import React, { Component } from "react";
import { Helmet } from "react-helmet";
import imgLogo from "../src/imgs/welcomhome-oc-horizontal.png";
import Tooltip from "@material-ui/core/Tooltip";
import { Auth } from "aws-amplify";
import { NavLink } from "react-router-dom";

let UserName = "";
class PageNotFound extends Component {
  signOut = async () => {
    try {
      await Auth.signOut();
      window.location.assign("/");
    } catch (e) {
      console.log(e);
    }
  };
  logoClick = function () {
    window.location.assign("/");
  };
  render() {
    return (
      <div className="d-flex flex-column sticky-footer-wrapper">
        <Helmet>
          <title>WelcomeHomeOC</title>
        </Helmet>
        <header>
          <nav
            className="navbar shadow-sm fixed-top navbar-light navbar-inner bg-light py-1"
            id="headerBg"
          >
            <a
              className="mr-2 navbar-brand pt-0"
              onClick={() => this.logoClick()}
            >
              <img
                src={imgLogo}
                height="22"
                className="logo"
                alt="WelcomeHome OC logo"
                loading="lazy"
              />
            </a>
            <h3
              className="font-weight-bold text-uppercase text-primary border-left py-1 pl-4 d-none d-sm-inline-block ml-3 mb-0"
              style={{ whiteSpace: "nowrap" }}
            >
              {/* {this.state.pageimg && <img src={this.state.pageimg} alt="{this.state.pagetitle}" loading="lazy" />}
                            {this.state.pageicon && <i className={this.state.pageicon}></i>}
                            <span className="header-title">{this.state.pagetitle}</span> */}
            </h3>
            <div className="ml-auto">
              <span className="text-primary">{UserName}</span>
              <Tooltip title="Back">
                <NavLink to="/">
                  <button
                    className="btn btn-light d-none d-sm-inline-block"
                    type="button"
                  >
                    <i className="fas fa-reply"></i>
                  </button>
                </NavLink>
              </Tooltip>
              <Tooltip title="Logout">
                <button
                  className="btn btn-link text-secondary"
                  onClick={this.signOut}
                >
                  <i className="fas fa-power-off ml-1"></i>
                </button>
              </Tooltip>
            </div>
          </nav>
        </header>
        <main className="page-not-found-main flex-fill">
          <div className="container-fluid">
            <div className="d-flex flex-column sticky-footer-wrapper">
              <div className="box-shadow details-page p-5 text-center">
                <h2 className="not-allowed" style={{fontSize: '20px', color: 'red'}}>Page not found</h2>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
export default PageNotFound;
