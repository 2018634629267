import React from "react";
import WelcomehomeOCH from './imgs/welcomhome-oc-horizontal.png';
import propertyImg from './imgs/property-img.jpg';
import { API, Auth, Storage } from 'aws-amplify';
import { CircularProgress } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import $ from 'jquery';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { toBase64, fromBase64 } from "@aws-sdk/util-base64-browser";
const filter = createFilterOptions();
class PropertyOwnerProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPageLoad: false,
            isLoading: false,
            authState: "loading",

            // Property Owner Information
            ID: 0,
            FirstName: "",
            //MiddleName: "",
            LastName: "",
            Address: "",
            City: "",
            Company: "",
            EmailID: "",
            HaveExpRenting: false,
            IsMemberOfAAOC: false,
            IsMemberOfCAA: false,
            IsMemberOfAnyOther: false,
            MemberOfAnyOtherText: "",
            IsVendorOfPHA: 0,
            IsVendorOfAHA: false,
            IsVendorOfGGHA: false,
            IsVendorOfOCHA: false,
            IsVendorOfSAHA: false,
            HaveRentalPropertiesInCentralOC: false,
            HaveRentalPropertiesInNorthOC: false,
            HaveRentalPropertiesInSouthOC: false,
            OwnerSignatoryName: "",
            OwnerSignatoryTitle: "",
            WantToBeRecognized: false,
            TaxID: "",
            Phone: "",
            State: "",
            Status: "",
            Zip: "",
            AdminSignatoryName: "",
            AdminSignatoryTitle: "",
            EnvelopId: "",
            EnvelopStatus: "",
            EnvelopStatusDateTime: "",
            Guid: "",
            EnvelopeId: "",
            EnvelopeStatusDateTime: "",
            EnvelopeStatus: "",
            AgreementSubmitted: false,
            BusinessName: "",
            Fax: "",
            SSN: "",
            EmployerIdentificationNumber: "",
            Properties: [],
            Units: [],
            AdditionalPhone: "",
            PersonalMailingAddress: "",

            validationErrors: {},

            openSnackbar: false,
            snackbarSeverity: "",
            snackbarVertical: 'top',
            snackbarHorizontal: 'right',
            snackbarMessage: "",
            snackbarMessageClass: '',

            OwnerFullName: "",
            OwnerFirstName: "",
            OwnerLastName: "",
            editOwnerFullName: "",
            editOwnerFirstName: "",
            editOwnerLastName: "",
            editOwnerEmailID: "",
            editContactNumber: "",
            editAddress: "",
            editCity: "",
            editState: "",
            editZip: "",
            editStatus: "",
            //editCompany: "",
            editAdditionalPhone: "",
            editPersonalMailingAddress: "",
            editWantToBeRecognized: -1,
            editIsMemberOfAAOC: false,
            editIsMemberOfCAA: false,
            editIsMemberOfAnyOther: false,
            editMemberOfAnyOtherText: "",
            editIsVendorOfPHA: 0,
            editIsVendorOfAHA: false,
            editIsVendorOfGGHA: false,
            editIsVendorOfOCHA: false,
            editIsVendorOfSAHA: false,
            editHaveRentalPropertiesInCentralOC: false,
            editHaveRentalPropertiesInNorthOC: false,
            editHaveRentalPropertiesInSouthOC: false,

            showPropertySearch: false,
            searchPropertyName: "",
            searchPropertyStatus: "",
            searchPropertyLocation: "",
            searchPHAStatus: "",
            searchUnitLevel: "",
            searchUnitStatus: "",
            searchAvailableDate: "",
            searchRentRangeMin: "",
            searchRentRangeMax: "",

            searchPropertyName_OnChange: "",
            searchPropertyStatus_OnChange: "",
            searchPropertyLocation_OnChange: "",
            searchPHAStatus_OnChange: "",
            searchUnitLevel_OnChange: "",
            searchUnitStatus_OnChange: "",
            searchAvailableDate_OnChange: "",
            searchRentRangeMin_OnChange: "",
            searchRentRangeMax_OnChange: "",
            selectedManagementCompany: null,
            oldSelectedManagementCompany: null,
            managementCompanies: [],
            PAFileName: ""
        }

        this.InputIsVendorOfPHA = React.createRef();
        this.InputIsMemberOf = React.createRef();
        this.InputHaveRentalProperties = React.createRef();
    }

    dummyRequest = () => {
        return new Promise((resolve, reject) => {
            window.setTimeout(
                () => {
                    resolve();
                },
                5000
            );
        });
    }

    // redirectToAddPropertyPage = () => 
    // {return <Link to={{ pathname: '/property/add', state: {ID: this.state.ID }}} />}


    componentDidUpdate = async (prevProps, prevState) => {
        if ($('#_phone_number')[0] && $('#_phone_number').closest('div')) {
            setTimeout(() => {
                $('#_phone_number').closest('div').hide();
                if ($('[data-test ="verify-contact-skip-link"]') != null) {
                    $('[data-test ="verify-contact-skip-link"]').hidden = true;
                    $('[data-test ="verify-contact-skip-link"]').remove();
                }
                // $("#_email").prop("checked", true);
            }, 45);
        }
        if (this.props.authState === "signedIn" && this.state.isPageLoad == false) {
            this.setState({ isPageLoad: true });
            try {
                var user = await Auth.currentAuthenticatedUser();
                this.setState({ authState: 'signedIn' });
            }
            catch (err) {
                if (err === "not authenticated") {
                    await this.signOut();
                }
                this.setState({ authState: 'signIn' });
            }

            // ToDo: comment this block of code.
            let currentSession = await Auth.currentSession();
            let accessToken = currentSession.getAccessToken();
            let jwt = accessToken.getJwtToken();
            //You can print them to see the full objects

            var info = await this.getPropertyOwnerInfo();
            if (info.length > 0) {
                this.setState({
                    ID: info[0]["ID"],
                    FirstName: info[0]["FirstName"],
                    //MiddleName: info[0]["MiddleName"],
                    LastName: info[0]["LastName"],
                    Address: info[0]["Address"],
                    City: info[0]["City"],
                    Company: info[0]["Company"],
                    AdditionalPhone: (info[0]["AdditionalPhone"] === null || info[0]["AdditionalPhone"] === undefined) ? '' : info[0]["AdditionalPhone"].replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                    PersonalMailingAddress: info[0]["PersonalMailingAddress"],
                    EmailID: info[0]["EmailID"],
                    HaveExpRenting: info[0]["HaveExpRenting"],
                    IsMemberOfAAOC: info[0]["IsMemberOfAAOC"],
                    IsMemberOfCAA: info[0]["IsMemberOfCAA"],
                    IsMemberOfAnyOther: info[0]["IsMemberOfAnyOther"],
                    MemberOfAnyOtherText: info[0]["MemberOfAnyOtherText"],
                    IsVendorOfPHA: info[0]["IsVendorOfPHA"],
                    IsVendorOfAHA: info[0]["IsVendorOfAHA"],
                    IsVendorOfGGHA: info[0]["IsVendorOfGGHA"],
                    IsVendorOfOCHA: info[0]["IsVendorOfOCHA"],
                    IsVendorOfSAHA: info[0]["IsVendorOfSAHA"],
                    HaveRentalPropertiesInCentralOC: info[0]["HaveRentalPropertiesInCentralOC"],
                    HaveRentalPropertiesInNorthOC: info[0]["HaveRentalPropertiesInNorthOC"],
                    HaveRentalPropertiesInSouthOC: info[0]["HaveRentalPropertiesInSouthOC"],
                    OwnerSignatoryName: info[0]["OwnerSignatoryName"],
                    OwnerSignatoryTitle: info[0]["OwnerSignatoryTitle"],
                    WantToBeRecognized: info[0]["WantToBeRecognized"],
                    TaxID: info[0]["TaxID"],
                    Phone: (info[0]["Phone"] === null || info[0]["Phone"] === undefined) ? '' : info[0]["Phone"].replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                    State: info[0]["State"],
                    Status: info[0]["Status"],
                    Zip: info[0]["Zip"],
                    AdminSignatoryName: info[0]["AdminSignatoryName"],
                    AdminSignatoryTitle: info[0]["AdminSignatoryTitle"],
                    EnvelopId: info[0]["EnvelopId"],
                    EnvelopStatus: info[0]["EnvelopStatus"],
                    EnvelopStatusDateTime: info[0]["EnvelopStatusDateTime"],
                    Guid: info[0]["Guid"],
                    EnvelopeId: info[0]["EnvelopeId"],
                    EnvelopeStatusDateTime: info[0]["EnvelopeStatusDateTime"],
                    EnvelopeStatus: info[0]["EnvelopeStatus"],
                    AgreementSubmitted: info[0]["AgreementSubmitted"],
                    BusinessName: info[0]["BusinessName"],
                    Fax: info[0]["Fax"],
                    SSN: info[0]["SSN"],
                    EmployerIdentificationNumber: info[0]["EmployerIdentificationNumber"],

                    Properties: info[0]["Properties"],
                    Units: info[0]["Units"],

                    OwnerFullName: `${info[0]["FirstName"]} ${info[0]["LastName"]}`,
                    OwnerFirstName: `${info[0]["FirstName"]}`,
                    OwnerLastName: `${info[0]["LastName"]}`,
                    editOwnerFullName: `${info[0]["FirstName"]} ${info[0]["LastName"]}`,
                    editOwnerFirstName: `${info[0]["FirstName"]}`,
                    editOwnerLastName: `${info[0]["LastName"]}`,
                    editOwnerEmailID: info[0]["EmailID"],
                    editContactNumber: (info[0]["Phone"] === null || info[0]["Phone"] === undefined) ? '' : info[0]["Phone"].replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                    editAddress: info[0]["Address"],
                    editCity: info[0]["City"],
                    editState: info[0]["State"],
                    editZip: info[0]["Zip"],
                    editStatus: info[0]["Status"],
                    editWantToBeRecognized: info[0]["WantToBeRecognized"],
                    editIsMemberOfAAOC: info[0]["IsMemberOfAAOC"],
                    editIsMemberOfCAA: info[0]["IsMemberOfCAA"],
                    editIsMemberOfAnyOther: info[0]["IsMemberOfAnyOther"],
                    editMemberOfAnyOtherText: info[0]["MemberOfAnyOtherText"],
                    editIsVendorOfPHA: info[0]["IsVendorOfPHA"],
                    editIsVendorOfAHA: info[0]["IsVendorOfAHA"],
                    editIsVendorOfGGHA: info[0]["IsVendorOfGGHA"],
                    editIsVendorOfOCHA: info[0]["IsVendorOfOCHA"],
                    editIsVendorOfSAHA: info[0]["IsVendorOfSAHA"],
                    editHaveRentalPropertiesInCentralOC: info[0]["HaveRentalPropertiesInCentralOC"],
                    editHaveRentalPropertiesInNorthOC: info[0]["HaveRentalPropertiesInNorthOC"],
                    editHaveRentalPropertiesInSouthOC: info[0]["HaveRentalPropertiesInSouthOC"],
                    //editCompany: info[0]["Company"],
                    editAdditionalPhone: (info[0]["AdditionalPhone"] === null || info[0]["AdditionalPhone"] === undefined) ? '' : info[0]["AdditionalPhone"].replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                    editPersonalMailingAddress: info[0]["PersonalMailingAddress"],
                    SalesforceContactId: info[0]["SalesforceContactId"],
                    PAFileName: info[0]["PAFileName"],
                });
            }

            Storage.configure({
                bucket: process.env.REACT_APP_S3FrontendBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });
            await this.loadPropertyImages();

            var manaCompResponse = await this.getManagementCompanies();
            if (manaCompResponse.total > 0) {
                manaCompResponse.data.forEach(e => {
                    this.state.managementCompanies.push({ title: e[1], Id: e[0] + "-" + e[2] + "-" + e[3] });
                });
            }
            this.setState({
                selectedManagementCompany: (info[0]["Company"] ? { title: info[0]["Company"].Title, Id: info[0]["Company"].Id } : null),
                oldSelectedManagementCompany: (info[0]["Company"] ? { title: info[0]["Company"].Title, Id: info[0]["Company"].Id } : null),
            });
            this.setState({ isLoading: false, isPageLoad: true });
        }
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true });

        // try {
        //     var user = await Auth.currentAuthenticatedUser();
        //     this.setState({ authState: 'signedIn' });
        // }
        // catch (err) {
        //     if(err === "not authenticated") {
        //         await this.signOut();
        //     }
        //     this.setState({ authState: 'signIn' });
        // }

        // ToDo: comment this block of code.
        let currentSession = await Auth.currentSession();
        let accessToken = currentSession.getAccessToken();
        let jwt = accessToken.getJwtToken();
        //You can print them to see the full objects
        var info = await this.getPropertyOwnerInfo();
        if (info.length > 0) {
            this.setState({
                ID: info[0]["ID"],
                FirstName: info[0]["FirstName"],
                //MiddleName: info[0]["MiddleName"],
                LastName: info[0]["LastName"],
                Address: info[0]["Address"],
                City: info[0]["City"],
                Company: info[0]["Company"],
                AdditionalPhone: (info[0]["AdditionalPhone"] === null || info[0]["AdditionalPhone"] === undefined) ? '' : info[0]["AdditionalPhone"].replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                PersonalMailingAddress: info[0]["PersonalMailingAddress"],
                EmailID: info[0]["EmailID"],
                HaveExpRenting: info[0]["HaveExpRenting"],
                IsMemberOfAAOC: info[0]["IsMemberOfAAOC"],
                IsMemberOfCAA: info[0]["IsMemberOfCAA"],
                IsMemberOfAnyOther: info[0]["IsMemberOfAnyOther"],
                MemberOfAnyOtherText: info[0]["MemberOfAnyOtherText"],
                IsVendorOfPHA: info[0]["IsVendorOfPHA"],
                IsVendorOfAHA: info[0]["IsVendorOfAHA"],
                IsVendorOfGGHA: info[0]["IsVendorOfGGHA"],
                IsVendorOfOCHA: info[0]["IsVendorOfOCHA"],
                IsVendorOfSAHA: info[0]["IsVendorOfSAHA"],
                HaveRentalPropertiesInCentralOC: info[0]["HaveRentalPropertiesInCentralOC"],
                HaveRentalPropertiesInNorthOC: info[0]["HaveRentalPropertiesInNorthOC"],
                HaveRentalPropertiesInSouthOC: info[0]["HaveRentalPropertiesInSouthOC"],
                OwnerSignatoryName: info[0]["OwnerSignatoryName"],
                OwnerSignatoryTitle: info[0]["OwnerSignatoryTitle"],
                WantToBeRecognized: info[0]["WantToBeRecognized"],
                TaxID: info[0]["TaxID"],
                Phone: (info[0]["Phone"] === null || info[0]["Phone"] === undefined) ? '' : info[0]["Phone"].replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                State: info[0]["State"],
                Status: info[0]["Status"],
                Zip: info[0]["Zip"],
                AdminSignatoryName: info[0]["AdminSignatoryName"],
                AdminSignatoryTitle: info[0]["AdminSignatoryTitle"],
                EnvelopId: info[0]["EnvelopId"],
                EnvelopStatus: info[0]["EnvelopStatus"],
                EnvelopStatusDateTime: info[0]["EnvelopStatusDateTime"],
                Guid: info[0]["Guid"],
                EnvelopeId: info[0]["EnvelopeId"],
                EnvelopeStatusDateTime: info[0]["EnvelopeStatusDateTime"],
                EnvelopeStatus: info[0]["EnvelopeStatus"],
                AgreementSubmitted: info[0]["AgreementSubmitted"],
                BusinessName: info[0]["BusinessName"],
                Fax: info[0]["Fax"],
                SSN: info[0]["SSN"],
                EmployerIdentificationNumber: info[0]["EmployerIdentificationNumber"],

                Properties: info[0]["Properties"],
                Units: info[0]["Units"],

                OwnerFullName: `${info[0]["FirstName"]} ${info[0]["LastName"]}`,
                OwnerFirstName: `${info[0]["FirstName"]}`,
                OwnerLastName: `${info[0]["LastName"]}`,
                editOwnerFullName: `${info[0]["FirstName"]} ${info[0]["LastName"]}`,
                editOwnerFirstName: `${info[0]["FirstName"]}`,
                editOwnerLastName: `${info[0]["LastName"]}`,
                editOwnerEmailID: info[0]["EmailID"],
                editContactNumber: (info[0]["Phone"] === null || info[0]["Phone"] === undefined) ? '' : info[0]["Phone"].replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                editAddress: info[0]["Address"],
                editCity: info[0]["City"],
                editState: info[0]["State"],
                editZip: info[0]["Zip"],
                editStatus: info[0]["Status"],
                editWantToBeRecognized: info[0]["WantToBeRecognized"],
                editIsMemberOfAAOC: info[0]["IsMemberOfAAOC"],
                editIsMemberOfCAA: info[0]["IsMemberOfCAA"],
                editIsMemberOfAnyOther: info[0]["IsMemberOfAnyOther"],
                editMemberOfAnyOtherText: info[0]["MemberOfAnyOtherText"],
                editIsVendorOfPHA: info[0]["IsVendorOfPHA"],
                editIsVendorOfAHA: info[0]["IsVendorOfAHA"],
                editIsVendorOfGGHA: info[0]["IsVendorOfGGHA"],
                editIsVendorOfOCHA: info[0]["IsVendorOfOCHA"],
                editIsVendorOfSAHA: info[0]["IsVendorOfSAHA"],
                editHaveRentalPropertiesInCentralOC: info[0]["HaveRentalPropertiesInCentralOC"],
                editHaveRentalPropertiesInNorthOC: info[0]["HaveRentalPropertiesInNorthOC"],
                editHaveRentalPropertiesInSouthOC: info[0]["HaveRentalPropertiesInSouthOC"],
                //editCompany: info[0]["Company"],
                editAdditionalPhone: (info[0]["AdditionalPhone"] === null || info[0]["AdditionalPhone"] === undefined) ? '' : info[0]["AdditionalPhone"].replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                editPersonalMailingAddress: info[0]["PersonalMailingAddress"],
                SalesforceContactId: info[0]["SalesforceContactId"],
                PAFileName: info[0]["PAFileName"],
            });
        }

        await this.loadPropertyImages();
        var manaCompResponse = await this.getManagementCompanies();
        if (manaCompResponse.total > 0) {
            manaCompResponse.data.forEach(e => {
                this.state.managementCompanies.push({ title: e[1], Id: e[0] + "-" + e[2] });
            });
        }


        this.setState({
            selectedManagementCompany: (info[0]["Company"] ? { title: info[0]["Company"].Title, Id: info[0]["Company"].Id } : null),
            oldSelectedManagementCompany: (info[0]["Company"] ? { title: info[0]["Company"].Title, Id: info[0]["Company"].Id } : null),
        });
        this.setState({ isLoading: false });
    }

    getManagementCompanies = async () => {
        this.setState({ isLoading: true });
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/getmanagementcompanylist';

        let myInit = { // OPTIONAL
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            },

        }
        return await API.post(apiName, path, myInit);
    }

    loadPropertyImages = async () => {
        this.setState({ isLoading: true });

        for (let i = 0; i < this.state.Properties.length; i++) {
            try {
                if (this.state.Properties[i]["ImagePath"] && this.state.Properties[i]["ImagePath"] !== '') {
                    // replace the relative iamge S3 path to its absolute image S3 path of the property.
                    this.state.Properties[i]["ImagePath"] = await Storage.get(this.state.Properties[i]["ImagePath"]);
                }
            }
            catch (e) {
                console.log(e);
            }
        }

        this.setState({ isLoading: false });
    }

    getPropertyOwnerInfo = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/getpropertyownerbyemailid';
        let myInit = {
            body: {
                "email": user.attributes.email
            },
            // headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
            headers: { Authorization: 'United Way WelcomeHomeOC' }
        }
        return await API.post(apiName, path, myInit);
    }

    signOut = async () => {
        try {
            await Auth.signOut()
            // signout success
            window.location.assign("/");
        }
        catch (e) {
            console.log(e);
        }
    }

    getCurrentUser = async () => {
        try {
            var user = await Auth.currentAuthenticatedUser();
            return user;
        }
        catch (e) {
            this.setState({ isLoading: false });
            if (e === "not authenticated") {
                await this.signOut();
            }
        }
    }

    toggleSearch = async () => {
        this.setState({
            showPropertySearch: !this.state.showPropertySearch
        })
    }

    resetSearch = async () => {
        this.setState({
            searchPropertyName: "",
            searchPropertyStatus: "",
            searchPropertyLocation: "",
            searchPHAStatus: "",
            searchUnitLevel: "",
            searchUnitStatus: "",
            searchAvailableDate: "",
            searchRentRangeMin: "",
            searchRentRangeMax: "",

            searchPropertyName_OnChange: "",
            searchPropertyStatus_OnChange: "",
            searchPropertyLocation_OnChange: "",
            searchPHAStatus_OnChange: "",
            searchUnitLevel_OnChange: "",
            searchUnitStatus_OnChange: "",
            searchAvailableDate_OnChange: "",
            searchRentRangeMin_OnChange: "",
            searchRentRangeMax_OnChange: "",
        });
    }

    applySearch = async () => {
        this.setState({ isLoading: true });
        this.setState({
            searchPropertyName: this.state.searchPropertyName_OnChange,
            searchPropertyStatus: this.state.searchPropertyStatus_OnChange,
            searchPropertyLocation: this.state.searchPropertyLocation_OnChange,
            searchPHAStatus: this.state.searchPHAStatus_OnChange,
            searchUnitLevel: this.state.searchUnitLevel_OnChange,
            searchUnitStatus: this.state.searchUnitStatus_OnChange,
            searchAvailableDate: this.state.searchAvailableDate_OnChange,
            searchRentRangeMin: this.state.searchRentRangeMin_OnChange,
            searchRentRangeMax: this.state.searchRentRangeMax_OnChange,
        });

        await this.toggleSearch();

        this.setState({ isLoading: false });
    }

    getFilterredProperties = () => {
        let filterredProperties = this.state.Properties;
        if (this.state.searchPropertyName !== "") {
            filterredProperties = filterredProperties.filter(p => p.PropertyName.toLowerCase().includes(this.state.searchPropertyName.toLowerCase()));
        }

        if (this.state.searchPropertyStatus !== "") {
            filterredProperties = filterredProperties.filter(p => p.Status.toLowerCase() === this.state.searchPropertyStatus.toLowerCase());
        }

        if (this.state.searchPropertyLocation !== "") {
            filterredProperties = filterredProperties.filter(p => (p.Address + " " + p.City + " " + p.State + " " + p.Zipcode).toLowerCase().includes(this.state.searchPropertyLocation.toLowerCase()));
        }

        if (this.state.searchPHAStatus !== "") {
            filterredProperties = filterredProperties.filter(p => p.PHAStatus.toLowerCase() === this.state.searchPHAStatus.toLowerCase());
        }

        if (this.state.searchAvailableDate !== "") {
            let u4 = this.state.Units.filter(u => u.AvailableFrom.includes(this.state.searchAvailableDate))
                .map(u => u.PropertyID).filter((v, i, a) => a.indexOf(v) === i);
            filterredProperties = filterredProperties.filter(p => u4.indexOf(p.PropertyID) >= 0);
        }

        if (this.state.searchRentRangeMin !== "") {
            let u4 = this.state.Units.filter(u => +u.RequestedRent >= +this.state.searchRentRangeMin)
                .map(u => u.PropertyID).filter((v, i, a) => a.indexOf(v) === i);
            filterredProperties = filterredProperties.filter(p => u4.indexOf(p.PropertyID) >= 0);
        }

        if (this.state.searchRentRangeMax !== "") {
            let u4 = this.state.Units.filter(u => +u.RequestedRent <= +this.state.searchRentRangeMax)
                .map(u => u.PropertyID).filter((v, i, a) => a.indexOf(v) === i);
            filterredProperties = filterredProperties.filter(p => u4.indexOf(p.PropertyID) >= 0);
        }

        if (this.state.searchUnitLevel !== "") {
            let u4 = this.state.Units.filter(u => u.UnitLevel.toLowerCase() === this.state.searchUnitLevel.toLowerCase())
                .map(u => u.PropertyID).filter((v, i, a) => a.indexOf(v) === i);
            filterredProperties = filterredProperties.filter(p => u4.indexOf(p.PropertyID) >= 0);
        }

        if (this.state.searchUnitStatus !== "") {
            let u4 = this.state.Units.filter(u => u.Status.toLowerCase() ===
                this.state.searchUnitStatus.toLowerCase()).map(u => u.PropertyID).filter((v, i, a) => a.indexOf(v) === i);
            filterredProperties = filterredProperties.filter(p => u4.indexOf(p.PropertyID) >= 0);
        }

        return filterredProperties;
    }

    renderProperties = () => {
        let rowedProperties = [];
        let children = [];

        let filterredProperties = this.getFilterredProperties();

        if (filterredProperties.length === 0) {
            rowedProperties.push(<div className="row mt-2"><div class="col-lg-12 mb-3 mb-lg-0">No property enlisted.</div></div>);
        }
        else {
            // Outer loop to create parent
            for (let i = 0; i < filterredProperties.length; i++) {
                var propertyStatus = (<span>Active</span>);
                if (filterredProperties[i]["Status"].toLowerCase() === 'active') {
                    propertyStatus = (<span>Active</span>);
                }
                if (filterredProperties[i]["Status"].toLowerCase() === 'inactive') {
                    propertyStatus = (<span class="inactive-bg">Inactive</span>);
                }
                else if (filterredProperties[i]["Status"].toLowerCase() === 'pending') {
                    propertyStatus = (<span class="pending-bg">Pending</span>);
                }

                let propertyPHAStatus = (<p class="form-control-static green text-uppercase">Registered</p>);
                if (filterredProperties[i]["PHAStatus"].toLowerCase() === 'registered') {
                    propertyPHAStatus = (<p class="form-control-static green text-uppercase">Registered</p>);
                }
                else if (filterredProperties[i]["PHAStatus"].toLowerCase() === 'not registered') {
                    propertyPHAStatus = (<p class="form-control-static red text-uppercase">Not Registered</p>);
                }
                else if (filterredProperties[i]["PHAStatus"].toLowerCase() === 'under process') {
                    propertyPHAStatus = (<p class="form-control-static blue text-uppercase">Under Process</p>);
                }

                children.push(
                    <div className="col-lg-4 mb-3 mb-lg-0" style={{ cursor: 'pointer' }} onClick={() => {
                        this.setState({ isLoading: true });
                        this.props.history.push({
                            pathname: '/property/edit',
                            state: { ID: filterredProperties[i]["PropertyID"] }
                        })
                        // window.location.assign(`/property/edit/${filterredProperties[i]["PropertyID"]}`);
                        this.setState({ isLoading: false });
                    }}>
                        <div className="property-box">
                            <img src={filterredProperties[i]["ImagePath"] === '' ? propertyImg : filterredProperties[i]["ImagePath"]} width="150" height="150" className="property-img" alt="property" loading="lazy" />
                            <div className="property-content">
                                <h3 className="profile-status d-inline">Status:{propertyStatus}</h3>
                                <div class="form-row mt-3">
                                    <div class="form-group col-md-12">
                                        <label>Property Name</label>
                                        <p class="form-control-static">{filterredProperties[i]["PropertyName"]}</p>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label>Portal User</label>
                                        <p class="form-control-static">{(filterredProperties[i]["po_Name"] && filterredProperties[i]["po_Name"] !== "" && filterredProperties[i]["po_Name"] !== null && filterredProperties[i]["po_Name"] !== undefined) ? filterredProperties[i]["po_Name"] : "N/A"}</p>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Property Location</label>
                                        <p class="form-control-static">{filterredProperties[i]["Address"] + " " + filterredProperties[i]["City"] + " " + filterredProperties[i]["State"] + " " + filterredProperties[i]["Zipcode"]}</p>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>PHA status</label>
                                        {propertyPHAStatus}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );

                if (children.length === 3 || (i === filterredProperties.length - 1 && children.length > 0)) {
                    rowedProperties.push(<div className="row mt-2">{children}</div>);
                    children = [];
                }
            }
        }

        return rowedProperties;
    }

    renderUnits = () => {
        let rowedUnits = [];
        let children = [];

        let filterredProperties = this.getFilterredProperties();

        let filterredUnits = this.state.Units;

        // filter only the units which belongs to the filterred properties
        filterredUnits = filterredUnits.filter(u => filterredProperties.filter(p => u.PropertyID === p.PropertyID).length > 0);

        if (this.state.searchUnitLevel !== "") {
            filterredUnits = filterredUnits.filter(u => u.UnitLevel.toLowerCase() === this.state.searchUnitLevel.toLowerCase());
        }

        if (this.state.searchUnitStatus !== "") {
            filterredUnits = filterredUnits.filter(u => u.Status.toLowerCase() === this.state.searchUnitStatus.toLowerCase());
        }

        if (this.state.searchAvailableDate !== "") {
            filterredUnits = filterredUnits.filter(u => u.AvailableFrom.includes(this.state.searchAvailableDate));
        }

        if (this.state.searchRentRangeMin !== "") {
            filterredUnits = filterredUnits.filter(u => +u.RequestedRent >= +this.state.searchRentRangeMin);
        }

        if (this.state.searchRentRangeMax !== "") {
            filterredUnits = filterredUnits.filter(u => +u.RequestedRent <= +this.state.searchRentRangeMax);
        }


        // Maintain the below sorting order for the units
        let sortedUnits = filterredUnits.filter(u => u.Status === "Submitted" || u.Status === "Inbound");
        sortedUnits = sortedUnits.concat(filterredUnits.filter(u => u.Status === "Under Rent Review" || u.Status === "Rent Review"));
        sortedUnits = sortedUnits.concat(filterredUnits.filter(u => u.Status === "Rent Discussion" || u.Status === "Negotiation"));
        sortedUnits = sortedUnits.concat(filterredUnits.filter(u => u.Status === "Holding Fee Agreement Needed" || u.Status === "HFA Sent"));
        sortedUnits = sortedUnits.concat(filterredUnits.filter(u => u.Status === "Active" || u.Status === "Active-Affiliate"));
        sortedUnits = sortedUnits.concat(filterredUnits.filter(u => u.Status === "Not Ready" || u.Status === "Not Yet Ready"));
        sortedUnits = sortedUnits.concat(filterredUnits.filter(u => u.Status === "Matched" || u.Status === "Matched-Affiliate"));
        sortedUnits = sortedUnits.concat(filterredUnits.filter(u => u.Status === "Leased" || u.Status === "Leased - Lite" || u.Status === "Leased-Affiliate"));
        sortedUnits = sortedUnits.concat(filterredUnits.filter(u => u.Status === "Inactive" || u.Status === "Outside network" || u.Status === "Not Needed" || u.Status === "Ineligible" || u.Status === "Released" || u.Status === "Released-Affiliate"));

        if (sortedUnits.length === 0) {
            rowedUnits.push(<div className="row mt-2 mb-2"><div class="col-lg-12 mb-3 mb-lg-0">No unit submitted.</div></div>);
        }
        else {
            // Outer loop to create parent
            for (let i = 0; i < sortedUnits.length; i++) {
                var unitStatus = (<span class="under-rent-review-bg">Under Rent Review</span>);

                if (sortedUnits[i]["Status"].toLowerCase() === 'under rent review' || sortedUnits[i]["Status"].toLowerCase() === 'rent review') {
                    unitStatus = (<span class="under-rent-review-bg">Under Rent Review</span>);
                }
                else if (sortedUnits[i]["Status"].toLowerCase() === 'submitted' || sortedUnits[i]["Status"].toLowerCase() === 'inbound') {
                    unitStatus = (<span>Submitted</span>);
                }
                else if (sortedUnits[i]["Status"].toLowerCase() === 'leased' || sortedUnits[i]["Status"].toLowerCase() === 'leased - lite' || sortedUnits[i]["Status"].toLowerCase() === 'leased-affiliate') {
                    unitStatus = (<span class="leased-bg">Leased</span>);
                }
                else if (sortedUnits[i]["Status"].toLowerCase() === 'inactive' || sortedUnits[i]["Status"].toLowerCase() === 'outside network'
                    || sortedUnits[i]["Status"].toLowerCase() === 'not needed' || sortedUnits[i]["Status"].toLowerCase() === 'ineligible' || sortedUnits[i]["Status"].toLowerCase() === 'released' || sortedUnits[i]["Status"].toLowerCase() === 'released-affiliate') {
                    unitStatus = (<span class="inactive-bg">Inactive</span>);
                }
                else if (sortedUnits[i]["Status"].toLowerCase() === 'rent discussion' || sortedUnits[i]["Status"].toLowerCase() === 'negotiation') {
                    unitStatus = (<span class="rent-discussion-bg">Rent Discussion</span>);
                }
                else if (sortedUnits[i]["Status"].toLowerCase() === 'holding fee agreement needed' || sortedUnits[i]["Status"].toLowerCase() === 'hfa sent') {
                    unitStatus = (<span class="holding-fee-agreement-needed-bg">Holding Fee Agreement Needed</span>);
                }
                else if (sortedUnits[i]["Status"].toLowerCase() === 'active' || sortedUnits[i]["Status"].toLowerCase() === 'active-affiliate') {
                    unitStatus = (<span class="active-bg">Active</span>);
                }
                else if (sortedUnits[i]["Status"].toLowerCase() === 'matched' || sortedUnits[i]["Status"].toLowerCase() === 'matched-affiliate') {
                    unitStatus = (<span class="matched-bg">Matched</span>);
                }
                else if (sortedUnits[i]["Status"].toLowerCase() === 'not ready' || sortedUnits[i]["Status"].toLowerCase() === 'not yet ready') {
                    unitStatus = (<span class="not-ready-bg">Not Ready</span>);
                }

                var dateAvailableFrom_Formatted = sortedUnits[i]["AvailableFrom"];
                if (dateAvailableFrom_Formatted !== "" && dateAvailableFrom_Formatted !== null) {
                    var d = new Date(dateAvailableFrom_Formatted);
                    dateAvailableFrom_Formatted = [
                        ('0' + (d.getMonth() + 1)).slice(-2),
                        ('0' + d.getDate()).slice(-2),
                        d.getFullYear()
                    ].join('/');
                }

                children.push(
                    <div className="col-lg-4 mb-3 mb-lg-0" style={{ cursor: 'pointer' }} onClick={() => {
                        this.setState({ isLoading: true });
                        // window.location.assign(`/unit/view/${sortedUnits[i]["PropertyUnitID"]}`);
                        this.props.history.push({
                            pathname: '/unit/view',
                            state: { ID: sortedUnits[i]["PropertyUnitID"] }
                        })
                        this.setState({ isLoading: false });
                    }}>
                        <div className="property-box border-blue">
                            <div className="property-content ml-0 w-100">
                                <h3 className="profile-status d-inline">Status:{unitStatus}</h3>
                                <div class="form-row mt-3">
                                    <div class="form-group col-md-12">
                                        <label>Property with which this unit is linked to</label>
                                        <p class="form-control-static">{filterredProperties.filter(p => p.PropertyID === sortedUnits[i]["PropertyID"])[0]["PropertyName"]}</p>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Address and Unit Number</label>
                                        <p class="form-control-static">{`${sortedUnits[i]["Address"]} ${sortedUnits[i]["Unit"]}`}</p>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Available From</label>
                                        <p class="form-control-static">{dateAvailableFrom_Formatted == "01/01/1" ? "" : dateAvailableFrom_Formatted}</p>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Approved Rent</label>
                                        <p class="form-control-static">{sortedUnits[i]["ApprovedRent"]}</p>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Requested Rent</label>
                                        <p class="form-control-static">{sortedUnits[i]["RequestedRent"]}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );

                if (children.length === 3 || (i === sortedUnits.length - 1 && children.length > 0)) {
                    rowedUnits.push(<div className="row mt-2 mb-2">{children}</div>);
                    children = [];
                }
            }
        }

        return rowedUnits;
    }

    OnEditOwnerFullName = async (event) => {
        this.setState({ editOwnerFullName: event.target.value });
    }

    OnEditOwnerEmailID = async (event) => {
        this.setState({ editOwnerEmailID: event.target.value });
    }

    OnEditContactNumber = async (event) => {
        this.setState({ editContactNumber: event.target.value });
    }

    OnEditAddress = async (event) => {
        this.setState({ editAddress: event.target.value });
    }

    OnEditCity = async (event) => {
        this.setState({ editCity: event.target.value });
    }

    OnEditState = async (event) => {
        this.setState({ editState: event.target.value });
    }

    OnEditZip = async (event) => {
        this.setState({ editZip: event.target.value });
    }

    OnEditWantToBeRecognizedYes = async (event) => {
        if (event.target.checked === true) {
            this.setState({ editWantToBeRecognized: 1 });
        }
        else {
            this.setState({ editWantToBeRecognized: 0 });
        }
        this.state.validationErrors["editWantToBeRecognized"] = "";
    }

    OnEditWantToBeRecognizedNo = async (event) => {
        if (event.target.checked === true) {
            this.setState({ editWantToBeRecognized: 0 });
        }
        else {
            this.setState({ editWantToBeRecognized: 1 });
        }
        this.state.validationErrors["editWantToBeRecognized"] = "";
    }

    OnEditIsVendorOfPHAYes = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ editIsVendorOfPHA: 1 });    // selected Yes
        }
        this.state.validationErrors["editIsVendorOfPHA"] = "";
    }
    OnEditIsVendorOfPHANo = (evt) => {
        if (evt.target.checked === true) {
            this.setState({
                editIsVendorOfPHA: 0,     // selected No
                editIsVendorOfOCHA: false,
                editIsVendorOfSAHA: false,
                editIsVendorOfAHA: false,
                editIsVendorOfGGHA: false,
            });
        }
        this.state.validationErrors["editIsVendorOfPHA"] = "";
    }
    OnEditIsVendorOfPHAUnsure = (evt) => {
        if (evt.target.checked === true) {
            this.setState({
                editIsVendorOfPHA: -1,   // selected Unsure
                editIsVendorOfOCHA: false,
                editIsVendorOfSAHA: false,
                editIsVendorOfAHA: false,
                editIsVendorOfGGHA: false,
            });
        }
        this.state.validationErrors["editIsVendorOfPHA"] = "";
    }
    OnEditIsVendorOfOCHA = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ editIsVendorOfOCHA: true });
        }
        else {
            this.setState({ editIsVendorOfOCHA: false });
        }
        this.state.validationErrors["editIsVendorOfPHA"] = "";
    }
    OnEditIsVendorOfSAHA = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ editIsVendorOfSAHA: true });
        }
        else {
            this.setState({ editIsVendorOfSAHA: false });
        }
        this.state.validationErrors["editIsVendorOfPHA"] = "";
    }
    OnEditIsVendorOfAHA = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ editIsVendorOfAHA: true });
        }
        else {
            this.setState({ editIsVendorOfAHA: false });
        }
        this.state.validationErrors["editIsVendorOfPHA"] = "";
    }
    OnEditIsVendorOfGGHA = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ editIsVendorOfGGHA: true });
        }
        else {
            this.setState({ editIsVendorOfGGHA: false });
        }
        this.state.validationErrors["editIsVendorOfPHA"] = "";
    }

    OnEditIsMemberOfAAOC = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ editIsMemberOfAAOC: true });
        }
        else {
            this.setState({ editIsMemberOfAAOC: false });
        }
        this.state.validationErrors["editIsMemberOf"] = "";
    }
    OnEditIsMemberOfCAA = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ editIsMemberOfCAA: true });
        }
        else {
            this.setState({ editIsMemberOfCAA: false });
        }
        this.state.validationErrors["editIsMemberOf"] = "";
    }
    OnEditIsMemberOfAnyOther = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ editIsMemberOfAnyOther: true });
        }
        else {
            this.setState({ editIsMemberOfAnyOther: false });
        }
        this.state.validationErrors["editIsMemberOf"] = "";
    }
    OnEditMemberOfAnyOtherText = (evt) => {
        this.setState({ editMemberOfAnyOtherText: evt.target.value });

        this.state.validationErrors["editIsMemberOf"] = "";
    }

    OnEditHaveRentalPropertiesInNorthOC = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ editHaveRentalPropertiesInNorthOC: true });
        }
        else {
            this.setState({ editHaveRentalPropertiesInNorthOC: false });
        }
        this.state.validationErrors["editHaveRentalPropertiesIn"] = "";
    }
    OnEditHaveRentalPropertiesInCentralOC = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ editHaveRentalPropertiesInCentralOC: true });
        }
        else {
            this.setState({ editHaveRentalPropertiesInCentralOC: false });
        }
        this.state.validationErrors["editHaveRentalPropertiesIn"] = "";
    }
    OnEditHaveRentalPropertiesInSouthOC = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ editHaveRentalPropertiesInSouthOC: true });
        }
        else {
            this.setState({ editHaveRentalPropertiesInSouthOC: false });
        }
        this.state.validationErrors["editHaveRentalPropertiesIn"] = "";
    }

    OnSearchPropertyNameChanged = async (event) => {
        this.setState({ searchPropertyName_OnChange: event.target.value });
    }

    OnSearchPropertyStatusChanged = async (event) => {
        this.setState({ searchPropertyStatus_OnChange: event.target.value });
    }

    OnSearchPropertyLocationChanged = async (event) => {
        this.setState({ searchPropertyLocation_OnChange: event.target.value });
    }

    OnSearchPHAStatusChanged = async (event) => {
        this.setState({ searchPHAStatus_OnChange: event.target.value });
    }

    OnSearchUnitLevelChanged = async (event) => {
        this.setState({ searchUnitLevel_OnChange: event.target.value });
    }

    OnSearchUnitStatusChanged = async (event) => {
        this.setState({ searchUnitStatus_OnChange: event.target.value });
    }

    OnSearchAvailableDateChanged = async (event) => {
        this.setState({ searchAvailableDate_OnChange: event.target.value });
    }

    OnSearchRentRangeMinChanged = async (event) => {
        this.setState({ searchRentRangeMin_OnChange: event.target.value });
    }

    OnSearchRentRangeMaxChanged = async (event) => {
        this.setState({ searchRentRangeMax_OnChange: event.target.value });
    }

    // OnEditCompany = async (event) => {
    //     console.log(event.target.value);
    //     this.setState({ editCompany: event.target.value });
    // }

    OnEditAdditionalPhone = async (event) => {
        this.setState({ editAdditionalPhone: event.target.value });
    }

    OnEditPersonalMailingAddress = async (event) => {
        this.setState({ editPersonalMailingAddress: event.target.value });
    }

    resetProfile = async () => {
        this.setState({
            editOwnerFullName: this.state.OwnerFullName,
            editOwnerFirstName: this.state.OwnerFirstName,
            editOwnerLastName: this.state.OwnerLastName,
            editOwnerEmailID: this.state.EmailID,
            editContactNumber: (this.state.Phone === null || this.state.Phone === undefined) ? '' : this.state.Phone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
            editAddress: this.state.Address,
            editCity: this.state.City,
            editState: this.state.State,
            editZip: this.state.Zip,
            editWantToBeRecognized: this.state.WantToBeRecognized,
            editIsMemberOfAAOC: this.state.IsMemberOfAAOC,
            editIsMemberOfCAA: this.state.IsMemberOfCAA,
            editIsMemberOfAnyOther: this.state.IsMemberOfAnyOther,
            editMemberOfAnyOtherText: this.state.MemberOfAnyOtherText,
            editIsVendorOfPHA: this.state.IsVendorOfPHA,
            editIsVendorOfAHA: this.state.IsVendorOfAHA,
            editIsVendorOfGGHA: this.state.IsVendorOfGGHA,
            editIsVendorOfOCHA: this.state.IsVendorOfOCHA,
            editIsVendorOfSAHA: this.state.IsVendorOfSAHA,
            editHaveRentalPropertiesInCentralOC: this.state.HaveRentalPropertiesInCentralOC,
            editHaveRentalPropertiesInNorthOC: this.state.HaveRentalPropertiesInNorthOC,
            editHaveRentalPropertiesInSouthOC: this.state.HaveRentalPropertiesInSouthOC,
            //editCompany: this.state.Company,
            editAdditionalPhone: (this.state.AdditionalPhone === null || this.state.AdditionalPhone === undefined) ? '' : this.state.AdditionalPhone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
            editPersonalMailingAddress: this.state.PersonalMailingAddress,
            selectedManagementCompany: this.state.oldSelectedManagementCompany,
            validationErrors: {}
        });
    }

    saveProfile = async () => {
        try {
            this.setState({ isLoading: true });

            if (!this.validateControls()) {
                this.setState({ isLoading: false });
                return;
            }

            var res = await this.updateOwnerProfile();
            if (res.result == "1" && this.state.SalesforceContactId) {
                var issueDateTime = new Date(
                    new Date().toLocaleString("en-US", { timeZone: "US/Pacific" })
                );
                var todaysDate = [
                    issueDateTime.getFullYear(),
                    ("0" + (issueDateTime.getMonth() + 1)).slice(-2),
                    ("0" + issueDateTime.getDate()).slice(-2),
                ].join("-");

                var todaysTime = [
                    ("0" + issueDateTime.getHours()).slice(-2),
                    ("0" + issueDateTime.getMinutes()).slice(-2),
                    ("0" + issueDateTime.getSeconds()).slice(-2),
                ].join(":");

                let Member_of__c = [];
                
                if (this.state.editIsMemberOfAAOC)
                    Member_of__c.push("Apartment Association of Orange County (AAOC)");
                if (this.state.editIsMemberOfCAA)
                    Member_of__c.push("California Apartment Association (CAA)");
                if (this.state.editIsMemberOfAnyOther)
                    Member_of__c.push("Other");
                
                let Rental_Properties_in__c = [];
                if (this.state.editHaveRentalPropertiesInNorthOC)
                    Rental_Properties_in__c.push("North Orange County");
                if (this.state.editHaveRentalPropertiesInCentralOC)
                    Rental_Properties_in__c.push("Central Orange County");
                if (this.state.editHaveRentalPropertiesInSouthOC)
                    Rental_Properties_in__c.push("South Orange County");
                
                //Update portal user in salesforce
                await this.loadFileToS3(
                    [
                        {
                            "Phone": (this.state.editContactNumber === null || this.state.editContactNumber === undefined) ? '' : this.state.editContactNumber.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                            "Address": this.state.editAddress, "City": this.state.editCity, "State": this.state.editState,
                            "Zip": this.state.editZip,
                            "SalesforceCompanyId": res.SalesforceCompanyId,
                            "SecondPhone": (this.state.editAdditionalPhone === null || this.state.editAdditionalPhone === undefined) ? '' : this.state.editAdditionalPhone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                            //"Status":this.state.editStatus,
                            "Member_of__c" : Member_of__c.join(';'), 
                            //"IsMemberOfAAOC": this.state.editIsMemberOfAAOC,
                            //"IsMemberOfCAA": this.state.editIsMemberOfCAA,
                            //"IsMemberOfAnyOther": this.state.editIsMemberOfAnyOther,
                            "MemberOfAnyOtherText": this.state.editMemberOfAnyOtherText,
                            "Rental_Properties_in__c": Rental_Properties_in__c.join(';'),
                            //"HaveRentalPropertiesInCentralOC": this.state.editHaveRentalPropertiesInCentralOC,
                            //"HaveRentalPropertiesInNorthOC": this.state.editHaveRentalPropertiesInNorthOC,
                            //"HaveRentalPropertiesInSouthOC": this.state.editHaveRentalPropertiesInSouthOC,
                            "AWS_OwnerID": this.state.ID, "AWS_UpdatedBy": "AWS", 
                            "SalesforceContactId": this.state.SalesforceContactId
                        },
                    ], todaysDate, todaysTime
                );
            } else {
                console.log("Not found Salesforce contact id.")
            }

            this.setState({ snackbarMessageClass: 'Mui-filledSuccess', openSnackbar: true, snackbarSeverity: "success", snackbarMessage: "User profile updated successfully" });
            // alert('The changes have been saved successfully.');
            // await this.dummyRequest();
            $('#editProfile').modal('hide');

            // Reload the property owner profile
            var info = await this.getPropertyOwnerInfo();

            if (info.length > 0) {
                this.setState({
                    ID: info[0]["ID"],
                    FirstName: info[0]["FirstName"],
                    //MiddleName: info[0]["MiddleName"],
                    LastName: info[0]["LastName"],
                    Address: info[0]["Address"],
                    City: info[0]["City"],
                    Company: info[0]["Company"],
                    AdditionalPhone: (info[0]["AdditionalPhone"] === null || info[0]["AdditionalPhone"] === undefined) ? '' : info[0]["AdditionalPhone"].replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                    PersonalMailingAddress: info[0]["PersonalMailingAddress"],
                    EmailID: info[0]["EmailID"],
                    HaveExpRenting: info[0]["HaveExpRenting"],
                    IsMemberOfAAOC: info[0]["IsMemberOfAAOC"],
                    IsMemberOfCAA: info[0]["IsMemberOfCAA"],
                    IsMemberOfAnyOther: info[0]["IsMemberOfAnyOther"],
                    MemberOfAnyOtherText: info[0]["MemberOfAnyOtherText"],
                    IsVendorOfPHA: info[0]["IsVendorOfPHA"],
                    IsVendorOfAHA: info[0]["IsVendorOfAHA"],
                    IsVendorOfGGHA: info[0]["IsVendorOfGGHA"],
                    IsVendorOfOCHA: info[0]["IsVendorOfOCHA"],
                    IsVendorOfSAHA: info[0]["IsVendorOfSAHA"],
                    HaveRentalPropertiesInCentralOC: info[0]["HaveRentalPropertiesInCentralOC"],
                    HaveRentalPropertiesInNorthOC: info[0]["HaveRentalPropertiesInNorthOC"],
                    HaveRentalPropertiesInSouthOC: info[0]["HaveRentalPropertiesInSouthOC"],
                    OwnerSignatoryName: info[0]["OwnerSignatoryName"],
                    OwnerSignatoryTitle: info[0]["OwnerSignatoryTitle"],
                    WantToBeRecognized: info[0]["WantToBeRecognized"],
                    TaxID: info[0]["TaxID"],
                    Phone: (info[0]["Phone"] === null || info[0]["Phone"] === undefined) ? '' : info[0]["Phone"].replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                    State: info[0]["State"],
                    Status: info[0]["Status"],
                    Zip: info[0]["Zip"],
                    AdminSignatoryName: info[0]["AdminSignatoryName"],
                    AdminSignatoryTitle: info[0]["AdminSignatoryTitle"],
                    EnvelopId: info[0]["EnvelopId"],
                    EnvelopStatus: info[0]["EnvelopStatus"],
                    EnvelopStatusDateTime: info[0]["EnvelopStatusDateTime"],
                    Guid: info[0]["Guid"],
                    EnvelopeId: info[0]["EnvelopeId"],
                    EnvelopeStatusDateTime: info[0]["EnvelopeStatusDateTime"],
                    EnvelopeStatus: info[0]["EnvelopeStatus"],
                    AgreementSubmitted: info[0]["AgreementSubmitted"],
                    BusinessName: info[0]["BusinessName"],
                    Fax: info[0]["Fax"],
                    SSN: info[0]["SSN"],
                    EmployerIdentificationNumber: info[0]["EmployerIdentificationNumber"],

                    Properties: info[0]["Properties"],
                    Units: info[0]["Units"],

                    OwnerFullName: `${info[0]["FirstName"]} ${info[0]["LastName"]}`,
                    OwnerFirstName: `${info[0]["FirstName"]}`,
                    OwnerLastName: `${info[0]["LastName"]}`,
                    editOwnerFullName: `${info[0]["FirstName"]} ${info[0]["LastName"]}`,
                    editOwnerFirstName: `${info[0]["FirstName"]}`,
                    editOwnerLastName: `${info[0]["LastName"]}`,
                    editOwnerEmailID: info[0]["EmailID"],
                    editContactNumber: (info[0]["Phone"] === null || info[0]["Phone"] === undefined) ? '' : info[0]["Phone"].replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                    editAddress: info[0]["Address"],
                    editCity: info[0]["City"],
                    editState: info[0]["State"],
                    editZip: info[0]["Zip"],
                    editStatus: info[0]["Status"],
                    editWantToBeRecognized: info[0]["WantToBeRecognized"],
                    editIsMemberOfAAOC: info[0]["IsMemberOfAAOC"],
                    editIsMemberOfCAA: info[0]["IsMemberOfCAA"],
                    editIsMemberOfAnyOther: info[0]["IsMemberOfAnyOther"],
                    editMemberOfAnyOtherText: info[0]["MemberOfAnyOtherText"],
                    editIsVendorOfPHA: info[0]["IsVendorOfPHA"],
                    editIsVendorOfAHA: info[0]["IsVendorOfAHA"],
                    editIsVendorOfGGHA: info[0]["IsVendorOfGGHA"],
                    editIsVendorOfOCHA: info[0]["IsVendorOfOCHA"],
                    editIsVendorOfSAHA: info[0]["IsVendorOfSAHA"],
                    editHaveRentalPropertiesInCentralOC: info[0]["HaveRentalPropertiesInCentralOC"],
                    editHaveRentalPropertiesInNorthOC: info[0]["HaveRentalPropertiesInNorthOC"],
                    editHaveRentalPropertiesInSouthOC: info[0]["HaveRentalPropertiesInSouthOC"],
                    //editCompany: info[0]["Company"],
                    editAdditionalPhone: (info[0]["AdditionalPhone"] === null || info[0]["AdditionalPhone"] === undefined) ? '' : info[0]["AdditionalPhone"].replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                    editPersonalMailingAddress: info[0]["PersonalMailingAddress"],
                    selectedManagementCompany: (info[0]["Company"] ? { title: info[0]["Company"].Title, Id: info[0]["Company"].Id } : null),
                    oldSelectedManagementCompany: (info[0]["Company"] ? { title: info[0]["Company"].Title, Id: info[0]["Company"].Id } : null),
                    SalesforceContactId: info[0]["SalesforceContactId"],
                });
            }
            this.setState({ isLoading: false });
        }
        catch (e) {
            console.log(e);
        }
    }

    loadFileToS3 = async (items, todaysDate, todaysTime) => {
        // Convert JSON to CSV & Display CSV
        var s = this.convertToCSV(items);
        try {
            Storage.configure({
                bucket: process.env.REACT_APP_S3WHOCAdminBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });

            var fileName = "Contact_" + items[0].AWS_OwnerID + "_" + todaysDate + "_" + todaysTime + ".csv";
            var fullFileName = `Salesforce/Update-Contact/${fileName}`;
            var result1 = await Storage.put(fullFileName, s, { contentType: "csv" });
        }
        catch (err) {
            console.log(err)
            this.setState({ isLoading: false });
            return;
        }
    }

    convertToCSV(arr) {
        const array = [Object.keys(arr[0])].concat(arr)

        return array.map(it => {
            return Object.values(it).toString()
        }).join('\n')
    }

    updateOwnerProfile = async () => {
        var user = await this.getCurrentUser();
        var adminUser = user.attributes.email;
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/updatepropertyownerprofile';
        let myInit = {
            body: {
                "CentralContactId": this.state.ID,
                "OwnerFullName": this.state.editOwnerFullName,
                "OwnerFirstName": this.state.editOwnerFirstName,
                "OwnerLastName": this.state.editOwnerLastName,
                "EmailID": this.state.editOwnerEmailID,
                "Phone": (this.state.editContactNumber === null || this.state.editContactNumber === undefined) ? '' : this.state.editContactNumber.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                "Address": this.state.editAddress,
                "City": this.state.editCity,
                "State": this.state.editState,
                "Zip": this.state.editZip,
                "Status": this.state.editStatus,
                "WantToBeRecognized": this.state.editWantToBeRecognized,
                //"Company": this.state.editCompany,
                "Company": (this.state.selectedManagementCompany && this.state.selectedManagementCompany.Id != undefined) ? this.state.selectedManagementCompany : null,
                "AdditionalPhone": (this.state.editAdditionalPhone === null || this.state.editAdditionalPhone === undefined) ? '' : this.state.editAdditionalPhone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                "PersonalMailingAddress": this.state.editPersonalMailingAddress,
                "IsMemberOfAAOC": this.state.editIsMemberOfAAOC,
                "IsMemberOfCAA": this.state.editIsMemberOfCAA,
                "IsMemberOfAnyOther": this.state.editIsMemberOfAnyOther,
                "MemberOfAnyOtherText": this.state.editMemberOfAnyOtherText,
                "IsVendorOfPHA": this.state.editIsVendorOfPHA,
                "IsVendorOfAHA": this.state.editIsVendorOfAHA,
                "IsVendorOfGGHA": this.state.editIsVendorOfGGHA,
                "IsVendorOfOCHA": this.state.editIsVendorOfOCHA,
                "IsVendorOfSAHA": this.state.editIsVendorOfSAHA,
                "HaveRentalPropertiesInCentralOC": this.state.editHaveRentalPropertiesInCentralOC,
                "HaveRentalPropertiesInNorthOC": this.state.editHaveRentalPropertiesInNorthOC,
                "HaveRentalPropertiesInSouthOC": this.state.editHaveRentalPropertiesInSouthOC,
                "ModifiedBy": adminUser,
                "IsFirstEdit": (this.state.WantToBeRecognized == '-1' ? true : false),
            },
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    validateControls() {
        let formIsValid = true;
        let errors = {};

        if (this.state.editContactNumber === "" || this.state.editContactNumber === null || this.state.editContactNumber === undefined) {
            errors["editContactNumber"] = "Enter the Contact Number";
            formIsValid = false;
        }
        else if (this.state.editContactNumber !== "" && this.state.editContactNumber !== null && this.state.editContactNumber !== undefined) {
            if (!this.state.editContactNumber.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
                errors["editContactNumber"] = "Invalid Number";
                formIsValid = false;
            }
        }

        if (this.state.editAdditionalPhone !== "" && this.state.editAdditionalPhone !== null && this.state.editAdditionalPhone !== undefined) {
            if (!this.state.editAdditionalPhone.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
                errors["editAdditionalPhone"] = "Invalid Phone";
                formIsValid = false;
            }
        }

        if (this.state.editAddress === "" || this.state.editAddress === null || this.state.editAddress === undefined) {
            errors["editAddress"] = "Enter the Address";
            formIsValid = false;
        }

        if (this.state.editCity === "" || this.state.editCity === null || this.state.editCity === undefined) {
            errors["editCity"] = "Enter the City";
            formIsValid = false;
        }
        else if (this.state.editCity !== "" && this.state.editCity !== null && this.state.editCity !== undefined) {
            var namePattern = new RegExp(/^[a-zA-Z- ]*$/);
            if (!namePattern.test(this.state.editCity)) {
                errors["editCity"] = "Invalid City";
                formIsValid = false;
            }
        }

        if (this.state.editState === "" || this.state.editState === null || this.state.editState === undefined) {
            errors["editState"] = "Select the State";
            formIsValid = false;
        }

        if (this.state.editZip === "" || this.state.editZip === null || this.state.editZip === undefined) {
            errors["editZip"] = "Enter the Zip";
            formIsValid = false;
        }
        else if (this.state.editZip !== "" && this.state.editZip !== null && this.state.editZip !== undefined) {
            if (this.state.editZip.length < 5 || /^\d+$/.test(this.state.editZip) !== true) {
                errors["editZip"] = "Invalid Zip";
                formIsValid = false;
            }
        }

        // if (this.state.editIsVendorOfPHA === 1 &&
        //     this.state.editIsVendorOfOCHA !== true && this.state.editIsVendorOfSAHA !== true && this.state.editIsVendorOfAHA !== true && this.state.editIsVendorOfGGHA !== true) {
        //     errors["editIsVendorOfPHA"] = "Please select any of the Public Housing Authority (PHA) that you are a vendor of.";

        //     this.InputIsVendorOfPHA.current.focus();
        //     formIsValid = false;
        // }
        // else if (this.state.editIsVendorOfPHA === -2) { // not-selected
        //     errors["editIsVendorOfPHA"] = "Please select if you are Public Housing Authority (PHA) vendor or not.";

        //     this.InputIsVendorOfPHA.current.focus();
        //     formIsValid = false;
        // }

        // if (this.state.editIsMemberOfAAOC !== true && this.state.editIsMemberOfCAA !== true && this.state.editIsMemberOfBoth !== true && this.state.editIsMemberOfAnyOther !== true) {

        //     this.InputIsMemberOf.current.focus();
        //     formIsValid = false;
        // }
        if (this.state.editIsMemberOfAnyOther === true && this.state.editMemberOfAnyOtherText === '') {
            errors["editIsMemberOf"] = "Please enter association in which you are member of.";

            this.InputIsMemberOf.current.focus();
            formIsValid = false;
        }
        else if (this.state.editIsMemberOfAnyOther === true && this.state.editMemberOfAnyOtherText.length > 255) {
            errors["editIsMemberOf"] = "Length of the other association should be less than 255 characters.";

            this.InputIsMemberOf.current.focus();
            formIsValid = false;
        }

        if (this.state.editHaveRentalPropertiesInNorthOC !== true && this.state.editHaveRentalPropertiesInCentralOC !== true && this.state.editHaveRentalPropertiesInSouthOC !== true) {
            errors["editHaveRentalPropertiesIn"] = "Please select area in which your rental properties are located.";

            this.InputHaveRentalProperties.current.focus();
            formIsValid = false;
        }

        // if(this.state.editWantToBeRecognized === undefined || this.state.editWantToBeRecognized === null || this.state.editWantToBeRecognized == -1) {
        //     errors["editWantToBeRecognized"] = "Please select one option";
        //     formIsValid = false;
        // }

        this.setState({ validationErrors: errors });

        return formIsValid;
    }

    render() {
        if (this.props.authState === "signedIn" || this.state.authState === "signedIn") {
            document.body.classList.remove('landingPage');
            return (

                <div>
                    {/* header starts */}
                    <header>
                        <nav className="navbar shadow-sm fixed-top navbar-light navbar-inner bg-light py-1" id="headerBg">
                            <a className="mr-2 navbar-brand pt-0" href="#">
                                <img src={WelcomehomeOCH} className="logo" height="22" alt="WelcomeHome OC logo" loading="lazy" />
                            </a>
                            <div className="ml-auto">
                                <Tooltip title="Logout">
                                    <button className="btn btn-link text-secondary" onClick={this.signOut}><i className="fas fa-power-off ml-1"></i></button>
                                </Tooltip>
                            </div>
                        </nav>
                    </header>
                    {/* header ends */}

                    {/* main content start */}
                    <main className="flex-fill">
                        <div className="container-fluid p-4">
                            <h2 className="profile-title mt-5"><i class="fas fa-address-book mr-2"></i>User Profile</h2>
                            <div className="profile-ylbox">
                                <div className="profile-header mb-4">
                                    <h3 className="profile-status d-inline">Status:<span>{this.state.Status}</span></h3>
                                    <Tooltip title="Edit profile">
                                        <span className="edit-profile" data-toggle="modal" data-target="#editProfile"><i class="fas fa-user-edit"></i></span>
                                    </Tooltip>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-3 col-sm-6">
                                        <label>Full Name</label>
                                        <p class="form-control-static">{this.state.OwnerFullName}</p>
                                    </div>
                                    <div class="form-group col-md-3 col-sm-6">
                                        <label>Email ID</label>
                                        <p class="form-control-static">{this.state.EmailID}</p>
                                    </div>
                                    <div class="form-group col-md-3 col-sm-6">
                                        <label>Contact Number</label>
                                        <p class="form-control-static">{(this.state.Phone === null || this.state.Phone === undefined) ? '' : this.state.Phone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '')}</p>
                                    </div>
                                    <div class="form-group col-md-3 col-sm-6">
                                        <label>Address</label>
                                        <p class="form-control-static">{this.state.Address}</p>
                                    </div>
                                    <div class="form-group col-md-3 col-sm-6">
                                        <label>City</label>
                                        <p class="form-control-static">{this.state.City}</p>
                                    </div>
                                    <div class="form-group col-md-3 col-sm-6">
                                        <label>State</label>
                                        <p class="form-control-static">{this.state.State}</p>
                                    </div>
                                    <div class="form-group col-md-3 col-sm-6">
                                        <label>Zip Code</label>
                                        <p class="form-control-static">{this.state.Zip}</p>
                                    </div>
                                    <div class="form-group col-md-3 col-sm-6">
                                        <label>Company </label>
                                        {/* <p class="form-control-static">{this.state.Company}</p> */}
                                        <p class="form-control-static">{(this.state.selectedManagementCompany == null || this.state.selectedManagementCompany === undefined) ? "" : this.state.selectedManagementCompany.title}</p>
                                    </div>
                                </div>
                                {/* <div class="form-row">
                                    <div class="form-group col-md-12 mb-0">
                                        <label>Can we recognize you as a leader to end homelessness through WelcomeHomeOC? - </label>
                                        <p class="form-control-static d-inline">{this.state.WantToBeRecognized == 1 ? " Yes" : this.state.WantToBeRecognized == 0 ? " No" : ""}</p>
                                    </div>
                                </div> */}
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <a href="#!" className="mr-5 d-block d-sm-inline mb-2 mb-sm-0" onClick={async () => {
                                        this.setState({ isLoading: true });

                                        Storage.configure({
                                            bucket: process.env.REACT_APP_S3FrontendBucket,
                                            region: process.env.REACT_APP_Region,
                                            level: 'public',
                                        });

                                        try {
                                            var template = await Storage.get(`StaticDocs/ProgramMemorandum/ProgramMemorandum.pdf`);

                                            let link = document.createElement('a');
                                            link.href = template;
                                            link.target = '_blank';
                                            link.download = template;
                                            link.click();
                                        }
                                        catch (e) {
                                            console.log(e);
                                        }

                                        this.setState({ isLoading: false });
                                    }}>
                                        <span className="round-img mr-2">
                                            <i class="fas fa-house-user"></i>
                                        </span>
                                        <span className="program-name">Program memorandum</span>
                                    </a>
                                    <a href="#!" className="d-block d-sm-inline" onClick={async () => {
                                        this.setState({ isLoading: true });

                                        if (this.state.EnvelopeStatus == "Completed") {
                                            try {
                                                Storage.configure({
                                                    bucket: process.env.REACT_APP_S3FrontendBucket,
                                                    region: process.env.REACT_APP_Region,
                                                    level: 'public',
                                                });

                                                var template = await Storage.get(`PortalUser/${this.state.ID}/participation_agreement/${this.state.PAFileName}`);

                                                let link = document.createElement('a');
                                                link.href = template;
                                                link.target = '_blank';
                                                link.download = template;
                                                link.click();
                                            }
                                            catch (e) {
                                                console.log(e);
                                                this.setState({ snackbarMessage: 'Participation agreement is under review or had been manually signed', openSnackbar: true, snackbarMessageClass: 'Mui-filledError' });
                                            }
                                        }
                                        else {
                                            this.setState({ snackbarMessage: 'Participation agreement is under review or had been manually signed', openSnackbar: true, snackbarMessageClass: 'Mui-filledError' });
                                        }

                                        this.setState({ isLoading: false });
                                    }}>
                                        <span className="round-img mr-2 green">
                                            <i class="fas fa-file-signature"></i>
                                        </span>
                                        <span className="program-name">Signed Participation Agreement Form</span>
                                    </a>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <div class="wh-note">
                                        <span>Note:</span>
                                        <li class="ml-4"> Please complete your profile before enlisting properties and units.</li>
                                        <li class="ml-4">For any technical issues, please contact us at: <b><a href="mailto:WHOCSupport@ocuwhelp.zohodesk.com">WHOC Support</a></b></li>
                                    </div>
                                </div>
                            </div>
                            <div className="position-relative">
                                <div className="property-search-box search-area">
                                    <div className={this.state.showPropertySearch ? 'collapse multi-collapse show' : 'collapse multi-collapse'} id="multiCollapseExample1">
                                        <div class="card card-body pt-2">
                                            <div className="filter-close" onClick={this.toggleSearch}><i className={this.state.showPropertySearch ? 'fas fa-times' : 'fas fa-times collapsed'} data-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded={this.state.showPropertySearch ? 'true' : 'false'} aria-controls="multiCollapseExample1"></i></div>
                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label>Property Name</label>
                                                    <input type="text" class="form-control" value={this.state.searchPropertyName_OnChange}
                                                        onChange={this.OnSearchPropertyNameChanged} />
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label>Property Status</label>
                                                    <select className="form-control" value={this.state.searchPropertyStatus_OnChange} onChange={this.OnSearchPropertyStatusChanged}>
                                                        <option value="">Please Select</option>
                                                        <option value="Active">Active</option>
                                                        <option value="Inactive">Inactive</option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label>Property Location</label>
                                                    <input type="text" class="form-control" value={this.state.searchPropertyLocation_OnChange}
                                                        onChange={this.OnSearchPropertyLocationChanged} />
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label>PHA Status</label>
                                                    <select className="form-control" value={this.state.searchPHAStatus_OnChange} onChange={this.OnSearchPHAStatusChanged}>
                                                        <option value="">Please Select</option>
                                                        <option value="Registered">Registered</option>
                                                        <option value="Not Registered">Not Registered</option>
                                                        <option value="Under Process">Under Process</option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label>Unit Level</label>
                                                    <select className="form-control" value={this.state.searchUnitLevel_OnChange} onChange={this.OnSearchUnitLevelChanged}>
                                                        <option value="">Please Select</option>
                                                        <option value="Upstairs">Upstairs</option>
                                                        <option value="Downstairs">Downstairs</option>
                                                        <option value="Multi-level">Multilevel</option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label>Unit Status</label>
                                                    <select className="form-control" value={this.state.searchUnitStatus_OnChange} onChange={this.OnSearchUnitStatusChanged}>
                                                        <option value="">Please Select</option>
                                                        <option value="Submitted">Submitted</option>
                                                        <option value="Under Rent Review">Under Rent Review</option>
                                                        <option value="Rent Discussion">Rent Discussion</option>
                                                        <option value="Holding Fee Agreement Needed">Holding Fee Agreement Needed</option>
                                                        <option value="Active">Active</option>
                                                        <option value="Matched">Matched</option>
                                                        <option value="Leased">Leased</option>
                                                        <option value="Inactive">Inactive</option>
                                                        <option value="Not Ready">Not Ready</option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label>Available Date</label>
                                                    <input id="date" type="date" value={this.state.searchAvailableDate_OnChange}
                                                        onChange={this.OnSearchAvailableDateChanged} class="form-control" />
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label>Rent Range (Min)</label>
                                                    <input type="text" class="form-control" value={this.state.searchRentRangeMin_OnChange}
                                                        onChange={this.OnSearchRentRangeMinChanged} />
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label>Rent Range (Max)</label>
                                                    <input type="text" class="form-control" value={this.state.searchRentRangeMax_OnChange}
                                                        onChange={this.OnSearchRentRangeMaxChanged} />
                                                </div>
                                            </div>
                                            <div className="text-right">
                                                <button type="button" class="btn btn-primary btn-light btn-sm" onClick={this.resetSearch}>Reset</button>
                                                <button type="button" class="btn btn-primary btn-black btn-sm" onClick={this.applySearch}>Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 mt-3 border-bottom border-dark profile-title-main">
                                <h2 className="profile-title"><i class="fas fa-home mr-2"></i>Property Enlisted</h2>
                                <button type="button" class="btn btn-primary btn-black float-right" onClick={() => {
                                    this.setState({ isLoading: true });

                                    if (this.state.Address === "" ||
                                        this.state.City === "" ||
                                        this.state.Phone === "" ||
                                        this.state.State === "" ||
                                        this.state.Zip === "" ||
                                        // this.state.WantToBeRecognized === false || 
                                        this.state.IsVendorOfPHA === -2 ||
                                        (this.state.IsVendorOfPHA === 1 &&
                                            this.state.IsVendorOfAHA === false &&
                                            this.state.IsVendorOfGGHA === false &&
                                            this.state.IsVendorOfOCHA === false &&
                                            this.state.IsVendorOfSAHA === false) ||
                                        (this.state.HaveRentalPropertiesInCentralOC === false &&
                                            this.state.HaveRentalPropertiesInNorthOC === false &&
                                            this.state.HaveRentalPropertiesInSouthOC === false)
                                    ) {
                                        this.setState({ snackbarMessage: 'Please complete your profile to enlist new property.', openSnackbar: true, snackbarMessageClass: 'Mui-filledError' });
                                    }
                                    else {
                                        // this.redirectToAddPropertyPage();
                                        this.props.history.push({
                                            pathname: '/property/add',
                                            state: { ID: this.state.ID }
                                        })
                                        // window.location.assign(`/property/add/${this.state.ID}`);
                                    }

                                    this.setState({ isLoading: false });
                                }} >
                                    <i class="fas fa-plus-circle mr-1"></i>Add
                                </button>
                                <button data-toggle="collapse" href="#multiCollapseExample1" aria-expanded={this.state.showPropertySearch ? 'true' : 'false'}
                                    aria-controls="multiCollapseExample1" type="button" className={this.state.showPropertySearch ? 'btn btn-primary btn-red float-right mr-2 mb-2 mt-0 ' : 'btn btn-primary btn-red float-right mr-2 mb-2 mt-0 collapsed'}
                                    onClick={this.toggleSearch}>
                                    <i class="fas fa-filter mr-1"></i>Property search
                                </button>
                            </div>
                            {this.renderProperties()}

                            <div className="mb-3 mt-5 border-bottom border-dark profile-title-main">
                                <h2 className="profile-title"><i class="fas fa-building mr-2"></i>Unit Submitted</h2>
                                <button type="button" class="btn btn-primary btn-black float-right" onClick={() => {
                                    this.setState({ isLoading: true });

                                    if (this.state.Address === "" ||
                                        this.state.City === "" ||
                                        this.state.Phone === "" ||
                                        this.state.State === "" ||
                                        this.state.Zip === "" ||
                                        // this.state.WantToBeRecognized === false || 
                                        this.state.IsVendorOfPHA === -2 ||
                                        (this.state.IsVendorOfPHA === 1 &&
                                            this.state.IsVendorOfAHA === false &&
                                            this.state.IsVendorOfGGHA === false &&
                                            this.state.IsVendorOfOCHA === false &&
                                            this.state.IsVendorOfSAHA === false) ||
                                        (this.state.HaveRentalPropertiesInCentralOC === false &&
                                            this.state.HaveRentalPropertiesInNorthOC === false &&
                                            this.state.HaveRentalPropertiesInSouthOC === false)
                                    ) {
                                        this.setState({ snackbarMessage: 'Please complete your profile to add new unit.', openSnackbar: true, snackbarMessageClass: 'Mui-filledError' });
                                    }
                                    else {
                                        // window.location.assign(`/unit/add/${this.state.ID}`);
                                        this.props.history.push({
                                            pathname: '/unit/add',
                                            state: { ID: this.state.ID }
                                        })
                                    }

                                    this.setState({ isLoading: false });
                                }} >
                                    <i class="fas fa-plus-circle mr-1"></i>Add
                                </button>
                            </div>
                            {this.renderUnits()}
                        </div>
                    </main>
                    {/* main content end */}

                    {/* footer START */}
                    <footer>
                        <div>Powered by Orange County United Way</div>
                    </footer>
                    {/* footer END */}

                    {/* edit profile modal START*/}
                    <div class="modal fade" data-backdrop="static" data-keyboard="false" id="editProfile" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel"><i class="fas fa-address-book mr-2"></i>Edit User Profile</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.resetProfile}>
                                        <i class="fas fa-times"></i>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form>
                                        <div class="form-row">
                                            <div class="form-group col-md-4">
                                                <label>Owner Full Name</label>
                                                <input type="text" disabled class="form-control" id="fullname" value={this.state.editOwnerFullName}
                                                    onChange={this.OnEditOwnerFullName} />
                                            </div>
                                            <div class="form-group col-md-4">
                                                <label>Owner Email ID</label>
                                                <input type="email" disabled class="form-control" id="email" value={this.state.editOwnerEmailID}
                                                    onChange={this.OnEditOwnerEmailID} />
                                            </div>
                                            <div class="form-group col-md-4">
                                                <label>Contact Number <i class="fas fa-asterisk asterisk"></i></label>
                                                <input type="text" maxLength="14" class="form-control" id="contactnumber" value={this.state.editContactNumber}
                                                    onChange={this.OnEditContactNumber} />
                                                <span class="alert-small-text" id="valEditContactNumber">{this.state.validationErrors["editContactNumber"]}</span>
                                            </div>
                                            <div class="form-group col-md-4">
                                                <label>Address <i class="fas fa-asterisk asterisk"></i></label>
                                                <input type="text" maxLength="50" class="form-control" id="address" value={this.state.editAddress}
                                                    onChange={this.OnEditAddress} />
                                                <span class="alert-small-text" id="valEditAddress">{this.state.validationErrors["editAddress"]}</span>
                                            </div>
                                            <div class="form-group col-md-2 whoc-col-2">
                                                <label>City <i class="fas fa-asterisk asterisk"></i></label>
                                                <input type="text" maxLength="50" class="form-control" id="address" value={this.state.editCity}
                                                    onChange={this.OnEditCity} />
                                                <span class="alert-small-text" id="valEditCity">{this.state.validationErrors["editCity"]}</span>
                                            </div>
                                            <div class="form-group col-md-2 whoc-col-2">
                                                <label>State <i class="fas fa-asterisk asterisk"></i></label>
                                                <select className="form-control" onChange={this.OnEditState} value={this.state.editState}>
                                                    <option value="">Select</option>
                                                    <option value="AA"> AA </option>
                                                    <option value="AE"> AE </option>
                                                    <option value="AK"> AK </option>
                                                    <option value="AL"> AL </option>
                                                    <option value="AP"> AP </option>
                                                    <option value="AR"> AR </option>
                                                    <option value="AS"> AS </option>
                                                    <option value="AZ"> AZ </option>
                                                    <option value="CA"> CA </option>
                                                    <option value="CO"> CO </option>
                                                    <option value="CT"> CT </option>
                                                    <option value="DC"> DC </option>
                                                    <option value="DE"> DE </option>
                                                    <option value="FL"> FL </option>
                                                    <option value="GA"> GA </option>
                                                    <option value="GU"> GU </option>
                                                    <option value="HI"> HI </option>
                                                    <option value="IA"> IA </option>
                                                    <option value="ID"> ID </option>
                                                    <option value="IL"> IL </option>
                                                    <option value="IN"> IN </option>
                                                    <option value="KS"> KS </option>
                                                    <option value="KY"> KY </option>
                                                    <option value="LA"> LA </option>
                                                    <option value="MA"> MA </option>
                                                    <option value="MD"> MD </option>
                                                    <option value="ME"> ME </option>
                                                    <option value="MI"> MI </option>
                                                    <option value="MN"> MN </option>
                                                    <option value="MO"> MO </option>
                                                    <option value="MP"> MP </option>
                                                    <option value="MS"> MS </option>
                                                    <option value="MT"> MT </option>
                                                    <option value="NC"> NC </option>
                                                    <option value="ND"> ND </option>
                                                    <option value="NE"> NE </option>
                                                    <option value="NH"> NH </option>
                                                    <option value="NJ"> NJ </option>
                                                    <option value="NM"> NM </option>
                                                    <option value="NV"> NV </option>
                                                    <option value="NY"> NY </option>
                                                    <option value="OH"> OH </option>
                                                    <option value="OK"> OK </option>
                                                    <option value="OR"> OR </option>
                                                    <option value="PA"> PA </option>
                                                    <option value="PR"> PR </option>
                                                    <option value="RI"> RI </option>
                                                    <option value="SC"> SC </option>
                                                    <option value="SD"> SD </option>
                                                    <option value="TN"> TN </option>
                                                    <option value="TX"> TX </option>
                                                    <option value="UT"> UT </option>
                                                    <option value="VA"> VA </option>
                                                    <option value="VI"> VI </option>
                                                    <option value="VT"> VT </option>
                                                    <option value="WA"> WA </option>
                                                    <option value="WI"> WI </option>
                                                    <option value="WV"> WV </option>
                                                    <option value="WY"> WY </option>
                                                </select>
                                                <span class="alert-small-text" id="valEditState">{this.state.validationErrors["editState"]}</span>
                                            </div>
                                            <div class="form-group col-md-2 whoc-col-2">
                                                <label>Zip <i class="fas fa-asterisk asterisk"></i></label>
                                                <input type="text" maxLength="5" class="form-control" id="address" value={this.state.editZip}
                                                    onChange={this.OnEditZip} />
                                                <span class="alert-small-text" id="valEditZip">{this.state.validationErrors["editZip"]}</span>
                                            </div>
                                            <div class="form-group col-md-4">
                                                <label>Company</label>
                                                {/* <input type="text" maxLength="50" class="form-control" id="company" value={this.state.editCompany}
                                                    onChange={this.OnEditCompany} /> */}

                                                {this.state.selectedManagementCompany && this.state.selectedManagementCompany.Id !== undefined && this.state.selectedManagementCompany.Id !== null ?
                                                    (
                                                        <Autocomplete className="uw-autocomplete autocomplete-disable"
                                                            disabled
                                                            value={this.state.selectedManagementCompany}
                                                            onChange={(event, newValue1) => {
                                                                if (typeof newValue1 === 'string') {
                                                                    this.setState({ selectedManagementCompany: newValue1 });
                                                                } else if (newValue1 && newValue1.inputValue) {
                                                                    this.state.managementCompanies.push({ title: newValue1.inputValue, Id: "0" });
                                                                    this.setState({ selectedManagementCompany: { title: newValue1.inputValue, Id: "0" } });
                                                                } else {
                                                                    this.setState({ selectedManagementCompany: newValue1 });
                                                                }
                                                            }}
                                                            filterOptions={(options, params) => {
                                                                const filtered = filter(options, params);
                                                                let isFound = false;
                                                                filtered.forEach(e => {
                                                                    if (e.title === params.inputValue) {
                                                                        isFound = true;
                                                                    }
                                                                });
                                                                // Suggest the creation of a new value
                                                                if (params.inputValue !== '' && !isFound) {
                                                                    filtered.push({
                                                                        inputValue: params.inputValue,
                                                                        //title: `Add "${params.inputValue}"`,
                                                                        title: `+ New Company "${params.inputValue}"`,
                                                                    });
                                                                }
                                                                return filtered;
                                                            }}
                                                            selectOnFocus
                                                            clearOnBlur
                                                            handleHomeEndKeys
                                                            //autoComplete={true}
                                                            autoSelect={true}
                                                            id="company"
                                                            options={this.state.managementCompanies}
                                                            getOptionLabel={(option) => {
                                                                // Value selected with enter, right from the input
                                                                if (typeof option === 'string') {
                                                                    return option;
                                                                }
                                                                // Add "xyz" option created dynamically
                                                                if (option.inputValue) {
                                                                    return option.inputValue;
                                                                }
                                                                // Regular option
                                                                return option.title;
                                                            }}
                                                            renderOption={(option) => option.title}
                                                            //freeSolo
                                                            renderInput={(params) => (
                                                                <TextField {...params} className="form-control" />
                                                            )}
                                                        />
                                                    ) : (
                                                        <input type="text" disabled class="form-control" id="companyEmpty" value="N/A" />
                                                    )
                                                }
                                                <span class="alert-small-text" id="valEditCompany">{this.state.validationErrors["editCompany"]}</span>
                                            </div>
                                            <div class="form-group col-md-4">
                                                <label>Additional Phone</label>
                                                <input type="text" maxLength="14" class="form-control" id="additionalPhone" value={this.state.editAdditionalPhone}
                                                    onChange={this.OnEditAdditionalPhone} />
                                                <span class="alert-small-text" id="valEditAdditionalPhone">{this.state.validationErrors["editAdditionalPhone"]}</span>
                                            </div>
                                            <div class="form-group col-md-4">
                                                <label>Personal Mailing Address</label>
                                                <input type="text" maxLength="40" class="form-control" id="personalMailingAddress" value={this.state.editPersonalMailingAddress}
                                                    onChange={this.OnEditPersonalMailingAddress} />
                                                <span class="alert-small-text" id="valEditPersonalMailingAddress">{this.state.validationErrors["editPersonalMailingAddress"]}</span>
                                            </div>
                                            {/* <div class="form-group col-md-12">
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <legend class="col-form-label">Can we recognize you as a leader to end homelessness through WelcomeHomeOC? </legend>
                                                    </div>
                                                    <div className="col-auto mt-1">
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" id="recogniz" name="recogniz" class="custom-control-input"
                                                                checked={this.state.editWantToBeRecognized === 1}
                                                                onChange={this.OnEditWantToBeRecognizedYes} />
                                                            <label class="custom-control-label" for="recogniz">Yes</label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" id="recogniz2" name="recogniz" class="custom-control-input"
                                                                checked={this.state.editWantToBeRecognized === 0}
                                                                onChange={this.OnEditWantToBeRecognizedNo} />
                                                            <label class="custom-control-label" for="recogniz2">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span class="alert-small-text" id="valEditWantToBeRecognized">{this.state.validationErrors["editWantToBeRecognized"]}</span>
                                            </div> */}

                                            {/* <div class="form-group col-md-12">
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <legend class="col-form-label">Are you currently set-up as a vendor with a Public Housing Authority (PHA)? </legend>
                                                    </div>
                                                    <div className="col-auto mt-1">
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" id="vendorWithPHAYes" name="vendorWithPHA" class="custom-control-input"
                                                                checked={this.state.editIsVendorOfPHA === 1}
                                                                onChange={this.OnEditIsVendorOfPHAYes} />
                                                            <label class="custom-control-label" for="vendorWithPHAYes">Yes</label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" id="vendorWithPHANo" name="vendorWithPHA" class="custom-control-input"
                                                                checked={this.state.editIsVendorOfPHA === 0}
                                                                onChange={this.OnEditIsVendorOfPHANo} />
                                                            <label class="custom-control-label" for="vendorWithPHANo">No</label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" id="vendorWithPHAUnsure" name="vendorWithPHA" class="custom-control-input"
                                                                checked={this.state.editIsVendorOfPHA === -1}
                                                                onChange={this.OnEditIsVendorOfPHAUnsure} />
                                                            <label class="custom-control-label" for="vendorWithPHAUnsure">Unsure</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.editIsVendorOfPHA === 1 &&
                                                    <div class="form-sub-category">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheckIsVendorOfOCHA"
                                                                checked={this.state.editIsVendorOfOCHA === true || this.state.editIsVendorOfOCHA === "true"}
                                                                onChange={this.OnEditIsVendorOfOCHA} ref={this.InputIsVendorOfPHA} />
                                                            <label class="custom-control-label" for="customCheckIsVendorOfOCHA">Orange County Housing Authority (OCHA)</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheckIsVendorOfSAHA"
                                                                checked={this.state.editIsVendorOfSAHA === true || this.state.editIsVendorOfSAHA === "true"}
                                                                onChange={this.OnEditIsVendorOfSAHA} />
                                                            <label class="custom-control-label" for="customCheckIsVendorOfSAHA">Santa Ana Housing Authority (SAHA)</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheckIsVendorOfAHA"
                                                                checked={this.state.editIsVendorOfAHA === true || this.state.editIsVendorOfAHA === "true"}
                                                                onChange={this.OnEditIsVendorOfAHA} />
                                                            <label class="custom-control-label" for="customCheckIsVendorOfAHA">Anaheim Housing Authority</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheckIsVendorOfGGHA"
                                                                checked={this.state.editIsVendorOfGGHA === true || this.state.editIsVendorOfGGHA === "true"}
                                                                onChange={this.OnEditIsVendorOfGGHA} />
                                                            <label class="custom-control-label" for="customCheckIsVendorOfGGHA">Garden Grove Housing Authority</label>
                                                        </div>
                                                    </div>
                                                }
                                                <span class="alert-small-text" id="valEditIsVendorOfPHA">{this.state.validationErrors["editIsVendorOfPHA"]}</span>
                                            </div> */}

                                            <div class="form-group col-md-12">
                                                <legend class="col-form-label">I am a member of: </legend>
                                                <div class="form-sub-category">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="customCheckMemberOfAAOC"
                                                            checked={this.state.editIsMemberOfAAOC === true || this.state.editIsMemberOfAAOC === "true"}
                                                            onChange={this.OnEditIsMemberOfAAOC} ref={this.InputIsMemberOf} />
                                                        <label class="custom-control-label" for="customCheckMemberOfAAOC">Apartment Association of Orange County (AAOC)</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="customCheckMemberOfCAA"
                                                            checked={this.state.editIsMemberOfCAA === true || this.state.editIsMemberOfCAA === "true"}
                                                            onChange={this.OnEditIsMemberOfCAA} />
                                                        <label class="custom-control-label" for="customCheckMemberOfCAA">California Apartment Association (CAA)</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customCheckMemberOfAnyOther"
                                                            checked={this.state.editIsMemberOfAnyOther === true || this.state.editIsMemberOfAnyOther === "true"}
                                                            onChange={this.OnEditIsMemberOfAnyOther} />
                                                        <label class="custom-control-label" for="customCheckMemberOfAnyOther">Other</label>
                                                    </div>
                                                    {(this.state.editIsMemberOfAnyOther === true || this.state.editIsMemberOfAnyOther === "true") &&
                                                        <input class="form-control w-50 custom-control-inline" type="text" placeholder=""
                                                            value={this.state.editMemberOfAnyOtherText}
                                                            onChange={this.OnEditMemberOfAnyOtherText} />
                                                    }
                                                </div>
                                                <span class="alert-small-text" id="valEditIsMemberOf">{this.state.validationErrors["editIsMemberOf"]}</span>
                                            </div>

                                            <div class="form-group col-md-12">
                                                <label class="new-label">I have rental properties in the following areas: 
                                                <i class="fas fa-asterisk asterisk"></i>
                                                 </label>
                                               
                                                <div className="form-sub-category">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="customCheckHaveRentalPropertiesInNorthOC"
                                                            checked={this.state.editHaveRentalPropertiesInNorthOC === true || this.state.editHaveRentalPropertiesInNorthOC === "true"}
                                                            onChange={this.OnEditHaveRentalPropertiesInNorthOC} ref={this.InputHaveRentalProperties} />
                                                        <label class="custom-control-label" for="customCheckHaveRentalPropertiesInNorthOC">North Orange County</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="customCheckHaveRentalPropertiesInCentralOC"
                                                            checked={this.state.editHaveRentalPropertiesInCentralOC === true || this.state.editHaveRentalPropertiesInCentralOC === "true"}
                                                            onChange={this.OnEditHaveRentalPropertiesInCentralOC} />
                                                        <label class="custom-control-label" for="customCheckHaveRentalPropertiesInCentralOC">Central Orange County</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="customCheckHaveRentalPropertiesInSouthOC"
                                                            checked={this.state.editHaveRentalPropertiesInSouthOC === true || this.state.editHaveRentalPropertiesInSouthOC === "true"}
                                                            onChange={this.OnEditHaveRentalPropertiesInSouthOC} />
                                                        <label class="custom-control-label" for="customCheckHaveRentalPropertiesInSouthOC">South Orange County</label>
                                                    </div>
                                                </div>
                                                <span class="alert-small-text" id="valEditHaveRentalPropertiesIn">{this.state.validationErrors["editHaveRentalPropertiesIn"]}</span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary btn-light btn-sm" onClick={this.resetProfile} >Reset</button>
                                    <button type="button" class="btn btn-primary btn-black btn-sm" onClick={this.saveProfile} >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* edit profile modal END*/}

                    <React.Fragment>
                        <Backdrop open={this.state.isLoading} >
                            <CircularProgress />
                        </Backdrop>
                        <Snackbar anchorOrigin={{ vertical: this.state.snackbarVertical, horizontal: this.state.snackbarHorizontal }}
                            open={this.state.openSnackbar}
                            autoHideDuration={3000}
                            severity={this.state.snackbarSeverity}
                            onClose={() => this.setState({ openSnackbar: false })}
                            message={this.state.snackbarMessage}
                            className={this.state.snackbarMessageClass}
                            key={this.state.snackbarVertical + this.state.snackbarHorizontal}
                        />
                    </React.Fragment>

                </div>
            );
        }
        else {
            return null;
        }
    }
}

export default withRouter(PropertyOwnerProfile);
