import React from "react";
import imgLogo from './../imgs/welcomhome-oc-horizontal.png';

import {
    Route,
    NavLink,
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from "@material-ui/core/StepButton";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { API, Storage, Auth } from 'aws-amplify';

import { CircularProgress } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import PHARegistrationStep1 from './phaRegistrationStep1'
import PHARegistrationStep2 from './phaRegistrationStep2'
import PHARegistrationStep3 from './phaRegistrationStep3'
import PHARegistrationStep4 from './phaRegistrationStep4'
import PHARegistrationStep5 from './phaRegistrationStep5'
import PHARegistrationComplete from './phaRegistrationComplete'
import Tooltip from '@material-ui/core/Tooltip';

let UserName = '';

let classes = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    buttons: {
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
    }
}));

class PHARegistration extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            pagetitle: '',
            pageicon: '',
            pageimg: '',

            isLoading: false,

            activeStep: 0,
            prevActiveStep: 0,
            steps: [],

            w9Uploaded: false,
            allowNavigation: true,
        }

        this.propertyInfo = [];

        this.showHTML = false;
        this.step1Validator = React.createRef();
        this.step2Validator = React.createRef();
        this.step3Validator = React.createRef();
        this.step4Validator = React.createRef();
        this.step5Validator = React.createRef();
    }

    signOut = async () => {
        try {
            await Auth.signOut()
            // signout success
            window.location.assign("/");
        }
        catch (e) {
            console.log(e)
        }
    }

    callbackFunction = (title, icon, img) => {
        this.setState({ pagetitle: title });
        this.setState({ pageicon: icon })
        this.setState({ pageimg: img })
    }

    step1Callback = (w9Uploaded) => {
        //console.log('step1Callback');
        //console.log(w9Uploaded);
        this.setState({ w9Uploaded: w9Uploaded });
    }

    dummyRequest = () => {
        return new Promise((resolve, reject) => {
            window.setTimeout(
                () => {
                    resolve();
                },
                5000
            );
        });
    }

    async componentDidMount() {
        //console.log(+this.props.match.params.id);

        try {
            await Auth.currentAuthenticatedUser();
        }
        catch (err) {
            console.log(err);
            this.signOut();
            return;
        }

        this.setState({ isLoading: true }, () => this.loadPropertyData());
    }

    loadPropertyData = async () => {
        var info = await this.getPropertyDetails();

        //console.log('property details');
        //console.log(info);

        if (info.length > 0) {
            this.propertyInfo = info;

            let currentStep = info[0]["PHARegistrationActiveStep"];
            if (info[0]["PHAStatus"] === "Registered") {
                currentStep = this.getSteps().length; // Show the completed step
                this.setState({ allowNavigation: false });
            }
            else if (info[0]["PHAStatus"] === "Under Process") {
                if (this.propertyInfo[0]["AHASigner1Status"] === 'Completed' &&
                    this.propertyInfo[0]["OCHASigner1Status"] === 'Completed' &&
                    this.propertyInfo[0]["SAHASigner1Status"] === 'Completed') {
                    currentStep = 4;    // Show only review and sign step and do now allow navigation
                    this.setState({ allowNavigation: false });
                }
                // else {
                //     currentStep = 3;    // Show only documents upload step
                //     this.setState({allowNavigation: true});
                // }
            }
            else {
                currentStep = info[0]["PHARegistrationActiveStep"];
                this.setState({ allowNavigation: true });
            }

            this.setState({
                activeStep: currentStep,
                w9Uploaded: (info[0]["W9DocumentName"] !== "" && info[0]["W9DocumentPath"] !== "")

            }, () => {
                this.showHTML = true;
                this.setState({ steps: this.getSteps(), isLoading: false });
            });
        }
    }

    getPropertyDetails = async () => {
        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/getpropertydetailsbypropertyid';
        let testId = this.props.location.state?.ID ;
        let formattedId = testId && testId.toString() || localStorage.getItem('url').split('/')[4].split('?')[0];
        let myInit = {
            body: { "PropertyID": +formattedId },
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    updatePropertyRegistrationDetails = async () => {
        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/updatepropertyregistrationdetails';
        let myInit = {
            body: this.propertyInfo[0],
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    getSteps() {
        return ['Instructions for Owner', 'Property Registration Form', 'Regulations', 'Documents', 'Review & Sign'];
    }

    getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <Route exact path="/pharegistration" render={props => <PHARegistrationStep1 {...props} ref={this.step1Validator} propertyInfo={this.propertyInfo} parentCallback={this.step1Callback} />}></Route>
                );
            case 1:
                return (
                    <Route exact path="/pharegistration" render={props => <PHARegistrationStep2 {...props} ref={this.step2Validator} propertyInfo={this.propertyInfo} parentCallback={this.callbackFunction} w9Uploaded={this.state.w9Uploaded} />}></Route>
                );
            case 2:
                return (
                    <Route exact path="/pharegistration" render={props => <PHARegistrationStep3 {...props} ref={this.step3Validator} propertyInfo={this.propertyInfo} parentCallback={this.callbackFunction} />}></Route>
                );
            case 3:
                return (
                    <Route exact path="/pharegistration" render={props => <PHARegistrationStep4 {...props} ref={this.step4Validator} propertyInfo={this.propertyInfo} parentCallback={this.callbackFunction} />}></Route>
                );
            case 4:
                return (
                    <Route exact path="/pharegistration" render={props => <PHARegistrationStep5 {...props} ref={this.step5Validator} propertyInfo={this.propertyInfo} parentCallback={this.callbackFunction} />}></Route>
                );
            default:
                return ('Unknown stepIndex');
        }
    }

    validateStep = async (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return await this.step1Validator.current.validateForm();
            case 1:
                return await this.step2Validator.current.validateForm();
            case 2:
                return await this.step3Validator.current.validateForm();
            case 3:
                return await this.step4Validator.current.validateForm();
            case 4:
                return await this.step5Validator.current.validateForm();
            default:
                break;
        }
        return true;
    }

    handleSave = async () => {
        switch (this.state.activeStep) {
            case 0:
                return await this.step1Validator.current.saveForm();
            case 1:
                return await this.step2Validator.current.saveForm();
            case 2:
                return await this.step3Validator.current.saveForm();
            case 3:
                return await this.step4Validator.current.saveForm();
            case 4:
                return await this.step5Validator.current.saveForm();
            default:
                break;
        }
    }

    handleNext = async () => {
        if (this.state.activeStep === this.state.steps.length - 1 &&
            (this.propertyInfo[0]["AHASigner1Status"] != 'Completed' ||
                this.propertyInfo[0]["OCHASigner1Status"] != 'Completed' ||
                this.propertyInfo[0]["SAHASigner1Status"] != 'Completed')) {
            alert('Please sign all 3 documents to submit this application.');
        }
        else if (await this.validateStep(this.state.activeStep)) {
            this.setState({ isLoading: true });

            if (this.state.activeStep !== this.state.steps.length - 1) {
                this.propertyInfo[0]["WhatToUpdate"] = "ActiveStep";
                this.propertyInfo[0]["PHARegistrationActiveStep"] = this.state.activeStep + 1;
                await this.updatePropertyRegistrationDetails();
            }

            this.setState({ isLoading: false, prevActiveStep: this.state.activeStep, activeStep: this.state.activeStep + 1 });
        }
    }

    handleBack = async () => {
        this.setState({ isLoading: true });

        this.propertyInfo[0]["WhatToUpdate"] = "ActiveStep";
        this.propertyInfo[0]["PHARegistrationActiveStep"] = this.state.activeStep - 1;
        await this.updatePropertyRegistrationDetails();

        this.setState({ isLoading: false, prevActiveStep: this.state.activeStep, activeStep: this.state.activeStep - 1 });
    }

    handleReset = async () => {
        this.setState({ isLoading: true });

        this.propertyInfo[0]["WhatToUpdate"] = "ActiveStep";
        this.propertyInfo[0]["PHARegistrationActiveStep"] = 0;
        await this.updatePropertyRegistrationDetails();

        this.setState({ isLoading: false, prevActiveStep: 0, activeStep: 0 });
    }

    handleStep = async (stepIndex) => {
        if (this.state.allowNavigation) {
            this.setState({ isLoading: true });

            // ToDo: Validate the navigation directly to the step = stepIndex
            this.setState({ prevActiveStep: this.state.activeStep, activeStep: stepIndex })

            this.propertyInfo[0]["WhatToUpdate"] = "ActiveStep";
            this.propertyInfo[0]["PHARegistrationActiveStep"] = stepIndex;
            await this.updatePropertyRegistrationDetails();

            this.setState({ isLoading: false });
        }
    }

    logoClick = function () {
        // window.location.assign("/profile");
        window.location.assign("/");
    }

    render() {
        if (this.showHTML === true) {
            return (
                <div className="d-flex flex-column sticky-footer-wrapper">
                    {/* header starts */}
                    <header>
                        <nav className="navbar fixed-top navbar-light navbar-inner bg-light py-1" id="headerBg">
                            <a className="mr-2 navbar-brand pt-0" onClick={() => this.logoClick()}>
                                <img src={imgLogo} height="22" className="logo" alt="WelcomeHome OC logo" loading="lazy" />
                            </a>
                            <div className="ml-auto">
                                <span className="text-primary">{UserName}</span>
                                <Tooltip title="Logout">
                                     <button className="btn btn-link text-secondary" onClick={this.signOut}><i className="fas fa-power-off ml-1"></i></button>
                                </Tooltip>
                            </div>
                        </nav>
                    </header>
                    {/* header ends */}

                    {/* main content starts */}
                    <main className="main flex-fill">
                        <div className="container-fluid">
                            <h2 className="profile-title mt-3"><i class="fas fa-file-signature mr-2"></i>Public Housing Authority Registration</h2>
                            <hr />

                            <div className={classes.root}>
                                <Stepper activeStep={this.state.activeStep} alternativeLabel>
                                    {this.state.steps.map((label, stepIndex) => (
                                        <Step key={label}>
                                            <StepButton onClick={(e) => { this.handleStep(stepIndex) }} >
                                                <StepLabel>{label}</StepLabel>
                                            </StepButton>
                                        </Step>
                                    ))}
                                </Stepper>
                                <div>
                                    {this.state.activeStep === this.state.steps.length ? (
                                        <div>
                                            <Typography className={classes.instructions}>
                                                <div>
                                                    <div style={{ display: this.state.showLogin }}>
                                                        <Route exact path="/pharegistration" render={props => <PHARegistrationComplete {...props} parentCallback={this.callbackFunction} />}></Route>
                                                    </div>
                                                </div>
                                            </Typography>
                                            <br />
                                            <Button onClick={this.handleReset} disabled={!this.state.allowNavigation}>Reset</Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <Typography className={classes.instructions}>
                                                {this.getStepContent(this.state.activeStep)}
                                            </Typography>

                                            <div className="d-flex justify-content-between mt-2">
                                                <button className="btn btn-primary btn-black  mb-2" onClick={this.handleSave} disabled={!this.state.allowNavigation}>
                                                    Save
                                                </button>
                                                <div>
                                                    <button
                                                        disabled={this.state.activeStep === 0 || !this.state.allowNavigation}
                                                        onClick={this.handleBack}
                                                        className="btn btn-primary btn-light" >
                                                        Back
                                                    </button>

                                                    <button className="btn btn-primary btn-black ml-2" onClick={this.handleNext} disabled={!this.state.allowNavigation}>
                                                        {this.state.activeStep === this.state.steps.length - 1 ? 'Submit' : 'Next'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>
                    </main>
                    {/* main content ends */}

                    {/* footer starts */}
                    <footer className="foot mt-auto text-white text-center">
                        <p className="mb-0">Powered by Orange County United Way</p>
                        {/* <Link target="_blank" to="/faqs" className="text-white">Read our FAQs</Link> */}
                    </footer>
                    {/* footer ends */}

                    <React.Fragment>
                        <Backdrop open={this.state.isLoading}>
                            <CircularProgress />
                        </Backdrop>
                    </React.Fragment>
                </div >
            );
        }
        else {
            return (
                <React.Fragment>
                    <Backdrop open={this.state.isLoading}>
                        <CircularProgress />
                    </Backdrop>
                </React.Fragment>
            );
        }
    }
}
export default PHARegistration;