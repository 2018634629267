import React from "react";
import { CircularProgress } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { API, Storage, Auth } from 'aws-amplify';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';

class PHARegistrationStep2 extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,

            openSnackbar: false,
            snackbarSeverity: "",
            snackbarVertical: 'top',
            snackbarHorizontal: 'right',
            snackbarMessage: "",
            snackbarMessageClass: '',

            // TRANSACTION
            selectedTransaction: 0,
            newDirectDepositRequest: 0,
            changeInformation: 0,

            // PROPERTY INFORMATION
            pi_Name: "",
            pi_Unit: "",
            pi_Address: "",
            pi_City: "",
            pi_State: "",
            pi_Zip: "",
            pi_Email: "",
            pi_Phone: "",
            pi_PhoneExtension: "",
            pi_Fax: "",

            // PROPERTY OWNER INFORMATION
            poi_Name: "",
            poi_BusinessName: "",
            poi_Address: "",
            poi_Phone: "",
            poi_Fax: "",
            poi_City: "",
            poi_State: "",
            poi_Zip: "",
            poi_Email: "",
            poi_SSN: "",
            poi_EID: "",
            poi_TaxID: "",

            // PAYEE INFORMATION
            pyei_Name: "",
            pyei_TaxID: "",
            pyei_SSN: "",
            pyei_Phone: "",
            pyei_PhoneExtension: "",
            pyei_Unit: "",
            pyei_Address: "",
            pyei_City: "",
            pyei_State: "",
            pyei_Zip: "",
            pyei_Email: "",
            pyei_EID: "",

            // TAX PAYER INFORMATION
            tpi_Name: "",
            tpi_BusinessName: "",
            selectedFederalTaxClassification: 0,
            tpi_FederalTaxClassification: "",
            selectedLLCClassification: 0,
            tpi_LLCClassification: "",
            tpi_OtherClassification: "",
            tpi_ExemptPayeeCode: "",
            tpi_FATCAReportingCode: "",
            tpi_Unit: "",
            tpi_Address: "",
            tpi_City: "",
            tpi_State: "",
            tpi_Zip: "",
            tpi_AccountNumbers: "",
            tpi_SSN: "",
            tpi_EID: "",
            tpi_Transaction: "",
            tpi_TenantName: "",

            // MANAGEMENT COMPANY INFORMATION
            mci_Name: "",
            mci_Phone: "",
            mci_PhoneExtension: "",
            mci_Fax: "",
            mci_Email: "",
            mci_Unit: "",
            mci_Address: "",
            mci_City: "",
            mci_State: "",
            mci_Zip: "",

            mci_Name1: "",
            mci_Phone1: "",
            mci_PhoneExtension1: "",
            mci_Fax1: "",
            mci_Email1: "",
            mci_Unit1: "",
            mci_Address1: "",
            mci_City11: "",
            mci_State: "",
            mci_Zip1: "",

            mci_ManagerID: -1,
            mci_PropertyID: -1,
            mci_Title: "",
            mci_FirstName: "",
            mci_MiddleName: "",
            mci_LastName: "",
            mci_Emailid: "",
            mci_ContactType: "",

            mci_Managers: [],


            // FINANCIAL INSTITUTION INFORMATION
            fii_Name: "",
            selectedAccountType: 0,
            fii_AccountType: "",
            fii_Unit: "",
            fii_Address: "",
            fii_City: "",
            fii_State: "",
            fii_Zip: "",
            fii_RoutingTransitNumber: "",
            fii_AccountNumber: "",

            // HOUSING AUTHORITIES CORRESPONDENCE INFORMATION
            haci_Name: "",
            haci_InCareOf: "",
            haci_Unit: "",
            haci_Address: "",
            haci_Phone: "",
            haci_PhoneExtension: "",
            haci_Fax: "",
            haci_Email: "",

            // REQUESTER INFORMATION
            ri_Name: "",
            ri_Unit: "",
            ri_Address: "",
            ri_City: "",
            ri_State: "",
            ri_Zip: "",


            // ERROR - TRANSACTION
            transaction_Error: "",

            // ERROR - PROPERTY INFORMATION
            pi_Name_Error: "",
            pi_Address_Error: "",
            pi_City_Error: "",
            pi_State_Error: "",
            pi_Zip_Error: "",
            pi_Email_Error: "",
            pi_Phone_Error: "",
            pi_Fax_Error: "",

            // ERROR - PROPERTY OWNER INFORMATION
            poi_Name_Error: "",
            poi_BusinessName_Error: "",
            poi_Address_Error: "",
            poi_Phone_Error: "",
            poi_Fax_Error: "",
            poi_City_Error: "",
            poi_State_Error: "",
            poi_Zip_Error: "",
            poi_Email_Error: "",
            poi_SSN_Error: "",
            poi_EID_Error: "",
            poi_TaxID_Error: "",

            // ERROR - PAYEE INFORMATION
            pyei_Name_Error: "",
            pyei_TaxID_Error: "",
            pyei_SSN_Error: "",
            pyei_Phone_Error: "",
            pyei_Address_Error: "",
            pyei_City_Error: "",
            pyei_State_Error: "",
            pyei_Zip_Error: "",
            pyei_Email_Error: "",
            pyei_EID_Error: "",

            // ERROR - TAX PAYER INFORMATION
            tpi_Name_Error: "",
            tpi_BusinessName_Error: "",
            selectedFederalTaxClassification_Error: 0,
            tpi_FederalTaxClassification_Error: "",
            selectedLLCClassification_Error: 0,
            tpi_LLCClassification_Error: "",
            tpi_OtherClassification_Error: "",
            tpi_ExemptPayeeCode_Error: "",
            tpi_FATCAReportingCode_Error: "",
            tpi_Address_Error: "",
            tpi_City_Error: "",
            tpi_State_Error: "",
            tpi_Zip_Error: "",
            tpi_AccountNumbers_Error: "",
            tpi_SSN_Error: "",
            tpi_EID_Error: "",
            tpi_Transaction_Error: "",
            tpi_TenantName_Error: "",

            // ERROR - MANAGEMENT COMPANY INFORMATION
            mci_Name_Error: "",
            mci_Phone_Error: "",
            mci_Fax_Error: "",
            mci_Email_Error: "",
            mci_Address_Error: "",
            mci_City_Error: "",
            mci_State_Error: "",
            mci_Zip_Error: "",

            mci_Name_Error1: "",
            mci_Phone_Error1: "",
            mci_Fax_Error1: "",
            mci_Email_Error1: "",
            mci_Address_Error1: "",
            mci_City_Error1: "",
            mci_State_Error1: "",
            mci_Zip_Error1: "",

            // ERROR - FINANCIAL INSTITUTION INFORMATION
            fii_Name_Error: "",
            selectedAccountType_Error: 0,
            fii_AccountType_Error: "",
            fii_Address_Error: "",
            fii_City_Error: "",
            fii_State_Error: "",
            fii_Zip_Error: "",
            fii_RoutingTransitNumber_Error: "",
            fii_AccountNumber_Error: "",

            // ERROR - HOUSING AUTHORITIES CORRESPONDENCE INFORMATION
            haci_Name_Error: "",
            haci_InCareOf_Error: "",
            haci_Address_Error: "",
            haci_Phone_Error: "",
            haci_Fax_Error: "",
            haci_Email_Error: "",

            // ERROR - REQUESTER INFORMATION
            ri_Name_Error: "",
            ri_Address_Error: "",
            ri_City_Error: "",
            ri_State_Error: "",
            ri_Zip_Error: "",
        }

        this.propertyInfo = [];

        // TRANSACTION INFORMATION
        this.piTenantNameRef = React.createRef();
        this.TransactionRef = React.createRef();

        // PROPERTY INFORMATION
        this.piNameRef = React.createRef();
        this.piAddressRef = React.createRef();
        this.piCityRef = React.createRef();
        this.piStateRef = React.createRef();
        this.piZipRef = React.createRef();
        this.piEmailRef = React.createRef();
        this.piPhoneRef = React.createRef();
        this.piFaxRef = React.createRef();

        // PROPERTY OWNER INFORMATION
        this.poiNameRef = React.createRef();
        this.poiBusinessNameRef = React.createRef();
        this.poiAddressRef = React.createRef();
        this.poiPhoneRef = React.createRef();
        this.poiFaxRef = React.createRef();
        this.poiCityRef = React.createRef();
        this.poiStateRef = React.createRef();
        this.poiZipRef = React.createRef();
        this.poiEmailRef = React.createRef();
        this.poiSSNRef = React.createRef();
        this.poiEIDRef = React.createRef();
        this.poiTaxIDRef = React.createRef();

        // PAYEE INFORMATION
        this.pyeiNameRef = React.createRef();
        this.pyeiTaxIDRef = React.createRef();
        this.pyeiSSNRef = React.createRef();
        this.pyeiPhoneRef = React.createRef();
        this.pyeiAddressRef = React.createRef();
        this.pyeiCityRef = React.createRef();
        this.pyeiStateRef = React.createRef();
        this.pyeiZipRef = React.createRef();
        this.pyeiEmailRef = React.createRef();
        this.pyeiEIDRef = React.createRef();

        // TAX PAYER INFORMATION
        this.tpiNameRef = React.createRef();
        this.tpiBusinessNameRef = React.createRef();
        this.tpiFederalTaxClassificationRef = React.createRef();
        this.tpiLLCClassificationRef = React.createRef();
        this.tpiOtherClassificationRef = React.createRef();
        this.tpiExemptPayeeCodeRef = React.createRef();
        this.tpiFATCAReportingCodeRef = React.createRef();
        this.tpiAddressRef = React.createRef();
        this.tpiCityRef = React.createRef();
        this.tpiStateRef = React.createRef();
        this.tpiZipRef = React.createRef();
        this.tpiAccountNumbersRef = React.createRef();
        this.tpiSSNRef = React.createRef();
        this.tpiEIDRef = React.createRef();

        // MANAGEMENT COMPANY INFORMATION
        this.mciNameRef = React.createRef();
        this.mciPhoneRef = React.createRef();
        this.mciFaxRef = React.createRef();
        this.mciEmailRef = React.createRef();
        this.mciAddressRef = React.createRef();
        this.mciCityRef = React.createRef();
        this.mciStateRef = React.createRef();
        this.mciZipRef = React.createRef();

        this.mciNameRef1 = React.createRef();
        this.mciPhoneRef1 = React.createRef();
        this.mciFaxRef1 = React.createRef();
        this.mciEmailRef1 = React.createRef();
        this.mciAddressRef1 = React.createRef();
        this.mciCityRef1 = React.createRef();
        this.mciStateRef1 = React.createRef();
        this.mciZipRef1 = React.createRef();

        // FINANCIAL INSTITUTION INFORMATION
        this.fiiNameRef = React.createRef();
        this.fiiAccountTypeRef = React.createRef();
        this.fiiAddressRef = React.createRef();
        this.fiiCityRef = React.createRef();
        this.fiiStateRef = React.createRef();
        this.fiiZipRef = React.createRef();
        this.fiiRoutingTransitNumberRef = React.createRef();
        this.fiiAccountNumberRef = React.createRef();

        // HOUSING AUTHORITIES CORRESPONDENCE INFORMATION
        this.haciNameRef = React.createRef();
        this.haciInCareOfRef = React.createRef();
        this.haciAddressRef = React.createRef();
        this.haciPhoneRef = React.createRef();
        this.haciFaxRef = React.createRef();
        this.haciEmailRef = React.createRef();

        // REQUESTER INFORMATION
        this.riNameRef = React.createRef();
        this.riAddressRef = React.createRef();
        this.riCityRef = React.createRef();
        this.riStateRef = React.createRef();
        this.riZipRef = React.createRef();
    }

    async componentDidMount() {
        //console.log(+this.props.match.params.id);
        this.setState({ isLoading: true });

        var info = await this.getPropertyDetails();
        if (info.length > 0) {
            this.propertyInfo = info;
        }

        //console.log('property registration details');
        //console.log(info);

        //console.log('this.props.w9Uploaded');
        //console.log(this.props.w9Uploaded);

        var mciManager = '';

        if (info.length > 0) {
            this.setState({
                // PROPERTY INFORMATION
                pi_Name: info[0]["PropertyName"],
                pi_Unit: info[0]["Unit"],
                pi_Address: info[0]["Address"],
                pi_City: info[0]["City"],
                pi_State: info[0]["State"],
                pi_Zip: info[0]["Zipcode"],
                pi_Email: info[0]["Email"],
                pi_Phone: (info[0]["Phone"] === null || info[0]["Phone"] === undefined) ? '' : info[0]["Phone"].replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                pi_PhoneExtension: info[0]["PhoneExtension"],
                pi_Fax: info[0]["Fax"],

                // PROPERTY OWNER INFORMATION
                poi_Name: info[0]["poi_Name"],
                poi_BusinessName: info[0]["poi_BusinessName"],
                poi_Address: info[0]["poi_Address"],
                poi_Phone: (info[0]["poi_Phone"] === null || info[0]["poi_Phone"] === undefined) ? '' : info[0]["poi_Phone"].replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                poi_Fax: info[0]["poi_Fax"],
                poi_City: info[0]["poi_City"],
                poi_State: info[0]["poi_State"],
                poi_Zip: info[0]["poi_Zip"],
                poi_Email: info[0]["poi_Email"],
                poi_SSN: info[0]["poi_SSN"],
                poi_EID: info[0]["poi_EID"],
                poi_TaxID: info[0]["poi_TaxID"],

                // PAYEE INFORMATION
                pyei_Name: info[0]["pyei_Name"],
                pyei_TaxID: info[0]["pyei_TaxID"],
                pyei_SSN: info[0]["pyei_SSN"],
                pyei_Phone: (info[0]["pyei_Phone"] === null || info[0]["pyei_Phone"] === undefined) ? '' : info[0]["pyei_Phone"].replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                pyei_PhoneExtension: info[0]["pyei_PhoneExtension"],
                pyei_Unit: info[0]["pyei_Unit"],
                pyei_Address: info[0]["pyei_Address"],
                pyei_City: info[0]["pyei_City"],
                pyei_State: info[0]["pyei_State"],
                pyei_Zip: info[0]["pyei_Zip"],
                pyei_Email: info[0]["pyei_Email"],
                pyei_EID: info[0]["pyei_EID"],

                // TAX PAYER INFORMATION
                tpi_Name: info[0]["tpi_Name"],
                tpi_BusinessName: info[0]["tpi_BusinessName"],
                //selectedFederalTaxClassification: 0,
                tpi_FederalTaxClassification: info[0]["tpi_FederalTaxClassification"],
                //selectedLLCClassification: 0,
                tpi_LLCClassification: info[0]["tpi_LLCClassification"],
                tpi_OtherClassification: info[0]["tpi_OtherClassification"],
                tpi_ExemptPayeeCode: info[0]["tpi_ExemptPayeeCode"],
                tpi_FATCAReportingCode: info[0]["tpi_FATCAReportingCode"],
                tpi_Unit: info[0]["tpi_Unit"],
                tpi_Address: info[0]["tpi_Address"],
                tpi_City: info[0]["tpi_City"],
                tpi_State: info[0]["tpi_State"],
                tpi_Zip: info[0]["tpi_Zip"],
                tpi_AccountNumbers: info[0]["tpi_AccountNumbers"],
                tpi_SSN: info[0]["tpi_SSN"],
                tpi_EID: info[0]["tpi_EID"],
                tpi_Transaction: info[0]["tpi_Transaction"],
                tpi_TenantName: info[0]["tpi_TenantName"],

                // // MANAGEMENT COMPANY INFORMATION
                // mci_Name: info[0]["mci_Name"],
                // mci_Phone: info[0]["mci_Phone"],
                // mci_Fax: info[0]["mci_Fax"],
                // mci_Email: info[0]["mci_Email"],
                // mci_Address: info[0]["mci_Address"],
                // mci_City: info[0]["mci_City"],
                // mci_State: info[0]["mci_State"],
                // mci_Zip: info[0]["mci_Zip"],

                // FINANCIAL INSTITUTION INFORMATION
                fii_Name: info[0]["fii_Name"],
                //selectedAccountType: 0,
                fii_AccountType: info[0]["fii_AccountType"],
                fii_Unit: info[0]["fii_Unit"],
                fii_Address: info[0]["fii_Address"],
                fii_City: info[0]["fii_City"],
                fii_State: info[0]["fii_State"],
                fii_Zip: info[0]["fii_Zip"],
                fii_RoutingTransitNumber: info[0]["fii_RoutingTransitNumber"],
                fii_AccountNumber: info[0]["fii_AccountNumber"],

                // HOUSING AUTHORITIES CORRESPONDENCE INFORMATION
                haci_Name: info[0]["haci_Name"],
                haci_InCareOf: info[0]["haci_InCareOf"],
                haci_Unit: info[0]["haci_Unit"],
                haci_Address: info[0]["haci_Address"],
                haci_Phone: (info[0]["haci_Phone"] === null || info[0]["haci_Phone"] === undefined) ? '' : info[0]["haci_Phone"].replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                haci_PhoneExtension: info[0]["haci_PhoneExtension"],
                haci_Fax: info[0]["haci_Fax"],
                haci_Email: info[0]["haci_Email"],

                // REQUESTER INFORMATION
                ri_Name: info[0]["ri_Name"],
                ri_Unit: info[0]["ri_Unit"],
                ri_Address: info[0]["ri_Address"],
                ri_City: info[0]["ri_City"],
                ri_State: info[0]["ri_State"],
                ri_Zip: info[0]["ri_Zip"],
            });
            mciManager = info[0]["mci_Name"];
        }

        var mgrs = await this.getPropertyManagersByPropertyId();

        //console.log('property managers details');
        //console.log(mgrs);

        var managerFound = false;
        if (mgrs.length > 0) {
            this.setState({
                // MANAGEMENT COMPANY INFORMATION
                mci_Managers: mgrs,
            });
            //console.log(this.state.mci_Managers.length);

            // MANAGEMENT COMPANY INFORMATION
            if (mciManager !== '') {
                mgrs.map((item, index) => {
                    var manager = item["mci_FirstName"] + ' ' + item["mci_MiddleName"] + ' ' + item["mci_LastName"];
                    if (mciManager === manager) {
                        managerFound = true;

                        this.setState({
                            mci_Name: manager,
                            mci_Phone: (item["mci_Phone"] === null || item["mci_Phone"] === undefined) ? '' : item["mci_Phone"].replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                            mci_PhoneExtension: item["mci_PhoneExtension"],
                            mci_Fax: item["mci_Fax"],
                            mci_Email: item["mci_Emailid"],
                            mci_Unit: item["mci_Unit"],
                            mci_Address: item["mci_Address"],
                            mci_City: item["mci_City"],
                            mci_State: item["mci_State"],
                            mci_Zip: item["mci_Zip"],

                            mci_ManagerID: item["mci_ManagerID"],
                            mci_PropertyID: item["mci_PropertyID"],
                            mci_Title: item["mci_Title"],
                            mci_FirstName: item["mci_FirstName"],
                            mci_MiddleName: item["mci_MiddleName"],
                            mci_LastName: item["mci_LastName"],
                            mci_ContactType: item["mci_ContactType"],
                        });
                    }
                });
            }
        }

        if (managerFound === false && info.length > 0) {
            this.setState({
                mci_Name1: info[0]["mci_Name"],
                mci_Phone1: (info[0]["mci_Phone"] === null || info[0]["mci_Phone"] === undefined) ? '' : info[0]["mci_Phone"].replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                mci_PhoneExtension1: info[0]["mci_PhoneExtension"],
                mci_Fax1: info[0]["mci_Fax"],
                mci_Email1: info[0]["mci_Email"],
                mci_Unit1: info[0]["mci_Unit"],
                mci_Address1: info[0]["mci_Address"],
                mci_City1: info[0]["mci_City"],
                mci_State1: info[0]["mci_State"],
                mci_Zip1: info[0]["mci_Zip"],
            });
        }

        // Transaction
        if (this.state.tpi_Transaction === "NewDirectDepositRequest") {
            document.getElementById("newDirectDepositRequest").checked = true;
            this.setState({ selectedTransaction: 1, newDirectDepositRequest: 1, changeInformation: 0 });
        }
        else if (this.state.tpi_Transaction === "ChangeInformation") {
            document.getElementById("changeInformation").checked = true;
            this.setState({ selectedTransaction: 1, newDirectDepositRequest: 0, changeInformation: 1 });
        }

        // Restore federal tax classification
        if (this.props.w9Uploaded === false) {
            if (this.state.tpi_FederalTaxClassification === "Individual") {
                document.getElementById("individual").checked = true;
            }
            else if (this.state.tpi_FederalTaxClassification === "CCorporation") {
                document.getElementById("ccorporation").checked = true;
            }
            else if (this.state.tpi_FederalTaxClassification === "SCorporation") {
                document.getElementById("scorporation").checked = true;
            }
            else if (this.state.tpi_FederalTaxClassification === "Partnership") {
                document.getElementById("partnership").checked = true;
            }
            else if (this.state.tpi_FederalTaxClassification === "Trust") {
                document.getElementById("trust").checked = true;
            }
            else if (this.state.tpi_FederalTaxClassification === "LLC") {
                document.getElementById("llc").checked = true;
                // document.getElementById("llcRadios").style.display = "block";
                document.getElementById("llcClassification_asterisk_Id").style.display = 'block';
                document.getElementById("llcClassificationId").disabled = false;
            }
            else if (this.state.tpi_FederalTaxClassification === "Other") {
                document.getElementById("other").checked = true;
                document.getElementById("txtOtherFedClassification_asterisk_id").style.display = 'block';
                document.getElementById("txtOtherFedClassification").readOnly = false;
            }

            if (this.state.tpi_FederalTaxClassification !== "LLC") {
                document.getElementById("llcClassification_asterisk_Id").style.display = 'none';
                document.getElementById("llcClassificationId").disabled = true;
                this.setState({ tpi_LLCClassification: "" });
            }
            if (this.state.tpi_FederalTaxClassification !== "Other") {
                document.getElementById("txtOtherFedClassification_asterisk_id").style.display = 'none';
                document.getElementById("txtOtherFedClassification").readOnly = true;
                this.setState({ tpi_OtherClassification: "" });
            }

            // // Restore LLC classification
            // if (this.state.tpi_LLCClassification === "CCorporation") {
            //     document.getElementById("llcCCorporation").checked = true;
            //     document.getElementById("llcRadios").style.display = "block";
            // }
            // else if (this.state.tpi_LLCClassification === "SCorporation") {
            //     document.getElementById("llcSCorporation").checked = true;
            //     document.getElementById("llcRadios").style.display = "block";
            // }
            // else if (this.state.tpi_LLCClassification === "Partnership") {
            //     document.getElementById("llcPartership").checked = true;
            //     document.getElementById("llcRadios").style.display = "block";
            // }
        }
        else {
            this.setState({
                tpi_FederalTaxClassification: "",
                tpi_LLCClassification: "",
                tpi_OtherClassification: "",
                tpi_ExemptPayeeCode: "",
                tpi_FATCAReportingCode: "",
            });
        }

        // Restore account type
        if (this.state.fii_AccountType === "Savings") {
            document.getElementById("savingAccount").checked = true;
        }
        else if (this.state.fii_AccountType === "Checking") {
            document.getElementById("checkingAccount").checked = true;
        }

        //this.piTenantNameRef.current.focus();
        window.scrollTo(0, 0); // scroll to the top of the window

        this.setState({ isLoading: false });
    }

    getPropertyManagersByPropertyId = async () => {
        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/getpropertymanagersbypropertyid';
        let testId = this.props.location.state?.ID ;
        let formattedId = testId.toString();
        let myInit = {
            body: { "PropertyID": +formattedId },
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    updatePropertyManagerDetails = async () => {
        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/updatepropertymanagerdetails';
        let testId = this.props.location.state?.ID ;
        let formattedId = testId.toString();
        let myInit = {
            body: {
                "mci_ManagerID": this.state.mci_ManagerID,
                "mci_PropertyID": +formattedId,
                "mci_Title": this.state.mci_Title,
                "mci_ContactType": this.state.mci_ContactType,
                // MANAGEMENT COMPANY INFORMATION
                "mci_FirstName": this.state.mci_FirstName,
                "mci_MiddleName": this.state.mci_MiddleName,
                "mci_LastName": this.state.mci_LastName,
                "mci_Unit": this.state.mci_Unit,
                "mci_Address": this.state.mci_Address,
                "mci_Emailid": this.state.mci_Email,
                "mci_Phone": (this.state.mci_Phone === null || this.state.mci_Phone === undefined) ? '' : this.state.mci_Phone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                "mci_PhoneExtension": this.state.mci_PhoneExtension,
                "mci_Fax": this.state.mci_Fax,
                "mci_City": this.state.mci_City,
                "mci_State": this.state.mci_State,
                "mci_Zip": this.state.mci_Zip,
            },
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    getPropertyDetails = async () => {
        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/getpropertydetailsbypropertyid';
        let testId = this.props.location.state?.ID ;
        let formattedId = testId.toString();
        let myInit = {
            body: { "PropertyID": +formattedId },
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    updatePropertyRegistrationDetails = async () => {
        let testId = this.props.location.state?.ID ;
        let formattedId = testId.toString();
        this.propertyInfo[0]["WhatToUpdate"] = "Step2";

        this.propertyInfo[0]["PropertyID"] = +formattedId;
        // this.propertyInfo[0]["PropertyOwnerID"] = -1;

        // PROPERTY INFORMATION
        this.propertyInfo[0]["PropertyName"] = this.state.pi_Name;
        this.propertyInfo[0]["Unit"] = this.state.pi_Unit;
        this.propertyInfo[0]["Address"] = this.state.pi_Address;
        this.propertyInfo[0]["City"] = this.state.pi_City;
        this.propertyInfo[0]["State"] = this.state.pi_State;
        this.propertyInfo[0]["Zipcode"] = this.state.pi_Zip;
        this.propertyInfo[0]["Email"] = this.state.pi_Email;
        this.propertyInfo[0]["Phone"] = (this.state.pi_Phone === null || this.state.pi_Phone === undefined) ? '' : this.state.pi_Phone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
        this.propertyInfo[0]["PhoneExtension"] = this.state.pi_PhoneExtension;
        this.propertyInfo[0]["Fax"] = this.state.pi_Fax;

        // PROPERTY OWNER INFORMATION
        this.propertyInfo[0]["poi_Name"] = this.state.poi_Name;
        this.propertyInfo[0]["poi_BusinessName"] = this.state.poi_BusinessName;
        this.propertyInfo[0]["poi_Address"] = this.state.poi_Address;
        this.propertyInfo[0]["poi_Phone"] = (this.state.poi_Phone === null || this.state.poi_Phone === undefined) ? '' : this.state.poi_Phone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
        this.propertyInfo[0]["poi_Fax"] = this.state.poi_Fax;
        this.propertyInfo[0]["poi_City"] = this.state.poi_City;
        this.propertyInfo[0]["poi_State"] = this.state.poi_State;
        this.propertyInfo[0]["poi_Zip"] = this.state.poi_Zip;
        this.propertyInfo[0]["poi_Email"] = this.state.poi_Email;
        this.propertyInfo[0]["poi_SSN"] = this.state.poi_SSN;
        this.propertyInfo[0]["poi_EID"] = this.state.poi_EID;
        this.propertyInfo[0]["poi_TaxID"] = this.state.poi_TaxID;

        // PAYEE INFORMATION
        this.propertyInfo[0]["pyei_Name"] = this.state.pyei_Name;
        this.propertyInfo[0]["pyei_TaxID"] = this.state.pyei_TaxID;
        this.propertyInfo[0]["pyei_SSN"] = this.state.pyei_SSN;
        this.propertyInfo[0]["pyei_EID"] = this.state.pyei_EID;
        this.propertyInfo[0]["pyei_Phone"] = (this.state.pyei_Phone === null || this.state.pyei_Phone === undefined) ? '' : this.state.pyei_Phone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
        this.propertyInfo[0]["pyei_PhoneExtension"] = this.state.pyei_PhoneExtension;
        this.propertyInfo[0]["pyei_Unit"] = this.state.pyei_Unit;
        this.propertyInfo[0]["pyei_Address"] = this.state.pyei_Address;
        this.propertyInfo[0]["pyei_Email"] = this.state.pyei_Email;
        this.propertyInfo[0]["pyei_City"] = this.state.pyei_City;
        this.propertyInfo[0]["pyei_State"] = this.state.pyei_State;
        this.propertyInfo[0]["pyei_Zip"] = this.state.pyei_Zip;
        this.propertyInfo[0]["pyei_Attention"] = this.state.pyei_Attention;

        // TAX PAYER INFORMATION
        this.propertyInfo[0]["tpi_Name"] = this.state.tpi_Name;
        this.propertyInfo[0]["tpi_BusinessName"] = this.state.tpi_BusinessName;
        this.propertyInfo[0]["tpi_FederalTaxClassification"] = this.state.tpi_FederalTaxClassification;
        this.propertyInfo[0]["tpi_LLCClassification"] = this.state.tpi_LLCClassification;
        this.propertyInfo[0]["tpi_OtherClassification"] = this.state.tpi_OtherClassification;
        this.propertyInfo[0]["tpi_ExemptPayeeCode"] = this.state.tpi_ExemptPayeeCode;
        this.propertyInfo[0]["tpi_FATCAReportingCode"] = this.state.tpi_FATCAReportingCode;
        this.propertyInfo[0]["tpi_Unit"] = this.state.tpi_Unit;
        this.propertyInfo[0]["tpi_Address"] = this.state.tpi_Address;
        this.propertyInfo[0]["tpi_City"] = this.state.tpi_City;
        this.propertyInfo[0]["tpi_State"] = this.state.tpi_State;
        this.propertyInfo[0]["tpi_Zip"] = this.state.tpi_Zip;
        this.propertyInfo[0]["tpi_AccountNumbers"] = this.state.tpi_AccountNumbers;
        this.propertyInfo[0]["tpi_SSN"] = this.state.tpi_SSN;
        this.propertyInfo[0]["tpi_EID"] = this.state.tpi_EID;
        this.propertyInfo[0]["tpi_Transaction"] = this.state.tpi_Transaction;
        this.propertyInfo[0]["tpi_TenantName"] = this.state.tpi_TenantName;

        // MANAGEMENT COMPANY INFORMATION
        if (this.state.mci_Name !== '') {
            this.propertyInfo[0]["mci_Name"] = this.state.mci_Name;
            this.propertyInfo[0]["mci_Phone"] = (this.state.mci_Phone === null || this.state.mci_Phone === undefined) ? '' : this.state.mci_Phone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
            this.propertyInfo[0]["mci_PhoneExtension"] = this.state.mci_PhoneExtension;
            this.propertyInfo[0]["mci_Fax"] = this.state.mci_Fax;
            this.propertyInfo[0]["mci_Email"] = this.state.mci_Email;
            this.propertyInfo[0]["mci_Unit"] = this.state.mci_Unit;
            this.propertyInfo[0]["mci_Address"] = this.state.mci_Address;
            this.propertyInfo[0]["mci_City"] = this.state.mci_City;
            this.propertyInfo[0]["mci_State"] = this.state.mci_State;
            this.propertyInfo[0]["mci_Zip"] = this.state.mci_Zip;
        }
        else if (this.state.mci_Name1 !== '') {
            this.propertyInfo[0]["mci_Name"] = this.state.mci_Name1;
            this.propertyInfo[0]["mci_Phone"] = (this.state.mci_Phone1 === null || this.state.mci_Phone1 === undefined) ? '' : this.state.mci_Phone1.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
            this.propertyInfo[0]["mci_PhoneExtension"] = this.state.mci_PhoneExtension1;
            this.propertyInfo[0]["mci_Fax"] = this.state.mci_Fax1;
            this.propertyInfo[0]["mci_Email"] = this.state.mci_Email1;
            this.propertyInfo[0]["mci_Unit"] = this.state.mci_Unit1;
            this.propertyInfo[0]["mci_Address"] = this.state.mci_Address1;
            this.propertyInfo[0]["mci_City"] = this.state.mci_City1;
            this.propertyInfo[0]["mci_State"] = this.state.mci_State1;
            this.propertyInfo[0]["mci_Zip"] = this.state.mci_Zip1;
        }

        // FINANCIAL INSTITUTION INFORMATION
        this.propertyInfo[0]["fii_Name"] = this.state.fii_Name;
        this.propertyInfo[0]["fii_AccountType"] = this.state.fii_AccountType;
        this.propertyInfo[0]["fii_Unit"] = this.state.fii_Unit;
        this.propertyInfo[0]["fii_Address"] = this.state.fii_Address;
        this.propertyInfo[0]["fii_City"] = this.state.fii_City;
        this.propertyInfo[0]["fii_State"] = this.state.fii_State;
        this.propertyInfo[0]["fii_Zip"] = this.state.fii_Zip;
        this.propertyInfo[0]["fii_RoutingTransitNumber"] = this.state.fii_RoutingTransitNumber;
        this.propertyInfo[0]["fii_AccountNumber"] = this.state.fii_AccountNumber;

        // HOUSING AUTHORITIES CORRESPONDENCE INFORMATION
        this.propertyInfo[0]["haci_Name"] = this.state.haci_Name;
        this.propertyInfo[0]["haci_InCareOf"] = this.state.haci_InCareOf;
        this.propertyInfo[0]["haci_Unit"] = this.state.haci_Unit;
        this.propertyInfo[0]["haci_Address"] = this.state.haci_Address;
        this.propertyInfo[0]["haci_Phone"] = (this.state.haci_Phone === null || this.state.haci_Phone === undefined) ? '' : this.state.haci_Phone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
        this.propertyInfo[0]["haci_PhoneExtension"] = this.state.haci_PhoneExtension;
        this.propertyInfo[0]["haci_Fax"] = this.state.haci_Fax;
        this.propertyInfo[0]["haci_Email"] = this.state.haci_Email;

        // REQUESTER INFORMATION
        this.propertyInfo[0]["ri_Name"] = this.state.ri_Name;
        this.propertyInfo[0]["ri_Unit"] = this.state.ri_Unit;
        this.propertyInfo[0]["ri_Address"] = this.state.ri_Address;
        this.propertyInfo[0]["ri_City"] = this.state.ri_City;
        this.propertyInfo[0]["ri_State"] = this.state.ri_State;
        this.propertyInfo[0]["ri_Zip"] = this.state.ri_Zip;

        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/updatepropertyregistrationdetails';
        let myInit = {
            body: this.propertyInfo[0],
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    dummyRequest = (url) => {
        return new Promise((resolve, reject) => {
            window.setTimeout(
                () => {
                    console.log('dummy work completed');
                    resolve();
                },
                2000
            );
        });
    }

    saveForm = async () => {
        if (this.allControlsAreValid() === true) {
            this.setState({ isLoading: true });

            var res = await this.updatePropertyRegistrationDetails();
            //console.log('Updated Property Registration Details');
            //console.log(res);

            if (this.state.mci_Name !== '') {
                res = await this.updatePropertyManagerDetails();
                //console.log('Updated Property Manager Details');
                //console.log(res);
            }

            this.setState({ isLoading: false });
            this.setState({ openSnackbar: true, snackbarMessage: "The updates are been saved",snackbarMessageClass: 'Mui-filledSuccess', hideDuration: 3000 });
        }
    }

    allControlsAreValid = () => {
        var validationError = false;

        ///////////////////////////   VALIDATION STARTS   ///////////////////////////
        // REQUESTER INFORMATION
        if (this.state.ri_Name === "" || this.state.ri_Name === null || this.state.ri_Name === undefined) {
            this.setState({ ri_Name_Error: "Name is required" });
            this.riNameRef.current.focus();

            validationError = true;
        }
        else {
            var namePattern = new RegExp(/^[a-zA-Z- ]*$/);
            if (!namePattern.test(this.state.ri_Name)) {
                this.setState({ ri_Name_Error: "Invalid Name" });
                this.riNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ ri_Name_Error: "" });
            }
        }
        if (this.state.ri_Address === "" || this.state.ri_Address === null || this.state.ri_Address === undefined) {
            this.setState({ ri_Address_Error: "Address is required" });
            this.riAddressRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ ri_Address_Error: "" });
        }
        if (this.state.ri_City === "" || this.state.ri_City === null || this.state.ri_City === undefined) {
            this.setState({ ri_City_Error: "City is required" });
            this.riCityRef.current.focus();

            validationError = true;
        }
        else {
            var patternCity = new RegExp(/^[a-zA-Z- ]*$/);
            if (!patternCity.test(this.state.ri_City)) {
                this.setState({ ri_City_Error: "Invalid City" });
                this.riCityRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ ri_City_Error: "" });
            }
        }
        // if (this.state.ri_State === "" || this.state.ri_State === null || this.state.ri_State === undefined) {
        //     this.riStateRef.current.focus();

        //     validationError = true;
        // }
        if (this.ri_StateSelector.value === "" || this.ri_StateSelector.value === "selectstate" || this.ri_StateSelector.value === null || this.ri_StateSelector.value === undefined) {
            this.setState({ ri_State_Error: "State is required" });
            this.riCityRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ ri_State_Error: "" });
        }
        if (this.state.ri_Zip === "" || this.state.ri_Zip === null || this.state.ri_Zip === undefined) {
            this.setState({ ri_Zip_Error: "Zip is required" });
            this.riZipRef.current.focus();

            validationError = true;
        }
        else if (this.state.ri_Zip.length < 5 || /^\d+$/.test(this.state.ri_Zip) !== true) {
            this.setState({ ri_Zip_Error: "Invalid Zip" });
            this.riZipRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ ri_Zip_Error: "" });
        }

        // HOUSING AUTHORITIES CORRESPONDENCE INFORMATION
        if (this.state.haci_Name === "" || this.state.haci_Name === null || this.state.haci_Name === undefined) {
            this.setState({ haci_Name_Error: "Name is required" });
            this.haciNameRef.current.focus();

            validationError = true;
        }
        else {
            namePattern = new RegExp(/^[a-zA-Z- ]*$/);
            if (!namePattern.test(this.state.haci_Name)) {
                this.setState({ haci_Name_Error: "Invalid Name" });
                this.haciNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ haci_Name_Error: "" });
            }
        }
        if (this.state.haci_InCareOf === "" || this.state.haci_InCareOf === null || this.state.haci_InCareOf === undefined) {
            this.setState({ haci_InCareOf_Error: "In Care of is required" });
            this.haciInCareOfRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ haci_InCareOf_Error: "" });
        }
        if (this.state.haci_Address === "" || this.state.haci_Address === null || this.state.haci_Address === undefined) {
            this.setState({ haci_Address_Error: "Address is required" });
            this.haciAddressRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ haci_Address_Error: "" });
        }
        if (this.state.haci_Phone === "" || this.state.haci_Phone === null || this.state.haci_Phone === undefined) {
            this.setState({ haci_Phone_Error: "Phone is required" });
            this.haciPhoneRef.current.focus();

            validationError = true;
        }
        else if (!this.state.haci_Phone.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
            this.setState({ haci_Phone_Error: "Invalid Phone" });
            this.haciPhoneRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ haci_Phone_Error: "" });
        }
        if (this.state.haci_Fax !== "") {
            if (/^\d+$/.test(this.state.haci_Fax) !== true) {
                this.setState({ haci_Fax_Error: "Invalid Fax" });
                this.haciFaxRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ haci_Fax_Error: "" });
            }
        }
        if (this.state.haci_Email === "" || this.state.haci_Email === null || this.state.haci_Email === undefined) {
            this.setState({ haci_Email_Error: "Email is required" });
            this.haciEmailRef.current.focus();

            validationError = true;
        }
        else {
            var emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!emailPattern.test(this.state.haci_Email)) {
                this.setState({ haci_Email_Error: "Invalid Email" });
                this.haciEmailRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ haci_Email_Error: "" });
            }
        }


        // FINANCIAL INSTITUTION INFORMATION
        if (this.state.fii_Name === "" || this.state.fii_Name === "" || this.state.fii_Name === undefined) {
            this.setState({ fii_Name_Error: "Name is required" });
            this.fiiNameRef.current.focus();

            validationError = true;
        }
        else {
            namePattern = new RegExp(/^[a-zA-Z- ]*$/);
            if (!namePattern.test(this.state.fii_Name)) {
                this.setState({ fii_Name_Error: "Invalid Name" });
                this.fiiNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ fii_Name_Error: "" });
            }
        }
        if (this.state.fii_AccountType === "" || this.state.fii_AccountType === "" || this.state.fii_AccountType === undefined) {
            //if (this.state.selectedAccountType === 0) {
            this.setState({ fii_AccountType_Error: "Account Type is required" });
            this.fiiAccountTypeRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ fii_AccountType_Error: "" });
        }
        if (this.state.fii_Address === "" || this.state.fii_Address === "" || this.state.fii_Address === undefined) {
            this.setState({ fii_Address_Error: "Address is invalid" });
            this.fiiAddressRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ fii_Address_Error: "" });
        }
        if (this.state.fii_City === "" || this.state.fii_City === "" || this.state.fii_City === undefined) {
            this.setState({ fii_City_Error: "City is required" });
            this.fiiCityRef.current.focus();

            validationError = true;
        }
        else {
            patternCity = new RegExp(/^[a-zA-Z- ]*$/);
            if (!patternCity.test(this.state.fii_City)) {
                this.setState({ fii_City_Error: "Invalid City" });
                this.fiiCityRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ fii_City_Error: "" });
            }
        }
        // if (this.state.fii_State === "" || this.state.fii_State === "" || this.state.fii_State === undefined) {
        //     this.fiiStateRef.current.focus();

        //     validationError = true;
        // }
        if (this.fii_StateSelector.value === "" || this.fii_StateSelector.value === "selectstate" || this.fii_StateSelector.value === "" || this.fii_StateSelector.value === undefined) {
            this.setState({ fii_State_Error: "State is required" });
            //this.fiiStateRef.current.focus();
            this.fiiCityRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ fii_State_Error: "" });
        }
        if (this.state.fii_Zip === "" || this.state.fii_Zip === "" || this.state.fii_Zip === undefined) {
            this.setState({ fii_Zip_Error: "Zip is required" });
            this.fiiZipRef.current.focus();

            validationError = true;
        }
        else if (this.state.fii_Zip.length < 5 || /^\d+$/.test(this.state.fii_Zip) !== true) {
            this.setState({ fii_Zip_Error: "Invalid Zip" });
            this.fiiZipRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ fii_Zip_Error: "" });
        }
        if (this.state.fii_RoutingTransitNumber === "" || this.state.fii_RoutingTransitNumber === "" || this.state.fii_RoutingTransitNumber === undefined) {
            this.setState({ fii_RoutingTransitNumber_Error: "Routing Transit Number is required" });
            this.fiiRoutingTransitNumberRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ fii_RoutingTransitNumber_Error: "" });
        }
        if (this.state.fii_AccountNumber === "" || this.state.fii_AccountNumber === "" || this.state.fii_AccountNumber === undefined) {
            this.setState({ fii_AccountNumber_Error: "Account Number is required" });
            this.fiiAccountNumberRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ fii_AccountNumber_Error: "" });
        }


        // // MANAGEMENT COMPANY INFORMATION
        // if (this.state.mci_Name === "" || this.state.mci_Name === null || this.state.mci_Name === undefined) {
        //     document.getElementById("managementCompanyInfoID").innerText = "Please select all the mandetory fields in this section";
        //     document.getElementById("managementCompanyInfoID").style.display = "block";
        //     this.mciNameRef.current.focus();

        //     validationError = true;
        // }
        // else {
        //     namePattern = new RegExp(/^[a-zA-Z- ]*$/);
        //     if (!namePattern.test(this.state.mci_Name)) {
        //         document.getElementById("managementCompanyInfoID").innerText = "Special characters or digits are not allowed in a name";
        //         document.getElementById("managementCompanyInfoID").style.display = "block";
        //         this.mciNameRef.current.focus();

        //         validationError = true;
        //     }
        // }
        // if (this.state.mci_Phone === "" || this.state.mci_Phone === null || this.state.mci_Phone === undefined) {
        //     document.getElementById("managementCompanyInfoID").innerText = "Please select all the mandetory fields in this section";
        //     document.getElementById("managementCompanyInfoID").style.display = "block";
        //     this.mciPhoneRef.current.focus();

        //     validationError = true;
        // }
        // else if (!this.state.mci_Phone.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
        //     document.getElementById("managementCompanyInfoID").innerText = "Invalid phone number";
        //     document.getElementById("managementCompanyInfoID").style.display = "block";
        //     this.mciPhoneRef.current.focus();

        //     validationError = true;
        // }
        // // if (this.state.mci_Fax === "" || this.state.mci_Fax === null || this.state.mci_Fax === undefined) {
        // //     document.getElementById("managementCompanyInfoID").innerText = "Please select all the mandetory fields in this section";
        // //     document.getElementById("managementCompanyInfoID").style.display = "block";
        // //     this.mciFaxRef.current.focus();

        // //     validationError = true;
        // // }
        // // else if(this.state.mci_Fax.length < 10 || /^\d+$/.test(this.state.mci_Fax) !== true){
        // //     document.getElementById("managementCompanyInfoID").innerText = "Invalid fax number";
        // //     document.getElementById("managementCompanyInfoID").style.display = "block";
        // //     this.mciFaxRef.current.focus();

        // //     validationError = true;
        // // }
        // if (this.state.mci_Email === "" || this.state.mci_Email === null || this.state.mci_Email === undefined) {
        //     document.getElementById("managementCompanyInfoID").innerText = "Please select all the mandetory fields in this section";
        //     document.getElementById("managementCompanyInfoID").style.display = "block";
        //     this.mciEmailRef.current.focus();

        //     validationError = true;
        // }
        // else {
        //     emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        //     if (!emailPattern.test(this.state.mci_Email)) {
        //         document.getElementById("managementCompanyInfoID").innerText = "Invalid email";
        //         document.getElementById("managementCompanyInfoID").style.display = "block";
        //         this.mciEmailRef.current.focus();

        //         validationError = true;
        //     }
        // }
        // if (this.state.mci_Address === "" || this.state.mci_Address === null || this.state.mci_Address === undefined) {
        //     document.getElementById("managementCompanyInfoID").innerText = "Please select all the mandetory fields in this section";
        //     document.getElementById("managementCompanyInfoID").style.display = "block";
        //     this.mciAddressRef.current.focus();

        //     validationError = true;
        // }
        // if (this.state.mci_City === "" || this.state.mci_City === null || this.state.mci_City === undefined) {
        //     document.getElementById("managementCompanyInfoID").innerText = "Please select all the mandetory fields in this section";
        //     document.getElementById("managementCompanyInfoID").style.display = "block";
        //     this.mciCityRef.current.focus();

        //     validationError = true;
        // }
        // else {
        //     patternCity = new RegExp(/^[a-zA-Z- ]*$/);
        //     if (!patternCity.test(this.state.mci_City)) {
        //         document.getElementById("managementCompanyInfoID").innerText = "Special characters or digits in city are not allowed";
        //         document.getElementById("managementCompanyInfoID").style.display = "block";
        //         this.mciCityRef.current.focus();

        //         validationError = true;
        //     }
        // }
        // // if (this.state.mci_State === "" || this.state.mci_State === null || this.state.mci_State === undefined) {
        // //     document.getElementById("managementCompanyInfoID").innerText = "Please select all the mandetory fields in this section";
        // //     document.getElementById("managementCompanyInfoID").style.display = "block";
        // //     this.mciStateRef.current.focus();

        // //     validationError = true;
        // // }
        // if (this.mci_StateSelector.value === "" || this.mci_StateSelector.value === "selectstate" || this.mci_StateSelector.value === null || this.mci_StateSelector.value === undefined) {
        //     document.getElementById("managementCompanyInfoID").innerText = "Please select all the mandetory fields in this section";
        //     document.getElementById("managementCompanyInfoID").style.display = "block";
        //     //this.mciStateRef.current.focus();
        //     this.mciCityRef.current.focus();

        //     validationError = true;
        // }
        // if (this.state.mci_Zip === "" || this.state.mci_Zip === null || this.state.mci_Zip === undefined) {
        //     document.getElementById("managementCompanyInfoID").innerText = "Please select all the mandetory fields in this section";
        //     document.getElementById("managementCompanyInfoID").style.display = "block";
        //     this.mciZipRef.current.focus();

        //     validationError = true;
        // }
        // else if (this.state.mci_Zip.length < 5 || /^\d+$/.test(this.state.mci_Zip) !== true) {
        //     document.getElementById("managementCompanyInfoID").innerHTML = "Invalid zip code";
        //     document.getElementById("managementCompanyInfoID").style.display = "block";
        //     this.mciZipRef.current.focus();

        //     validationError = true;
        // }
        if (this.state.mci_Email !== "") {
            var emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!emailPattern.test(this.state.mci_Email)) {
                this.setState({ mci_Email_Error: "Invalid Email" });
                this.mciEmailRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ mci_Email_Error: "" });
            }
        }
        if (this.state.mci_Zip !== "") {
            if (/^\d+$/.test(this.state.mci_Zip) !== true) {
                this.setState({ mci_Zip_Error: "Invalid Zip" });
                this.mciZipRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ mci_Zip_Error: "" });
            }
        }
        if (this.state.mci_Fax !== "") {
            if (/^\d+$/.test(this.state.mci_Fax) !== true) {
                this.setState({ mci_Fax_Error: "Invalid Fax" });
                this.mciFaxRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ mci_Fax_Error: "" });
            }
        }

        // TAX PAYER INFORMATION
        if (this.state.tpi_Name === "" || this.state.tpi_Name === null || this.state.tpi_Name === undefined) {
            this.setState({ tpi_Name_Error: "Name is required" });
            this.tpiNameRef.current.focus();

            validationError = true;
        }
        else {
            namePattern = new RegExp(/^[a-zA-Z- ]*$/);
            if (!namePattern.test(this.state.tpi_Name)) {
                this.setState({ tpi_Name_Error: "Invalid Name" });
                this.tpiNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ tpi_Name_Error: "" });
            }
        }
        // if (this.state.tpi_BusinessName === "" || this.state.tpi_BusinessName === null || this.state.tpi_BusinessName === undefined) {
        //     this.setState({ tpi_BusinessName_Error: "Business Name is required" });
        //     this.tpiBusinessNameRef.current.focus();

        //     validationError = true;
        // }
        // else {
        //     namePattern = new RegExp(/^[a-zA-Z- ]*$/);
        //     if (!namePattern.test(this.state.tpi_BusinessName)) {
        //         this.setState({ tpi_BusinessName_Error: "Invalid Business Name" });
        //         this.tpiBusinessNameRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ tpi_BusinessName_Error: "" });
        //     }
        // }

        if (this.props.w9Uploaded === false) {
            if (this.state.tpi_FederalTaxClassification === "" || this.state.tpi_FederalTaxClassification === null || this.state.tpi_FederalTaxClassification === undefined) {
                // if (this.state.selectedFederalTaxClassification === 0) {
                this.setState({ tpi_FederalTaxClassification_Error: "Federal Tax Classification is required" });
                this.tpiFederalTaxClassificationRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ tpi_FederalTaxClassification_Error: "" });
            }
            if (this.state.tpi_FederalTaxClassification === "LLC") {
                if (this.tpi_LLCClassificationSelector.value === "" || this.tpi_LLCClassificationSelector.value === "selectllc" || this.tpi_LLCClassificationSelector.value === null || this.tpi_LLCClassificationSelector.value === undefined) {
                    this.setState({ tpi_LLCClassification_Error: "LLC classification is required" });
                    this.tpiFederalTaxClassificationRef.current.focus();
                    //this.tpiStateRef.current.focus();

                    validationError = true;
                }
                else {
                    this.setState({ tpi_LLCClassification_Error: "" });
                }

                // if (this.state.tpi_LLCClassification === "" || this.state.tpi_LLCClassification === null || this.state.tpi_LLCClassification === undefined) {
                //     //if (this.state.selectedLLCClassification === 0) {
                //     this.setState({ tpi_LLCClassification_Error: "LLC classification is required" });
                //     this.tpiLLCClassificationRef.current.focus();

                //     validationError = true;
                // }
                // else {
                //     this.setState({ tpi_LLCClassification_Error: "" });
                // }
            }
            if (this.state.tpi_FederalTaxClassification === "Other" &&
                (this.state.tpi_OtherClassification === "" || this.state.tpi_OtherClassification === null || this.state.tpi_OtherClassification === undefined)) {
                this.setState({ tpi_OtherClassification_Error: "Other classification is required" });
                this.tpiOtherClassificationRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ tpi_OtherClassification_Error: "" });
            }
            // if (this.state.tpi_ExemptPayeeCode === "" || this.state.tpi_ExemptPayeeCode === null || this.state.tpi_ExemptPayeeCode === undefined) {
            //     this.setState({ tpi_ExemptPayeeCode_Error: "Exempt payee code is required" });
            //     this.tpiExemptPayeeCodeRef.current.focus();

            //     validationError = true;
            // }
            // else {
            //     this.setState({ tpi_ExemptPayeeCode_Error: "" });
            // }
            // if (this.state.tpi_FATCAReportingCode === "" || this.state.tpi_FATCAReportingCode === null || this.state.tpi_FATCAReportingCode === undefined) {
            //     this.setState({ tpi_FATCAReportingCode_Error: "Exemption from FATCA reporting code is required" });
            //     this.tpiFATCAReportingCodeRef.current.focus();

            //     validationError = true;
            // }
            // else {
            //     this.setState({ tpi_FATCAReportingCode_Error: "" });
            // }
        }

        if (this.state.tpi_Address === "" || this.state.tpi_Address === null || this.state.tpi_Address === undefined) {
            this.setState({ tpi_Address_Error: "Address is required" });
            this.tpiAddressRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ tpi_Address_Error: "" });
        }
        if (this.state.tpi_City === "" || this.state.tpi_City === null || this.state.tpi_City === undefined) {
            this.setState({ tpi_City_Error: "City is required" });
            this.tpiCityRef.current.focus();

            validationError = true;
        }
        else {
            patternCity = new RegExp(/^[a-zA-Z- ]*$/);
            if (!patternCity.test(this.state.tpi_City)) {
                this.setState({ tpi_City_Error: "Invalid City" });
                this.tpiCityRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ tpi_City_Error: "" });
            }
        }
        // if (this.state.tpi_State === "" || this.state.tpi_State === null || this.state.tpi_State === undefined) {
        //     this.tpiStateRef.current.focus();

        //     validationError = true;
        // }
        if (this.tpi_StateSelector.value === "" || this.tpi_StateSelector.value === "selectstate" || this.tpi_StateSelector.value === null || this.tpi_StateSelector.value === undefined) {
            this.setState({ tpi_State_Error: "State is required" });
            this.tpiCityRef.current.focus();
            //this.tpiStateRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ tpi_State_Error: "" });
        }
        if (this.state.tpi_Zip === "" || this.state.tpi_Zip === null || this.state.tpi_Zip === undefined) {
            this.setState({ tpi_Zip_Error: "Zip is required" });
            this.tpiZipRef.current.focus();

            validationError = true;
        }
        else if (this.state.tpi_Zip.length < 5 || /^\d+$/.test(this.state.tpi_Zip) !== true) {
            this.setState({ tpi_Zip_Error: "Invalid Zip" });
            this.tpiZipRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ tpi_Zip_Error: "" });
        }
        // if (this.state.tpi_AccountNumbers === "" || this.state.tpi_AccountNumbers === null || this.state.tpi_AccountNumbers === undefined) {
        //     this.setState({ tpi_AccountNumbers_Error: "Account Numbers is required" });
        //     this.tpiAccountNumbersRef.current.focus();

        //     validationError = true;
        // }
        // else {
        //     this.setState({ tpi_AccountNumbers_Error: "" });
        // }
        if ((this.state.tpi_SSN === "" || this.state.tpi_SSN === null || this.state.tpi_SSN === undefined) &&
            (this.state.tpi_EID === "" || this.state.tpi_EID === null || this.state.tpi_EID === undefined)) {
            this.setState({ tpi_SSN_Error: "SSN or Employer Identification Number is required" });
            this.tpiSSNRef.current.focus();

            validationError = true;
        }
        else {
            if (this.state.tpi_SSN !== "") { //|| this.state.poi_SSN !== null || this.state.poi_SSN !== undefined
                if (/^\d+$/.test(this.state.tpi_SSN) !== true) {
                    this.setState({ tpi_SSN_Error: "Invalid SSN" });
                    this.tpiSSNRef.current.focus();

                    validationError = true;
                }
                else {
                    this.setState({ tpi_SSN_Error: "" });
                }
            }
            if (this.state.tpi_EID !== "") { //|| this.state.poi_EID !== null || this.state.poi_EID !== undefined
                if (/^\d+$/.test(this.state.tpi_EID) !== true) {
                    this.setState({ tpi_EID_Error: "Invalid EIN" });
                    this.tpiEIDRef.current.focus();

                    validationError = true;
                }
                else {
                    this.setState({ tpi_EID_Error: "" });
                    // this.setState({ poi_SSN_Error: "" });
                }
            }
            // else {
            //     this.setState({ tpi_SSN_Error: "" });
            //     this.setState({ tpi_EID_Error: "" });
            // }
        }

        // PAYEE INFORMATION
        if (this.state.pyei_Name === "" || this.state.pyei_Name === null || this.state.pyei_Name === undefined) {
            this.setState({ pyei_Name_Error: "Name is required" });
            this.pyeiNameRef.current.focus();

            validationError = true;
        }
        else {
            namePattern = new RegExp(/^[a-zA-Z- ]*$/);
            if (!namePattern.test(this.state.pyei_Name)) {
                this.setState({ pyei_Name_Error: "Invalid Name" });
                this.pyeiNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ pyei_Name_Error: "" });
            }
        }
        // if (this.state.pyei_TaxID === "" || this.state.pyei_TaxID === null || this.state.pyei_TaxID === undefined) {
        //     this.pyeiTaxIDRef.current.focus();

        //     validationError = true;
        // }
        if (this.state.pyei_Phone === "" || this.state.pyei_Phone === null || this.state.pyei_Phone === undefined) {
            this.setState({ pyei_Phone_Error: "Phone is required" });
            this.pyeiPhoneRef.current.focus();

            validationError = true;
        }
        else if (!this.state.pyei_Phone.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
            this.setState({ pyei_Phone_Error: "Invalid Phone" });
            this.pyeiPhoneRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ pyei_Phone_Error: "" });
        }
        if (this.state.pyei_Address === "" || this.state.pyei_Address === null || this.state.pyei_Address === undefined) {
            this.setState({ pyei_Address_Error: "Address is required" });
            this.pyeiAddressRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ pyei_Address_Error: "" });
        }
        if (this.state.pyei_City === "" || this.state.pyei_City === null || this.state.pyei_City === undefined) {
            this.setState({ pyei_City_Error: "City is required" });
            this.pyeiCityRef.current.focus();

            validationError = true;
        }
        else {
            patternCity = new RegExp(/^[a-zA-Z- ]*$/);
            if (!patternCity.test(this.state.pyei_City)) {
                this.setState({ pyei_City_Error: "Invalid City" });
                this.pyeiCityRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ pyei_City_Error: "" });
            }
        }
        // if (this.state.pyei_State === "" || this.state.pyei_State === null || this.state.pyei_State === undefined) {
        //     this.pyeiStateRef.current.focus();

        //     validationError = true;
        // }
        if (this.pyei_StateSelector.value === "" || this.pyei_StateSelector.value === "selectstate" || this.pyei_StateSelector.value === null || this.pyei_StateSelector.value === undefined) {
            this.setState({ pyei_State_Error: "State is required" });
            this.pyeiCityRef.current.focus();
            //this.pyeiStateRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ pyei_State_Error: "" });
        }
        if (this.state.pyei_Zip === "" || this.state.pyei_Zip === null || this.state.pyei_Zip === undefined) {
            this.setState({ pyei_Zip_Error: "Zip is required" });
            this.pyeiZipRef.current.focus();

            validationError = true;
        }
        else if (this.state.pyei_Zip.length < 5 || /^\d+$/.test(this.state.pyei_Zip) !== true) {
            this.setState({ pyei_Zip_Error: "Invalid Zip" });
            this.pyeiZipRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ pyei_Zip_Error: "" });
        }
        if (this.state.pyei_Email === "" || this.state.pyei_Email === null || this.state.pyei_Email === undefined) {
            this.setState({ pyei_Email_Error: "Email is required" });
            this.pyeiEmailRef.current.focus();

            validationError = true;
        }
        else {
            emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!emailPattern.test(this.state.pyei_Email)) {
                this.setState({ pyei_Email_Error: "Invalid Email" });
                this.pyeiEmailRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ pyei_Email_Error: "" });
            }
        }
        if ((this.state.pyei_SSN === "" || this.state.pyei_SSN === null || this.state.pyei_SSN === undefined) &&
            (this.state.pyei_EID === "" || this.state.pyei_EID === null || this.state.pyei_EID === undefined)) {
            this.setState({ pyei_SSN_Error: "SSN or Employer Identification Number is required" });
            this.pyeiSSNRef.current.focus();

            validationError = true;
        }
        else {
            if (this.state.pyei_SSN !== "") { //|| this.state.poi_SSN !== null || this.state.poi_SSN !== undefined
                if (/^\d+$/.test(this.state.pyei_SSN) !== true) {
                    this.setState({ pyei_SSN_Error: "Invalid SSN" });
                    this.pyeiSSNRef.current.focus();

                    validationError = true;
                }
                else {
                    this.setState({ pyei_SSN_Error: "" });
                }
            }
            if (this.state.pyei_EID !== "") { //|| this.state.poi_EID !== null || this.state.poi_EID !== undefined
                if (/^\d+$/.test(this.state.pyei_EID) !== true) {
                    this.setState({ pyei_EID_Error: "Invalid EIN" });
                    this.pyeiEIDRef.current.focus();

                    validationError = true;
                }
                else {
                    this.setState({ pyei_EID_Error: "" });
                    // this.setState({ poi_SSN_Error: "" });
                }
            }
            // else {
            //     this.setState({ pyei_SSN_Error: "" });
            //     this.setState({ pyei_EID_Error: "" });
            // }
        }



        // // PROPERTY OWNER INFORMATION
        // if (this.state.poi_Name === "" || this.state.poi_Name === null || this.state.poi_Name === undefined) {
        //     this.setState({ poi_Name_Error: "Name is required" });
        //     this.poiNameRef.current.focus();

        //     validationError = true;
        // }
        // else {
        //     namePattern = new RegExp(/^[a-zA-Z- ]*$/);
        //     if (!namePattern.test(this.state.poi_Name)) {
        //         this.setState({ poi_Name_Error: "Invalid Name" });
        //         this.poiNameRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ poi_Name_Error: "" });
        //     }
        // }
        if (this.props.w9Uploaded === false) {
            if (this.state.poi_BusinessName === "" || this.state.poi_BusinessName === null || this.state.poi_BusinessName === undefined) {
                this.setState({ poi_BusinessName_Error: "Business Name is required" });
                this.poiBusinessNameRef.current.focus();

                validationError = true;
            }
            else {
                namePattern = new RegExp(/^[a-zA-Z- ]*$/);
                if (!namePattern.test(this.state.poi_BusinessName)) {
                    this.setState({ poi_BusinessName_Error: "Invalid Business Name" });
                    this.poiBusinessNameRef.current.focus();

                    validationError = true;
                }
                else {
                    this.setState({ poi_BusinessName_Error: "" });
                }
            }
        }
        // if (this.state.poi_Phone === "" || this.state.poi_Phone === null || this.state.poi_Phone === undefined) {
        //     this.setState({ poi_Phone_Error: "Phone is required" });
        //     this.poiPhoneRef.current.focus();

        //     validationError = true;
        // }
        // else if (!this.state.poi_Phone.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
        //     this.setState({ poi_Phone_Error: "Invalid Phone" });
        //     this.poiPhoneRef.current.focus();

        //     validationError = true;
        // }
        // else {
        //     this.setState({ poi_Phone_Error: "" });
        // }
        if (this.state.poi_Fax !== "") { //|| this.state.poi_Fax !== null || this.state.poi_Fax !== undefined
            if (/^\d+$/.test(this.state.poi_Fax) !== true) {
                this.setState({ poi_Fax_Error: "Invalid Fax" });
                this.poiFaxRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ poi_Fax_Error: "" });
            }
        }
        // if (this.state.poi_City === "" || this.state.poi_City === null || this.state.poi_City === undefined) {
        //     this.setState({ poi_City_Error: "City is required" });
        //     this.poiCityRef.current.focus();

        //     validationError = true;
        // }
        // else {
        //     patternCity = new RegExp(/^[a-zA-Z- ]*$/);
        //     if (!patternCity.test(this.state.poi_City)) {
        //         this.setState({ poi_City_Error: "Invalid City" });
        //         this.poiCityRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ poi_City_Error: "" });
        //     }
        // }
        // // if (this.state.poi_State === "" || this.state.poi_State === null || this.state.poi_State === undefined) {
        // //     this.poiStateRef.current.focus();

        // //     validationError = true;
        // // }
        // if (this.poi_StateSelector.value === "" || this.poi_StateSelector.value === "selectstate" || this.poi_StateSelector.value === null || this.poi_StateSelector.value === undefined) {
        //     this.setState({ poi_State_Error: "State is required" });
        //     //this.poiStateRef.current.focus();
        //     this.poiCityRef.current.focus();

        //     validationError = true;
        // }
        // else {
        //     this.setState({ poi_State_Error: "" });
        // }
        // if (this.state.poi_Zip === "" || this.state.poi_Zip === null || this.state.poi_Zip === undefined) {
        //     this.setState({ poi_Zip_Error: "Zip is required" });
        //     this.poiZipRef.current.focus();

        //     validationError = true;
        // }
        // else if (this.state.poi_Zip.length < 5 || /^\d+$/.test(this.state.poi_Zip) !== true) {
        //     this.setState({ poi_Zip_Error: "Invalid Zip" });
        //     this.poiZipRef.current.focus();

        //     validationError = true;
        // }
        // else {
        //     this.setState({ poi_Zip_Error: "" });
        // }
        // if (this.state.poi_Email === "" || this.state.poi_Email === null || this.state.poi_Email === undefined) {
        //     this.setState({ poi_Email_Error: "Email is required" });
        //     this.poiEmailRef.current.focus();

        //     validationError = true;
        // }
        // else {
        //     emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        //     if (!emailPattern.test(this.state.poi_Email)) {
        //         this.setState({ poi_Email_Error: "Invalid Email" });
        //         this.poiEmailRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ poi_Email_Error: "" });
        //     }
        // }
        if ((this.state.poi_SSN === "" || this.state.poi_SSN === null || this.state.poi_SSN === undefined) &&
            (this.state.poi_EID === "" || this.state.poi_EID === null || this.state.poi_EID === undefined)) {
            this.setState({ poi_SSN_Error: "SSN or Employer Identification Number is required" });
            this.poiSSNRef.current.focus();

            validationError = true;
        }
        else {
            if (this.state.poi_SSN !== "") { //|| this.state.poi_SSN !== null || this.state.poi_SSN !== undefined
                if (/^\d+$/.test(this.state.poi_SSN) !== true) {
                    this.setState({ poi_SSN_Error: "Invalid SSN" });
                    this.poiSSNRef.current.focus();

                    validationError = true;
                }
                else {
                    this.setState({ poi_SSN_Error: "" });
                }
            }
            if (this.state.poi_EID !== "") { //|| this.state.poi_EID !== null || this.state.poi_EID !== undefined
                if (/^\d+$/.test(this.state.poi_EID) !== true) {
                    this.setState({ poi_EID_Error: "Invalid EIN" });
                    this.poiEIDRef.current.focus();

                    validationError = true;
                }
                else {
                    this.setState({ poi_EID_Error: "" });
                    // this.setState({ poi_SSN_Error: "" });
                }
            }
            // else {
            //     this.setState({ poi_SSN_Error: "" });
            //     this.setState({ poi_EID_Error: "" });
            // }
        }
        // if (this.state.poi_TaxID === "" || this.state.poi_TaxID === null || this.state.poi_TaxID === undefined) {
        //     this.poiTaxIDRef.current.focus();

        //     validationError = true;
        // }

        // // PROPERTY INFORMATION
        // if (this.state.pi_Name === "" || this.state.pi_Name === null || this.state.pi_Name === undefined) {
        //     this.setState({ pi_Name_Error: "Name is required" });
        //     this.piNameRef.current.focus();

        //     validationError = true;
        // }
        // else {
        //     namePattern = new RegExp(/^[a-zA-Z- ]*$/);
        //     if (!namePattern.test(this.state.pi_Name)) {
        //         this.setState({ pi_Name_Error: "Invalid Name" });
        //         this.piNameRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ pi_Name_Error: "" });
        //     }
        // }
        // if (this.state.pi_Address === "" || this.state.pi_Address === null || this.state.pi_Address === undefined) {
        //     this.setState({ pi_Address_Error: "Address is required" });
        //     this.piAddressRef.current.focus();

        //     validationError = true;
        // }
        // else {
        //     this.setState({ pi_Address_Error: "" });
        // }
        // if (this.state.pi_City === "" || this.state.pi_City === null || this.state.pi_City === undefined) {
        //     this.setState({ pi_City_Error: "City is required" });
        //     this.piCityRef.current.focus();

        //     validationError = true;
        // }
        // else {
        //     patternCity = new RegExp(/^[a-zA-Z- ]*$/);
        //     if (!patternCity.test(this.state.pi_City)) {
        //         this.setState({ pi_City_Error: "Invalid City" });
        //         this.piCityRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ pi_City_Error: "" });
        //     }
        // }
        // // if (this.state.pi_State === "" || this.state.pi_State === null || this.state.pi_State === undefined) {
        // //     this.piStateRef.current.focus();

        // //     validationError = true;
        // // }
        // if (this.pi_StateSelector.value === "" || this.pi_StateSelector.value === "selectstate" || this.pi_StateSelector.value === null || this.pi_StateSelector.value === undefined) {
        //     this.setState({ pi_State_Error: "State is required" });
        //     //this.piStateRef.current.focus();
        //     this.piCityRef.current.focus();

        //     validationError = true;
        // }
        // else {
        //     this.setState({ pi_State_Error: "" });
        // }
        // if (this.state.pi_Zip === "" || this.state.pi_Zip === null || this.state.pi_Zip === undefined) {
        //     this.setState({ pi_Zip_Error: "Zip is required" });
        //     this.piZipRef.current.focus();

        //     validationError = true;
        // }
        // else if (this.state.pi_Zip.length < 5 || /^\d+$/.test(this.state.pi_Zip) !== true) {
        //     this.setState({ pi_Zip_Error: "Invalid Zip" });
        //     this.piZipRef.current.focus();

        //     validationError = true;
        // }
        // else {
        //     this.setState({ pi_Zip_Error: "" });
        // }
        // if (this.state.pi_Email === "" || this.state.pi_Email === null || this.state.pi_Email === undefined) {
        //     this.setState({ pi_Email_Error: "Email is required" });
        //     this.piEmailRef.current.focus();

        //     validationError = true;
        // }
        // else {
        //     emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        //     if (!emailPattern.test(this.state.pi_Email)) {
        //         this.setState({ pi_Email_Error: "Invalid Email" });
        //         this.piEmailRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ pi_Email_Error: "" });
        //     }
        // }
        // if (this.state.pi_Phone === "" || this.state.pi_Phone === null || this.state.pi_Phone === undefined) {
        //     this.setState({ pi_Phone_Error: "Phone is required" });
        //     this.piPhoneRef.current.focus();

        //     validationError = true;
        // }
        // else if (!this.state.pi_Phone.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
        //     this.setState({ pi_Phone_Error: "Invalid Phone" });
        //     this.piPhoneRef.current.focus();

        //     validationError = true;
        // }
        // else {
        //     this.setState({ pi_Phone_Error: "" });
        // }
        if (this.state.pi_Fax !== "") { //|| this.state.pi_Fax !== null || this.state.pi_Fax !== undefined
            if (/^\d+$/.test(this.state.pi_Fax) !== true) {
                this.setState({ pi_Fax_Error: "Invalid Fax" });
                this.piFaxRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ pi_Fax_Error: "" });
            }
        }

        // TRANSACTION INFORMATION
        if (this.state.selectedTransaction !== 1) {
            this.setState({ transaction_Error: "Transaction is required" });
            this.TransactionRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ transaction_Error: "" });
        }

        if (this.state.tpi_TenantName === "" || this.state.tpi_TenantName === null || this.state.tpi_TenantName === undefined) {
            this.setState({ tpi_TenantName_Error: "Tenant Name is required" });
            this.piTenantNameRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ tpi_TenantName_Error: "" });
        }

        ///////////////////////////   VALIDATION ENDS   ///////////////////////////

        if (validationError === true) {
            this.setState({ isLoading: false });
            return false;
        }

        return true;
    }

    validateForm = async () => {
        if (this.allControlsAreValid() === true) {
            this.setState({ isLoading: true });

            //var res = await this.dummyRequest('');
            var res = await this.updatePropertyRegistrationDetails();
            //console.log('Updated Property Registration Details');
            //console.log(res);

            if (this.state.mci_Name !== '') {
                res = await this.updatePropertyManagerDetails();
                //console.log('Updated Property Manager Details');
                //console.log(res);
            }

            this.setState({ isLoading: false });

            return true;
        }
        return false;
    }

    updateTenantName = (evt) => {
        this.setState({ tpi_TenantName: evt.target.value, tpi_TenantName_Error: "" });
    }

    updateNewDirectDepositRequest = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ newDirectDepositRequest: 1, tpi_Transaction: "NewDirectDepositRequest" });
        }
        else {
            this.setState({ newDirectDepositRequest: 0, tpi_Transaction: "ChangeInformation" });
        }
        this.setState({ selectedTransaction: 1, tpi_Transaction_Error: "" });
    }

    updateChangeInformation = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ changeInformation: 1, tpi_Transaction: "ChangeInformation" });
        }
        else {
            this.setState({ changeInformation: 0, tpi_Transaction: "NewDirectDepositRequest" });
        }
        this.setState({ selectedTransaction: 1, tpi_Transaction_Error: "" });
    }


    // PROPERTY INFORMATION
    updatePIName = (evt) => {
        this.setState({ pi_Name: evt.target.value, pi_Name_Error: "" });
    }
    updatePIUnit = (evt) => {
        this.setState({ pi_Unit: evt.target.value });
    }
    updatePIAddress = (evt) => {
        this.setState({ pi_Address: evt.target.value, pi_Address_Error: "" });
    }
    updatePICity = (evt) => {
        this.setState({ pi_City: evt.target.value, pi_City_Error: "" });
    }
    updatePIState = (evt) => {
        this.setState({ pi_State: evt.target.value, pi_State_Error: "" });
    }
    updatePIZip = (evt) => {
        this.setState({ pi_Zip: evt.target.value, pi_Zip_Error: "" });
    }
    updatePIEmail = (evt) => {
        this.setState({ pi_Email: evt.target.value, pi_Email_Error: "" });
    }
    updatePIPhone = (evt) => {
        this.setState({ pi_Phone: evt.target.value, pi_Phone_Error: "" });
    }
    updatePIPhoneExtension = (evt) => {
        this.setState({ pi_PhoneExtension: evt.target.value });
    }
    updatePIFax = (evt) => {
        this.setState({ pi_Fax: evt.target.value, pi_Fax_Error: "" });
    }

    // PROPERTY OWNER INFORMATION
    updatePOIName = (evt) => {
        this.setState({ poi_Name: evt.target.value, poi_Name_Error: "" });
    }
    updatePOIBusinessName = (evt) => {
        this.setState({ poi_BusinessName: evt.target.value, poi_BusinessName_Error: "" });
    }
    updatePOIAddress = (evt) => {
        this.setState({ poi_Address: evt.target.value, poi_Address_Error: "" });
    }
    updatePOIPhone = (evt) => {
        this.setState({ poi_Phone: evt.target.value, poi_Phone_Error: "" });
    }
    updatePOIFax = (evt) => {
        this.setState({ poi_Fax: evt.target.value, poi_Fax_Error: "" });
    }
    updatePOICity = (evt) => {
        this.setState({ poi_City: evt.target.value, poi_City_Error: "" });
    }
    updatePOIState = (evt) => {
        this.setState({ poi_State: evt.target.value, poi_State_Error: "" });
    }
    updatePOIZip = (evt) => {
        this.setState({ poi_Zip: evt.target.value, poi_Zip_Error: "" });
    }
    updatePOIEmail = (evt) => {
        this.setState({ poi_Email: evt.target.value, poi_Email_Error: "" });
    }
    updatePOISSN = (evt) => {
        this.setState({ poi_SSN: evt.target.value, poi_SSN_Error: "" });
    }
    updatePOIEID = (evt) => {
        this.setState({ poi_EID: evt.target.value, poi_EID_Error: "" });
    }
    updatePOITaxID = (evt) => {
        this.setState({ poi_TaxID: evt.target.value, poi_TaxID_Error: "" });
    }

    // PAYEE INFORMATION

    updatePayeeSameAsPropertyOwnerYes = (evt) => {
        if (evt.target.checked === true) {
            this.setState({
                pyei_Name: this.state.poi_Name,
                pyei_Address: this.state.poi_Address,
                pyei_City: this.state.poi_City,
                pyei_State: this.state.poi_State,
                pyei_Zip: this.state.poi_Zip,
                pyei_SSN: this.state.poi_SSN,
                pyei_EID: this.state.poi_EID,
                pyei_Phone: (this.state.poi_Phone === null || this.state.poi_Phone === undefined) ? '' : this.state.poi_Phone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                pyei_Email: this.state.poi_Email,
            });

            document.getElementById("pyei_Name_asterisk_id").style.display = "none";
            document.getElementById("pyei_Address_asterisk_id").style.display = "none";
            document.getElementById("pyei_City_asterisk_id").style.display = "none";
            document.getElementById("pyei_State_asterisk_id").style.display = "none";
            document.getElementById("pyei_Zip_asterisk_id").style.display = "none";
            // document.getElementById("pyei_SSN_asterisk_id").style.display = "none";
            // document.getElementById("pyei_EID_asterisk_id").style.display = "none";
            document.getElementById("pyei_Phone_asterisk_id").style.display = "none";
            document.getElementById("pyei_Email_asterisk_id").style.display = "none";

            document.getElementById("pyei_Name_id").readOnly = true;
            document.getElementById("pyei_Address_id").readOnly = true;
            document.getElementById("pyei_City_id").readOnly = true;
            document.getElementById("pyei_State_id").disabled = true;
            document.getElementById("pyei_Zip_id").readOnly = true;
            // document.getElementById("pyei_SSN_id").readOnly = true;
            // document.getElementById("pyei_EID_id").readOnly = true;
            document.getElementById("pyei_Phone_id").readOnly = true;
            document.getElementById("pyei_Email_id").readOnly = true;
        }
    }

    updatePayeeSameAsPropertyOwnerNo = (evt) => {
        if (evt.target.checked === true) {
            this.setState({
                pyei_Name: "",
                pyei_Address: "",
                pyei_City: "",
                pyei_State: "",
                pyei_Zip: "",
                pyei_SSN: "",
                pyei_EID: "",
                pyei_Phone: "",
                pyei_Email: "",
            });

            document.getElementById("pyei_Name_asterisk_id").style.display = "block";
            document.getElementById("pyei_Address_asterisk_id").style.display = "block";
            document.getElementById("pyei_City_asterisk_id").style.display = "block";
            document.getElementById("pyei_State_asterisk_id").style.display = "block";
            document.getElementById("pyei_Zip_asterisk_id").style.display = "block";
            document.getElementById("pyei_SSN_asterisk_id").style.display = "block";
            document.getElementById("pyei_EID_asterisk_id").style.display = "block";
            document.getElementById("pyei_Phone_asterisk_id").style.display = "block";
            document.getElementById("pyei_Email_asterisk_id").style.display = "block";

            document.getElementById("pyei_Name_id").readOnly = false;
            document.getElementById("pyei_Address_id").readOnly = false;
            document.getElementById("pyei_City_id").readOnly = false;
            document.getElementById("pyei_State_id").disabled = false;
            document.getElementById("pyei_Zip_id").readOnly = false;
            document.getElementById("pyei_SSN_id").readOnly = false;
            document.getElementById("pyei_EID_id").readOnly = false;
            document.getElementById("pyei_Phone_id").readOnly = false;
            document.getElementById("pyei_Email_id").readOnly = false;
        }
    }

    updatePYEIName = (evt) => {
        this.setState({ pyei_Name: evt.target.value, pyei_Name_Error: "" });
    }
    updatePYEITaxID = (evt) => {
        this.setState({ pyei_TaxID: evt.target.value, pyei_TaxID_Error: "" });
    }
    updatePYEISSN = (evt) => {
        this.setState({ pyei_SSN: evt.target.value, pyei_SSN_Error: "", pyei_EID_Error: "" });
    }
    updatePYEIPhone = (evt) => {
        this.setState({ pyei_Phone: evt.target.value, pyei_Phone_Error: "" });
    }
    updatePYEIPhoneExtension = (evt) => {
        this.setState({ pyei_PhoneExtension: evt.target.value });
    }
    updatePYEIUnit = (evt) => {
        this.setState({ pyei_Unit: evt.target.value });
    }
    updatePYEIAddress = (evt) => {
        this.setState({ pyei_Address: evt.target.value, pyei_Address_Error: "" });
    }
    updatePYEICity = (evt) => {
        this.setState({ pyei_City: evt.target.value, pyei_City_Error: "" });
    }
    updatePYEIState = (evt) => {
        this.setState({ pyei_State: evt.target.value, pyei_State_Error: "" });
    }
    updatePYEIZip = (evt) => {
        this.setState({ pyei_Zip: evt.target.value, pyei_Zip_Error: "" });
    }
    updatePYEIEmail = (evt) => {
        this.setState({ pyei_Email: evt.target.value, pyei_Email_Error: "" });
    }
    updatePYEIEID = (evt) => {
        this.setState({ pyei_EID: evt.target.value, pyei_EID_Error: "", pyei_SSN_Error: "" });
    }


    // TAX PAYER INFORMATION

    updatetaxPayerSameAsPropertyOwnerYes = (evt) => {
        if (evt.target.checked === true) {
            this.setState({
                tpi_Name: this.state.poi_Name,
                tpi_BusinessName: this.state.poi_BusinessName,
                tpi_Address: this.state.poi_Address,
                tpi_City: this.state.poi_City,
                tpi_State: this.state.poi_State,
                tpi_Zip: this.state.poi_Zip,
                tpi_SSN: this.state.poi_SSN,
                tpi_EID: this.state.poi_EID,
            });

            document.getElementById("tpi_Name_asterisk_id").style.display = 'none';
            document.getElementById("tpi_BusinessName_asterisk_id").style.display = 'none';
            document.getElementById("tpi_Address_asterisk_id").style.display = 'none';
            document.getElementById("tpi_City_asterisk_id").style.display = 'none';
            document.getElementById("tpi_State_asterisk_id").style.display = 'none';
            document.getElementById("tpi_Zip_asterisk_id").style.display = 'none';
            // document.getElementById("tpi_SSN_asterisk_id").style.display = 'none';
            // document.getElementById("tpi_EID_asterisk_id").style.display = 'none';

            document.getElementById("tpi_Name_id").readOnly = true;
            document.getElementById("tpi_BusinessName_id").readOnly = true;
            document.getElementById("tpi_Address_id").readOnly = true;
            document.getElementById("tpi_City_id").readOnly = true;
            document.getElementById("tpi_State_id").disabled = true;
            document.getElementById("tpi_Zip_id").readOnly = true;
            // document.getElementById("tpi_SSN_id").readOnly = true;
            // document.getElementById("tpi_EID_id").readOnly = true;
        }
    }

    updatetaxPayerSameAsPropertyOwnerNo = (evt) => {
        if (evt.target.checked === true) {
            this.setState({
                tpi_Name: "",
                tpi_BusinessName: "",
                tpi_City: "",
                tpi_State: "",
                tpi_Zip: "",
                tpi_SSN: "",
                tpi_EID: "",
            });

            document.getElementById("tpi_Name_asterisk_id").style.display = 'block';
            document.getElementById("tpi_BusinessName_asterisk_id").style.display = 'block';
            document.getElementById("tpi_Address_asterisk_id").style.display = 'block';
            document.getElementById("tpi_City_asterisk_id").style.display = 'block';
            document.getElementById("tpi_State_asterisk_id").style.display = 'block';
            document.getElementById("tpi_Zip_asterisk_id").style.display = 'block';
            document.getElementById("tpi_SSN_asterisk_id").style.display = 'block';
            document.getElementById("tpi_EID_asterisk_id").style.display = 'block';

            document.getElementById("tpi_Name_id").readOnly = false;
            document.getElementById("tpi_BusinessName_id").readOnly = false;
            document.getElementById("tpi_Address_id").readOnly = false;
            document.getElementById("tpi_City_id").readOnly = false;
            document.getElementById("tpi_State_id").disabled = false;
            document.getElementById("tpi_Zip_id").readOnly = false;
            document.getElementById("tpi_SSN_id").readOnly = false;
            document.getElementById("tpi_EID_id").readOnly = false;
        }
    }

    updateTPIName = (evt) => {
        this.setState({ tpi_Name: evt.target.value, tpi_Name_Error: "" });
    }
    updateTPIBusinessName = (evt) => {
        this.setState({ tpi_BusinessName: evt.target.value, tpi_BusinessName_Error: "" });
    }
    // updateTPIFederalTaxClassification = (evt) => {
    //     this.setState({ tpi_FederalTaxClassification: "" });
    //     this.setState({ selectedFederalTaxClassification: 1 });
    // }
    updateTPIFederalTaxClassificationIndividual = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ tpi_FederalTaxClassification: "Individual" });
            // document.getElementById("txtOtherFedClassification").style.display = "none";
            // document.getElementById("llcRadios").style.display = "none";
            document.getElementById("txtOtherFedClassification").readOnly = true;
            document.getElementById("llcClassificationId").disabled = true;

            this.setState({ tpi_LLCClassification: "" });
            this.setState({ tpi_OtherClassification: "" });

            document.getElementById("llcClassification_asterisk_Id").style.display = 'none';
            document.getElementById("txtOtherFedClassification_asterisk_id").style.display = 'none';
        }
        this.setState({ selectedFederalTaxClassification: 1, tpi_FederalTaxClassification_Error: "" });
    }
    updateTPIFederalTaxClassificationCCorp = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ tpi_FederalTaxClassification: "CCorporation" });
            // document.getElementById("txtOtherFedClassification").style.display = "none";
            // document.getElementById("llcRadios").style.display = "none";
            document.getElementById("txtOtherFedClassification").readOnly = true;
            document.getElementById("llcClassificationId").disabled = true;

            this.setState({ tpi_LLCClassification: "" });
            this.setState({ tpi_OtherClassification: "" });

            document.getElementById("llcClassification_asterisk_Id").style.display = 'none';
            document.getElementById("txtOtherFedClassification_asterisk_id").style.display = 'none';
        }
        this.setState({ selectedFederalTaxClassification: 1, tpi_FederalTaxClassification_Error: "" });
    }
    updateTPIFederalTaxClassificationSCorp = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ tpi_FederalTaxClassification: "SCorporation" });
            // document.getElementById("txtOtherFedClassification").style.display = "none";
            // document.getElementById("llcRadios").style.display = "none";
            document.getElementById("txtOtherFedClassification").readOnly = true;
            document.getElementById("llcClassificationId").disabled = true;

            this.setState({ tpi_LLCClassification: "" });
            this.setState({ tpi_OtherClassification: "" });

            document.getElementById("llcClassification_asterisk_Id").style.display = 'none';
            document.getElementById("txtOtherFedClassification_asterisk_id").style.display = 'none';
        }
        this.setState({ selectedFederalTaxClassification: 1, tpi_FederalTaxClassification_Error: "" });
    }
    updateTPIFederalTaxClassificationPartnership = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ tpi_FederalTaxClassification: "Partnership" });
            // document.getElementById("txtOtherFedClassification").style.display = "none";
            // document.getElementById("llcRadios").style.display = "none";
            document.getElementById("txtOtherFedClassification").readOnly = true;
            document.getElementById("llcClassificationId").disabled = true;

            this.setState({ tpi_LLCClassification: "" });
            this.setState({ tpi_OtherClassification: "" });

            document.getElementById("llcClassification_asterisk_Id").style.display = 'none';
            document.getElementById("txtOtherFedClassification_asterisk_id").style.display = 'none';
        }
        this.setState({ selectedFederalTaxClassification: 1, tpi_FederalTaxClassification_Error: "" });
    }
    updateTPIFederalTaxClassificationTrust = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ tpi_FederalTaxClassification: "Trust" });
            // document.getElementById("txtOtherFedClassification").style.display = "none";
            // document.getElementById("llcRadios").style.display = "none";
            document.getElementById("txtOtherFedClassification").readOnly = true;
            document.getElementById("llcClassificationId").disabled = true;

            this.setState({ tpi_LLCClassification: "" });
            this.setState({ tpi_OtherClassification: "" });

            document.getElementById("llcClassification_asterisk_Id").style.display = 'none';
            document.getElementById("txtOtherFedClassification_asterisk_id").style.display = 'none';
        }
        this.setState({ selectedFederalTaxClassification: 1, tpi_FederalTaxClassification_Error: "" });
    }
    updateTPIFederalTaxClassificationLLC = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ tpi_FederalTaxClassification: "LLC" });
            // document.getElementById("txtOtherFedClassification").style.display = "none";

            // document.getElementById("llcRadios").style.display = "block";
            document.getElementById("txtOtherFedClassification").readOnly = true;
            document.getElementById("llcClassificationId").disabled = false;

            this.setState({ tpi_LLCClassification: "" });
            this.setState({ tpi_OtherClassification: "" });

            document.getElementById("llcClassification_asterisk_Id").style.display = 'block';
            document.getElementById("txtOtherFedClassification_asterisk_id").style.display = 'none';
        }
        this.setState({ selectedFederalTaxClassification: 1, tpi_FederalTaxClassification_Error: "" });
    }
    updateTPIFederalTaxClassificationOther = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ tpi_FederalTaxClassification: "Other" });

            // document.getElementById("txtOtherFedClassification").style.display = "block";
            // document.getElementById("llcRadios").style.display = "none";
            document.getElementById("txtOtherFedClassification").readOnly = false;
            document.getElementById("llcClassificationId").disabled = true;

            this.setState({ tpi_LLCClassification: "" });
            this.setState({ tpi_OtherClassification: "" });

            document.getElementById("llcClassification_asterisk_Id").style.display = 'none';
            document.getElementById("txtOtherFedClassification_asterisk_id").style.display = 'block';
        }
        this.setState({ selectedFederalTaxClassification: 1, tpi_FederalTaxClassification_Error: "" });
    }

    // // updateTPILLCClassification = (evt) => {
    // //     this.setState({ tpi_LLCClassification: evt.target.value });
    // //     this.setState({ selectedLLCClassification: 1 });
    // // }
    // updateTPILLCClassificationCCorp = (evt) => {
    //     if (evt.target.checked === true) {
    //         this.setState({ tpi_LLCClassification: "CCorporation" });
    //     }
    //     this.setState({ selectedLLCClassification: 1, tpi_LLCClassification_Error: "" });
    // }
    // updateTPILLCClassificationSCorp = (evt) => {
    //     if (evt.target.checked === true) {
    //         this.setState({ tpi_LLCClassification: "SCorporation" });
    //     }
    //     this.setState({ selectedLLCClassification: 1, tpi_LLCClassification_Error: "" });
    // }
    // updateTPILLCClassificationPartnership = (evt) => {
    //     if (evt.target.checked === true) {
    //         this.setState({ tpi_LLCClassification: "Partnership" });
    //     }
    //     this.setState({ selectedLLCClassification: 1, tpi_LLCClassification_Error: "" });
    // }

    updateTPILLCClassification = (evt) => {
        this.setState({ selectedLLCClassification: 1, tpi_LLCClassification: evt.target.value, tpi_LLCClassification_Error: "" });
    }

    updateTPIOtherClassification = (evt) => {
        this.setState({ tpi_OtherClassification: evt.target.value, tpi_OtherClassification_Error: "" });
    }
    updateTPIExemptPayeeCode = (evt) => {
        this.setState({ tpi_ExemptPayeeCode: evt.target.value, tpi_ExemptPayeeCode_Error: "" });
    }
    updateTPIFATCAReportingCode = (evt) => {
        this.setState({ tpi_FATCAReportingCode: evt.target.value, tpi_FATCAReportingCode_Error: "" });
    }
    updateTPIUnit = (evt) => {
        this.setState({ tpi_Unit: evt.target.value });
    }
    updateTPIAddress = (evt) => {
        this.setState({ tpi_Address: evt.target.value, tpi_Address_Error: "" });
    }
    updateTPICity = (evt) => {
        this.setState({ tpi_City: evt.target.value, tpi_City_Error: "" });
    }
    updateTPIState = (evt) => {
        this.setState({ tpi_State: evt.target.value, tpi_State_Error: "" });
    }
    updateTPIZip = (evt) => {
        this.setState({ tpi_Zip: evt.target.value, tpi_Zip_Error: "" });
    }
    updateTPIAccountNumbers = (evt) => {
        this.setState({ tpi_AccountNumbers: evt.target.value, tpi_AccountNumbers_Error: "" });
    }
    updateTPISSN = (evt) => {
        this.setState({ tpi_SSN: evt.target.value, tpi_SSN_Error: "", tpi_EID_Error: "" });
    }
    updateTPIEID = (evt) => {
        this.setState({ tpi_EID: evt.target.value, tpi_EID_Error: "", tpi_SSN_Error: "" });
    }


    // MANAGEMENT COMPANY INFORMATION
    updateMCIName = (evt) => {
        this.setState({ mci_Name: evt.target.value, mci_Name_Error: "" });

        this.state.mci_Managers.map((item, index) => {
            var manager = item["mci_FirstName"] + ' ' + item["mci_MiddleName"] + ' ' + item["mci_LastName"];
            if (evt.target.value === manager) {
                this.setState({
                    mci_Name: manager,
                    mci_Phone: (item["mci_Phone"] === null || item["mci_Phone"] === undefined) ? '' : item["mci_Phone"].replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                    mci_PhoneExtension: item["mci_PhoneExtension"],
                    mci_Fax: item["mci_Fax"],
                    mci_Email: item["mci_Emailid"],
                    mci_Unit: item["mci_Unit"],
                    mci_Address: item["mci_Address"],
                    mci_City: item["mci_City"],
                    mci_State: item["mci_State"],
                    mci_Zip: item["mci_Zip"],

                    mci_ManagerID: item["mci_ManagerID"],
                    mci_PropertyID: item["mci_PropertyID"],
                    mci_Title: item["mci_Title"],
                    mci_FirstName: item["mci_FirstName"],
                    mci_MiddleName: item["mci_MiddleName"],
                    mci_LastName: item["mci_LastName"],
                    mci_ContactType: item["mci_ContactType"],
                });
            }
        })
    }
    updateMCIPhone = (evt) => {
        this.setState({ mci_Phone: evt.target.value, mci_Phone_Error: "" });
    }
    updateMCIPhoneExtension = (evt) => {
        this.setState({ mci_PhoneExtension: evt.target.value });
    }
    updateMCIFax = (evt) => {
        this.setState({ mci_Fax: evt.target.value, mci_Fax_Error: "" });
    }
    updateMCIEmail = (evt) => {
        this.setState({ mci_Email: evt.target.value, mci_Email_Error: "" });
    }
    updateMCIUnit = (evt) => {
        this.setState({ mci_Unit: evt.target.value });
    }
    updateMCIAddress = (evt) => {
        this.setState({ mci_Address: evt.target.value, mci_Address_Error: "" });
    }
    updateMCICity = (evt) => {
        this.setState({ mci_City: evt.target.value, mci_City_Error: "" });
    }
    updateMCIState = (evt) => {
        this.setState({ mci_State: evt.target.value, mci_State_Error: "" });
    }
    updateMCIZip = (evt) => {
        this.setState({ mci_Zip: evt.target.value, mci_Zip_Error: "" });
    }

    // MANAGEMENT COMPANY INFORMATION
    updateMCIName1 = (evt) => {
        this.setState({ mci_Name1: evt.target.value, mci_Name_Error1: "" });
    }
    updateMCIPhone1 = (evt) => {
        this.setState({ mci_Phone1: evt.target.value, mci_Phone_Error1: "" });
    }
    updateMCIPhoneExtension1 = (evt) => {
        this.setState({ mci_PhoneExtension1: evt.target.value });
    }
    updateMCIFax1 = (evt) => {
        this.setState({ mci_Fax1: evt.target.value, mci_Fax_Error1: "" });
    }
    updateMCIEmail1 = (evt) => {
        this.setState({ mci_Email1: evt.target.value, mci_Email_Error1: "" });
    }
    updateMCIUnit1 = (evt) => {
        this.setState({ mci_Unit1: evt.target.value });
    }
    updateMCIAddress1 = (evt) => {
        this.setState({ mci_Address1: evt.target.value, mci_Address_Error1: "" });
    }
    updateMCICity1 = (evt) => {
        this.setState({ mci_City1: evt.target.value, mci_City_Error1: "" });
    }
    updateMCIState1 = (evt) => {
        this.setState({ mci_State1: evt.target.value, mci_State_Error1: "" });
    }
    updateMCIZip1 = (evt) => {
        this.setState({ mci_Zip1: evt.target.value, mci_Zip_Error1: "" });
    }


    // FINANCIAL INSTITUTION INFORMATION
    updateFIIName = (evt) => {
        this.setState({ fii_Name: evt.target.value, fii_Name_Error: "" });
    }
    // updateFIIAccountType = (evt) => {
    //     this.setState({ fii_AccountType: evt.target.value });
    //     this.setState({ selectedAccountType: 1 });
    // }
    updateFIIAccountTypeSavings = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ fii_AccountType: "Savings" });
        }
        this.setState({ selectedAccountType: 1, fii_AccountType_Error: "" });
    }
    updateFIIAccountTypeChecking = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ fii_AccountType: "Checking" });
        }
        this.setState({ selectedAccountType: 1, fii_AccountType_Error: "" });
    }
    updateFIIUnit = (evt) => {
        this.setState({ fii_Unit: evt.target.value });
    }
    updateFIIAddress = (evt) => {
        this.setState({ fii_Address: evt.target.value, fii_Address_Error: "" });
    }
    updateFIICity = (evt) => {
        this.setState({ fii_City: evt.target.value, fii_City_Error: "" });
    }
    updateFIIState = (evt) => {
        this.setState({ fii_State: evt.target.value, fii_State_Error: "" });
    }
    updateFIIZip = (evt) => {
        this.setState({ fii_Zip: evt.target.value, fii_Zip_Error: "" });
    }
    updateFIIRoutingTransitNumber = (evt) => {
        this.setState({ fii_RoutingTransitNumber: evt.target.value, fii_RoutingTransitNumber_Error: "" });
    }
    updateFIIAccountNumber = (evt) => {
        this.setState({ fii_AccountNumber: evt.target.value, fii_AccountNumber_Error: "" });
    }


    // HOUSING AUTHORITIES CORRESPONDENCE INFORMATION
    updateHACIName = (evt) => {
        this.setState({ haci_Name: evt.target.value, haci_Name_Error: "" });
    }
    updateHACIInCareOf = (evt) => {
        this.setState({ haci_InCareOf: evt.target.value, haci_InCareOf_Error: "" });
    }
    updateHACIUnit = (evt) => {
        this.setState({ haci_Unit: evt.target.value });
    }
    updateHACIAddress = (evt) => {
        this.setState({ haci_Address: evt.target.value, haci_Address_Error: "" });
    }
    updateHACIPhone = (evt) => {
        this.setState({ haci_Phone: evt.target.value, haci_Phone_Error: "" });
    }
    updateHACIPhoneExtension = (evt) => {
        this.setState({ haci_PhoneExtension: evt.target.value });
    }
    updateHACIFax = (evt) => {
        this.setState({ haci_Fax: evt.target.value, haci_Fax_Error: "" });
    }
    updateHACIEmail = (evt) => {
        this.setState({ haci_Email: evt.target.value, haci_Email_Error: "" });
    }


    // REQUESTER INFORMATION
    updateRIName = (evt) => {
        this.setState({ ri_Name: evt.target.value, ri_Name_Error: "" });
    }
    updateRIUnit = (evt) => {
        this.setState({ ri_Unit: evt.target.value });
    }
    updateRIAddress = (evt) => {
        this.setState({ ri_Address: evt.target.value, ri_Address_Error: "" });
    }
    updateRICity = (evt) => {
        this.setState({ ri_City: evt.target.value, ri_City_Error: "" });
    }
    updateRIState = (evt) => {
        this.setState({ ri_State: evt.target.value, ri_State_Error: "" });
    }
    updateRIZip = (evt) => {
        this.setState({ ri_Zip: evt.target.value, ri_Zip_Error: "" });
    }

    render() {
        return (
            <div className="property-detail-main pha-step2">
                <div className="data-section">
                    <div className="border p-3">
                        <React.Fragment>
                            <input className="form-control" type="hidden" ></input>
                            {/* <h2 className="profile-title"><i class="fas fa-dollar-sign mr-2"></i>Transaction</h2> */}
                            <div class="form-row mb-3 search-area">
                                <div className="col-sm-2">
                                    <label>Tenant Name <i class="fas fa-asterisk asterisk"></i></label>
                                    <input className="form-control" type="text" maxlength="200" ref={this.piTenantNameRef} onChange={this.updateTenantName} value={this.state.tpi_TenantName} placeholder="Tenant Name" ></input>
                                    <div class="alert-small-text">{this.state.tpi_TenantName_Error}</div>
                                </div>

                                <div className="col-auto">
                                    <div><label class="col-auto p-0">Transaction <i class="fas fa-asterisk asterisk"></i></label></div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="newDirectDepositRequest" name="transaction" class="custom-control-input" ref={this.TransactionRef} onChange={this.updateNewDirectDepositRequest} />
                                        <label class="custom-control-label" for="newDirectDepositRequest">New Direct Deposit Request</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="changeInformation" name="transaction" class="custom-control-input" onChange={this.updateChangeInformation} />
                                        <label class="custom-control-label" for="changeInformation">Change Information (Account/Rounting # and/or financial institution)</label>
                                    </div>
                                    <div class="alert-small-text">{this.state.transaction_Error}</div>
                                </div>
                            </div>
                            <hr />
                            <div className="">
                                <div className="box-gray mb-4">
                                    <h2 class="profile-title"><i class="fas fa-home mr-2"></i>Property</h2>
                                    <div className="box-details">
                                        <div class="form-row">
                                            <div class="form-group col-sm-2">
                                                <label>Property Name / Principal </label>
                                                <input className="form-control" type="text" maxlength="100" ref={this.piNameRef} readOnly onChange={this.updatePIName} value={this.state.pi_Name} placeholder="Property Name / Principal" ></input>
                                                <div class="alert-small-text">{this.state.pi_Name_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Unit </label>
                                                <input className="form-control" type="text" maxlength="50" readOnly onChange={this.updatePIUnit} value={this.state.pi_Unit} placeholder="Unit" ></input>
                                                <div class="alert-small-text"></div>
                                            </div>
                                            <div class="form-group col-sm-4">
                                                <label>Rental Property Address </label>
                                                <input className="form-control" type="text" maxlength="200" ref={this.piAddressRef} readOnly onChange={this.updatePIAddress} value={this.state.pi_Address} placeholder="Rental Property Address" ></input>
                                                <div class="alert-small-text">{this.state.pi_Address_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>City </label>
                                                <input className="form-control" type="text" maxlength="10" ref={this.piCityRef} readOnly onChange={this.updatePICity} value={this.state.pi_City} placeholder="City" ></input>
                                                <div class="alert-small-text">{this.state.pi_City_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>State </label>
                                                <select className="form-control" ref={(input) => this.pi_StateSelector = input} disabled onChange={this.updatePIState} value={this.state.pi_State}>
                                                    <option value="selectstate">-- select --</option>
                                                    <option value="AL">AL</option>
                                                    <option value="AK">AK</option>
                                                    <option value="AR">AR</option>
                                                    <option value="AZ">AZ</option>
                                                    <option value="CA">CA</option>
                                                    <option value="CO">CO</option>
                                                    <option value="CT">CT</option>
                                                    <option value="DC">DC</option>
                                                    <option value="DE">DE</option>
                                                    <option value="FL">FL</option>
                                                    <option value="GA">GA</option>
                                                    <option value="HI">HI</option>
                                                    <option value="IA">IA</option>
                                                    <option value="ID">ID</option>
                                                    <option value="IL">IL</option>
                                                    <option value="IN">IN</option>
                                                    <option value="KS">KS</option>
                                                    <option value="KY">KY</option>
                                                    <option value="LA">LA</option>
                                                    <option value="MA">MA</option>
                                                    <option value="MD">MD</option>
                                                    <option value="ME">ME</option>
                                                    <option value="MI">MI</option>
                                                    <option value="MN">MN</option>
                                                    <option value="MO">MO</option>
                                                    <option value="MS">MS</option>
                                                    <option value="MT">MT</option>
                                                    <option value="NC">NC</option>
                                                    <option value="NE">NE</option>
                                                    <option value="NH">NH</option>
                                                    <option value="NJ">NJ</option>
                                                    <option value="NM">NM</option>
                                                    <option value="NV">NV</option>
                                                    <option value="NY">NY</option>
                                                    <option value="ND">ND</option>
                                                    <option value="OH">OH</option>
                                                    <option value="OK">OK</option>
                                                    <option value="OR">OR</option>
                                                    <option value="PA">PA</option>
                                                    <option value="RI">RI</option>
                                                    <option value="SC">SC</option>
                                                    <option value="SD">SD</option>
                                                    <option value="TN">TN</option>
                                                    <option value="TX">TX</option>
                                                    <option value="UT">UT</option>
                                                    <option value="VT">VT</option>
                                                    <option value="VA">VA</option>
                                                    <option value="WA">WA</option>
                                                    <option value="WI">WI</option>
                                                    <option value="WV">WV</option>
                                                    <option value="WY">WY</option>
                                                </select>
                                                <div class="alert-small-text">{this.state.pi_State_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>Zip </label>
                                                <input className="form-control" type="text" maxlength="5" ref={this.piZipRef} readOnly onChange={this.updatePIZip} value={this.state.pi_Zip} placeholder="Zip" ></input>
                                                <div class="alert-small-text">{this.state.pi_Zip_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-3">
                                                <label>Email </label>
                                                <input className="form-control" type="text" maxlength="20" ref={this.piEmailRef} readOnly onChange={this.updatePIEmail} value={this.state.pi_Email} placeholder="Email" ></input>
                                                <div class="alert-small-text">{this.state.pi_Email_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Phone </label>
                                                <input className="form-control" type="text" maxlength="14" ref={this.piPhoneRef} readOnly onChange={this.updatePIPhone} value={this.state.pi_Phone} placeholder="Phone" ></input>
                                                <div class="alert-small-text">{this.state.pi_Phone_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Phone Extension</label>
                                                <input className="form-control" type="text" maxlength="10" readOnly onChange={this.updatePIPhoneExtension} value={this.state.pi_PhoneExtension} placeholder="Phone Extension" ></input>
                                                <div class="alert-small-text"></div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Fax </label>
                                                <input className="form-control" type="text" maxlength="10" ref={this.piFaxRef} onChange={this.updatePIFax} value={this.state.pi_Fax} placeholder="Fax" ></input>
                                                <div class="alert-small-text">{this.state.pi_Fax_Error}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-gray mb-4">
                                    <h2 class="profile-title"><i class="fas fa-house-user mr-2"></i>Property Owner</h2>
                                    <div className="box-details">
                                        <div class="form-row">
                                            <div class="form-group col-sm-2">
                                                <label>Name (As it appears on account) </label>
                                                <input className="form-control" type="text" maxlength="100" ref={this.poiNameRef} readOnly onChange={this.updatePOIName} value={this.state.poi_Name} placeholder="Owner Name" ></input>
                                                <div class="alert-small-text">{this.state.poi_Name_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2" style={{ display: this.props.w9Uploaded ? 'none' : 'block' }}>
                                                <label>Business Name <i class="fas fa-asterisk asterisk"></i></label>
                                                <Tooltip title="Business Name/disregarded entity name, if different from Name">
                                                    <i className="fa fa-question-circle ml-3"></i>
                                                </Tooltip>
                                                <input className="form-control" type="text" maxlength="100" ref={this.poiBusinessNameRef} onChange={this.updatePOIBusinessName} value={this.state.poi_BusinessName} placeholder="Business Name" ></input>
                                                <div class="alert-small-text">{this.state.poi_BusinessName_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-3">
                                                <label>Address </label>
                                                <input className="form-control" type="text" ref={this.poiAddressRef} readOnly onChange={this.updatePOIAddress} value={this.state.poi_Address} placeholder="Address" ></input>
                                                <div class="alert-small-text">{this.state.poi_Address_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>City </label>
                                                <input className="form-control" type="text" maxlength="10" ref={this.poiCityRef} readOnly onChange={this.updatePOICity} value={this.state.poi_City} placeholder="City" ></input>
                                                <div class="alert-small-text">{this.state.poi_City_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>State </label>
                                                <select className="form-control" ref={(input) => this.poi_StateSelector = input} disabled onChange={this.updatePOIState} value={this.state.poi_State}>
                                                    <option value="selectstate">-- select --</option>
                                                    <option value="AL">AL</option>
                                                    <option value="AK">AK</option>
                                                    <option value="AR">AR</option>
                                                    <option value="AZ">AZ</option>
                                                    <option value="CA">CA</option>
                                                    <option value="CO">CO</option>
                                                    <option value="CT">CT</option>
                                                    <option value="DC">DC</option>
                                                    <option value="DE">DE</option>
                                                    <option value="FL">FL</option>
                                                    <option value="GA">GA</option>
                                                    <option value="HI">HI</option>
                                                    <option value="IA">IA</option>
                                                    <option value="ID">ID</option>
                                                    <option value="IL">IL</option>
                                                    <option value="IN">IN</option>
                                                    <option value="KS">KS</option>
                                                    <option value="KY">KY</option>
                                                    <option value="LA">LA</option>
                                                    <option value="MA">MA</option>
                                                    <option value="MD">MD</option>
                                                    <option value="ME">ME</option>
                                                    <option value="MI">MI</option>
                                                    <option value="MN">MN</option>
                                                    <option value="MO">MO</option>
                                                    <option value="MS">MS</option>
                                                    <option value="MT">MT</option>
                                                    <option value="NC">NC</option>
                                                    <option value="NE">NE</option>
                                                    <option value="NH">NH</option>
                                                    <option value="NJ">NJ</option>
                                                    <option value="NM">NM</option>
                                                    <option value="NV">NV</option>
                                                    <option value="NY">NY</option>
                                                    <option value="ND">ND</option>
                                                    <option value="OH">OH</option>
                                                    <option value="OK">OK</option>
                                                    <option value="OR">OR</option>
                                                    <option value="PA">PA</option>
                                                    <option value="RI">RI</option>
                                                    <option value="SC">SC</option>
                                                    <option value="SD">SD</option>
                                                    <option value="TN">TN</option>
                                                    <option value="TX">TX</option>
                                                    <option value="UT">UT</option>
                                                    <option value="VT">VT</option>
                                                    <option value="VA">VA</option>
                                                    <option value="WA">WA</option>
                                                    <option value="WI">WI</option>
                                                    <option value="WV">WV</option>
                                                    <option value="WY">WY</option>
                                                </select>
                                                <div class="alert-small-text">{this.state.poi_State_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>Zip </label>
                                                <input className="form-control" type="text" maxlength="5" ref={this.poiZipRef} readOnly onChange={this.updatePOIZip} value={this.state.poi_Zip} placeholder="Zip" ></input>
                                                <div class="alert-small-text">{this.state.poi_Zip_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-3">
                                                <label>Email </label>
                                                <input className="form-control" type="text" maxlength="20" ref={this.poiEmailRef} readOnly onChange={this.updatePOIEmail} value={this.state.poi_Email} placeholder="Email" ></input>
                                                <div class="alert-small-text">{this.state.poi_Email_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Phone </label>
                                                <input className="form-control" type="text" maxlength="14" ref={this.poiPhoneRef} readOnly onChange={this.updatePOIPhone} value={this.state.poi_Phone} placeholder="Phone" ></input>
                                                <div class="alert-small-text">{this.state.poi_Phone_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Fax </label>
                                                <input className="form-control" type="text" maxlength="10" ref={this.poiFaxRef} onChange={this.updatePOIFax} value={this.state.poi_Fax} placeholder="Fax" ></input>
                                                <div class="alert-small-text">{this.state.poi_Fax_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-3">
                                                <label>Social Security Number <i class="fas fa-asterisk asterisk"></i></label>
                                                <Tooltip title="Either Social Security Number or Employer Identification Number is required">
                                                    <i className="fa fa-question-circle ml-3"></i>
                                                </Tooltip>
                                                <input className="form-control" type="text" maxlength="9" ref={this.poiSSNRef} onChange={this.updatePOISSN} value={this.state.poi_SSN} placeholder="Social Security Number" ></input>
                                                <div class="alert-small-text">{this.state.poi_SSN_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-3">
                                                <label>Employer Identification Number <i class="fas fa-asterisk asterisk"></i></label>
                                                <Tooltip title="Either Employer Identification Number or Social Security Number is required">
                                                    <i className="fa fa-question-circle ml-3"></i>
                                                </Tooltip>
                                                <input className="form-control" type="text" maxlength="9" ref={this.poiEIDRef} onChange={this.updatePOIEID} value={this.state.poi_EID} placeholder="Employer Identification Number" ></input>
                                                <div class="alert-small-text">{this.state.poi_EID_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2" style={{ display: 'none' }}>
                                                <label>Tax ID <i class="fas fa-asterisk asterisk"></i></label>
                                                <input className="form-control" type="text" maxlength="10" ref={this.poiTaxIDRef} onChange={this.updatePOITaxID} value={this.state.poi_TaxID} placeholder="Tax ID" ></input>
                                                <div class="alert-small-text">{this.state.poi_TaxID_Error}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-gray mb-4">
                                    <h2 class="profile-title"><i class="fas fa-user-circle mr-2"></i>Payee</h2>
                                    <div className="box-details">
                                        <div class="form-row">
                                            <div className="col-sm-2">
                                                <div><label class="col-auto p-0">Same as Property Owner?</label></div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="payeeSameAsPropertyOwnerYes" name="payeeSameAsPropertyOwner" class="custom-control-input" onChange={this.updatePayeeSameAsPropertyOwnerYes} />
                                                    <label class="custom-control-label" for="payeeSameAsPropertyOwnerYes">Yes</label>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="payeeSameAsPropertyOwnerNo" name="payeeSameAsPropertyOwner" class="custom-control-input" onChange={this.updatePayeeSameAsPropertyOwnerNo} />
                                                    <label class="custom-control-label" for="payeeSameAsPropertyOwnerNo">No</label>
                                                </div>
                                            </div>

                                            <div class="form-group col-sm-2">
                                                <label>Payee Name <i class="fas fa-asterisk asterisk" id="pyei_Name_asterisk_id"></i></label>
                                                <input className="form-control" type="text" maxlength="100" id="pyei_Name_id" ref={this.pyeiNameRef} onChange={this.updatePYEIName} value={this.state.pyei_Name} placeholder="Payee Name" ></input>
                                                <div class="alert-small-text">{this.state.pyei_Name_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Unit </label>
                                                <input className="form-control" type="text" maxlength="50" onChange={this.updatePYEIUnit} value={this.state.pyei_Unit} placeholder="Unit" ></input>
                                                <div class="alert-small-text"></div>
                                            </div>
                                            <div class="form-group col-sm-3">
                                                <label>Mailing Address <i class="fas fa-asterisk asterisk" id="pyei_Address_asterisk_id"></i></label>
                                                <input className="form-control" type="text" maxlength="255" id="pyei_Address_id" ref={this.pyeiAddressRef} onChange={this.updatePYEIAddress} value={this.state.pyei_Address} placeholder="Mailing Address" ></input>
                                                <div class="alert-small-text">{this.state.pyei_Address_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>City <i class="fas fa-asterisk asterisk" id="pyei_City_asterisk_id"></i></label>
                                                <input className="form-control" type="text" maxlength="20" id="pyei_City_id" ref={this.pyeiCityRef} onChange={this.updatePYEICity} value={this.state.pyei_City} placeholder="City" ></input>
                                                <div class="alert-small-text">{this.state.pyei_City_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>State <i class="fas fa-asterisk asterisk" id="pyei_State_asterisk_id"></i></label>
                                                <select className="form-control" id="pyei_State_id" ref={(input) => this.pyei_StateSelector = input} onChange={this.updatePYEIState} value={this.state.pyei_State}>
                                                    <option value="selectstate">-- select --</option>
                                                    <option value="AL">AL</option>
                                                    <option value="AK">AK</option>
                                                    <option value="AR">AR</option>
                                                    <option value="AZ">AZ</option>
                                                    <option value="CA">CA</option>
                                                    <option value="CO">CO</option>
                                                    <option value="CT">CT</option>
                                                    <option value="DC">DC</option>
                                                    <option value="DE">DE</option>
                                                    <option value="FL">FL</option>
                                                    <option value="GA">GA</option>
                                                    <option value="HI">HI</option>
                                                    <option value="IA">IA</option>
                                                    <option value="ID">ID</option>
                                                    <option value="IL">IL</option>
                                                    <option value="IN">IN</option>
                                                    <option value="KS">KS</option>
                                                    <option value="KY">KY</option>
                                                    <option value="LA">LA</option>
                                                    <option value="MA">MA</option>
                                                    <option value="MD">MD</option>
                                                    <option value="ME">ME</option>
                                                    <option value="MI">MI</option>
                                                    <option value="MN">MN</option>
                                                    <option value="MO">MO</option>
                                                    <option value="MS">MS</option>
                                                    <option value="MT">MT</option>
                                                    <option value="NC">NC</option>
                                                    <option value="NE">NE</option>
                                                    <option value="NH">NH</option>
                                                    <option value="NJ">NJ</option>
                                                    <option value="NM">NM</option>
                                                    <option value="NV">NV</option>
                                                    <option value="NY">NY</option>
                                                    <option value="ND">ND</option>
                                                    <option value="OH">OH</option>
                                                    <option value="OK">OK</option>
                                                    <option value="OR">OR</option>
                                                    <option value="PA">PA</option>
                                                    <option value="RI">RI</option>
                                                    <option value="SC">SC</option>
                                                    <option value="SD">SD</option>
                                                    <option value="TN">TN</option>
                                                    <option value="TX">TX</option>
                                                    <option value="UT">UT</option>
                                                    <option value="VT">VT</option>
                                                    <option value="VA">VA</option>
                                                    <option value="WA">WA</option>
                                                    <option value="WI">WI</option>
                                                    <option value="WV">WV</option>
                                                    <option value="WY">WY</option>
                                                </select>
                                                <div class="alert-small-text">{this.state.pyei_State_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>Zip <i class="fas fa-asterisk asterisk" id="pyei_Zip_asterisk_id"></i></label>
                                                <input className="form-control" type="text" maxlength="5" id="pyei_Zip_id" ref={this.pyeiZipRef} onChange={this.updatePYEIZip} value={this.state.pyei_Zip} placeholder="Zip" ></input>
                                                <div class="alert-small-text">{this.state.pyei_Zip_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Email <i class="fas fa-asterisk asterisk" id="pyei_Email_asterisk_id"></i></label>
                                                <Tooltip title="To be used for payment notification">
                                                    <i className="fa fa-question-circle ml-3"></i>
                                                </Tooltip>
                                                <input className="form-control" type="text" maxlength="100" id="pyei_Email_id" ref={this.pyeiEmailRef} onChange={this.updatePYEIEmail} value={this.state.pyei_Email} placeholder="Email Address" ></input>
                                                <div class="alert-small-text">{this.state.pyei_Email_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Phone <i class="fas fa-asterisk asterisk" id="pyei_Phone_asterisk_id"></i></label>
                                                <input className="form-control" type="text" maxlength="14" id="pyei_Phone_id" ref={this.pyeiPhoneRef} onChange={this.updatePYEIPhone} value={this.state.pyei_Phone} placeholder="Phone" ></input>
                                                <div class="alert-small-text">{this.state.pyei_Phone_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Phone Extension</label>
                                                <input className="form-control" type="text" maxlength="12" onChange={this.updatePYEIPhoneExtension} value={this.state.pyei_PhoneExtension} placeholder="Phone Extension" ></input>
                                                <div class="alert-small-text"></div>
                                            </div>
                                            <div class="form-group col-sm-3">
                                                <label>Social Security Number <i class="fas fa-asterisk asterisk" id="pyei_SSN_asterisk_id"></i></label>
                                                <Tooltip title="Either Social Security Number or Employer Identification Number is required">
                                                    <i className="fa fa-question-circle ml-3"></i>
                                                </Tooltip>
                                                <input className="form-control" type="text" maxlength="9" id="pyei_SSN_id" ref={this.pyeiSSNRef} onChange={this.updatePYEISSN} value={this.state.pyei_SSN} placeholder="Social Security Number" ></input>
                                                <div class="alert-small-text">{this.state.pyei_SSN_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-3">
                                                <label>Employer Identification Number <i class="fas fa-asterisk asterisk" id="pyei_EID_asterisk_id"></i></label>
                                                <Tooltip title="Either Employer Identification Number or Social Security Number is required">
                                                    <i className="fa fa-question-circle ml-3"></i>
                                                </Tooltip>
                                                <input className="form-control" type="text" maxlength="9" id="pyei_EID_id" ref={this.pyeiEIDRef} onChange={this.updatePYEIEID} value={this.state.pyei_EID} placeholder="Employer Identification Number" ></input>
                                                <div class="alert-small-text">{this.state.pyei_EID_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2" style={{ display: 'none' }}>
                                                <label>Payee Tax ID <i class="fas fa-asterisk asterisk"></i></label>
                                                <input className="form-control" type="text" maxlength="10" ref={this.pyeiTaxIDRef} onChange={this.updatePYEITaxID} value={this.state.pyei_TaxID} placeholder="Payee Tax ID" ></input>
                                                <div class="alert-small-text">{this.state.pyei_TaxID_Error}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-gray mb-4">
                                    <h2 class="profile-title"><i class="fas fa-user-alt mr-2"></i>Tax Payer</h2>
                                    <div className="box-details">
                                        <div class="form-row">
                                            <div className="col-auto">
                                                <div><label class="col-auto p-0">Same as Property Owner?</label></div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="taxPayerSameAsPropertyOwnerYes" name="taxPayerSameAsPropertyOwner" class="custom-control-input" onChange={this.updatetaxPayerSameAsPropertyOwnerYes} />
                                                    <label class="custom-control-label" for="taxPayerSameAsPropertyOwnerYes">Yes</label>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="taxPayerSameAsPropertyOwnerNo" name="taxPayerSameAsPropertyOwner" class="custom-control-input" onChange={this.updatetaxPayerSameAsPropertyOwnerNo} />
                                                    <label class="custom-control-label" for="taxPayerSameAsPropertyOwnerNo">No</label>
                                                </div>
                                            </div>

                                            <div class="form-group col-sm-2">
                                                <label>Name <i class="fas fa-asterisk asterisk" id="tpi_Name_asterisk_id"></i></label>
                                                <Tooltip title="As shown on your income tax return">
                                                    <i className="fa fa-question-circle ml-3"></i>
                                                </Tooltip>
                                                <input className="form-control" type="text" maxlength="255" id="tpi_Name_id" ref={this.tpiNameRef} onChange={this.updateTPIName} value={this.state.tpi_Name} placeholder="Name" ></input>
                                                <div class="alert-small-text">{this.state.tpi_Name_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Business Name <i class="fas fa-asterisk asterisk" id="tpi_BusinessName_asterisk_id"></i></label>
                                                <Tooltip title="Business Name/disregarded entity name, if different from Name">
                                                    <i className="fa fa-question-circle ml-3"></i>
                                                </Tooltip>
                                                <input className="form-control" type="text" maxlength="255" id="tpi_BusinessName_id" ref={this.tpiBusinessNameRef} onChange={this.updateTPIBusinessName} value={this.state.tpi_BusinessName} placeholder="Business Name" ></input>
                                                <div class="alert-small-text">{this.state.tpi_BusinessName_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-12" style={{ display: this.props.w9Uploaded ? 'none' : 'block' }}>
                                                <label>Federal tax classification <i class="fas fa-asterisk asterisk"></i></label>
                                                <div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="individual" name="taxClassification" ref={this.tpiFederalTaxClassificationRef} onChange={this.updateTPIFederalTaxClassificationIndividual} class="custom-control-input" />
                                                        <label class="custom-control-label" for="individual">Individual/sole proprietor or single-member LLC</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="ccorporation" name="taxClassification" class="custom-control-input" onChange={this.updateTPIFederalTaxClassificationCCorp} />
                                                        <label class="custom-control-label" for="ccorporation">C Corporation</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="scorporation" name="taxClassification" class="custom-control-input" onChange={this.updateTPIFederalTaxClassificationSCorp} />
                                                        <label class="custom-control-label" for="scorporation">S Corporation</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="partnership" name="taxClassification" class="custom-control-input" onChange={this.updateTPIFederalTaxClassificationPartnership} />
                                                        <label class="custom-control-label" for="partnership">Partnership</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="trust" name="taxClassification" class="custom-control-input" onChange={this.updateTPIFederalTaxClassificationTrust} />
                                                        <label class="custom-control-label" for="trust">Trust/estate</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="llc" name="taxClassification" class="custom-control-input" onChange={this.updateTPIFederalTaxClassificationLLC} />
                                                        <label class="custom-control-label" for="llc">Limited liability company (LLC)</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="other" name="taxClassification" class="custom-control-input" onChange={this.updateTPIFederalTaxClassificationOther} />
                                                        <label class="custom-control-label" for="other">Other</label>
                                                    </div>

                                                </div>
                                                <div class="alert-small-text">{this.state.tpi_FederalTaxClassification_Error}</div>

                                                {/* <div id="llcRadios" style={{ display: 'none' }}>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="llcCCorporation" name="taxLLCClassification" class="custom-control-input" onChange={this.updateTPILLCClassificationCCorp} />
                                                        <label class="custom-control-label" for="llcCCorporation">C Corporation</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="llcSCorporation" name="taxLLCClassification" class="custom-control-input" onChange={this.updateTPILLCClassificationSCorp} />
                                                        <label class="custom-control-label" for="llcSCorporation">S Corporation</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="llcPartership" name="taxLLCClassification" class="custom-control-input" onChange={this.updateTPILLCClassificationPartnership} />
                                                        <label class="custom-control-label" for="llcPartership">Partnership</label>
                                                    </div>
                                                </div>
                                                <div class="alert-small-text">{this.state.tpi_LLCClassification_Error}</div> */}

                                            </div>

                                            <div class="form-group col-sm-2" style={{ display: this.props.w9Uploaded ? 'none' : 'block' }}>
                                                <label>LLC Classification <i class="fas fa-asterisk asterisk" id="llcClassification_asterisk_Id"></i></label>
                                                <select className="form-control" id="llcClassificationId" ref={(input) => this.tpi_LLCClassificationSelector = input} onChange={this.updateTPILLCClassification} value={this.state.tpi_LLCClassification}>
                                                    <option value="selectllc">-- select --</option>
                                                    <option value="CCorporation">C Corporation</option>
                                                    <option value="SCorporation">S Corporation</option>
                                                    <option value="Partership">Partership</option>
                                                </select>
                                                <div class="alert-small-text">{this.state.tpi_LLCClassification_Error}</div>
                                            </div>

                                            <div class="form-group col-sm-2" style={{ display: this.props.w9Uploaded ? 'none' : 'block' }}>
                                                <label>Other Classification <i class="fas fa-asterisk asterisk" id="txtOtherFedClassification_asterisk_id"></i></label>
                                                <input className="form-control" id="txtOtherFedClassification" type="text" maxlength="100" ref={this.tpiOtherClassificationRef} onChange={this.updateTPIOtherClassification} value={this.state.tpi_OtherClassification} placeholder="Other Classification" ></input>
                                                <div class="alert-small-text">{this.state.tpi_OtherClassification_Error}</div>
                                            </div>

                                            {/* <div class="form-group col-sm-2" style={{display: this.props.w9Uploaded? 'none': 'block'}}>
                                                <label>Exempt payee code (if any)</label>
                                                <input className="form-control" type="text" maxlength="10" ref={this.tpiExemptPayeeCodeRef} onChange={this.updateTPIExemptPayeeCode} value={this.state.tpi_ExemptPayeeCode} placeholder="Exempt payee code" ></input>
                                                <div class="alert-small-text">{this.state.tpi_ExemptPayeeCode_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-3" style={{display: this.props.w9Uploaded? 'none': 'block'}}>
                                                <label>Exemption from FATCA reporting code (if any)</label>
                                                <input className="form-control" type="text" maxlength="10" ref={this.tpiFATCAReportingCodeRef} onChange={this.updateTPIFATCAReportingCode} value={this.state.tpi_FATCAReportingCode} placeholder="Exemption from FATCA reporting code" ></input>
                                                <div class="alert-small-text">{this.state.tpi_FATCAReportingCode_Error}</div>
                                            </div> */}


                                            <div class="form-group col-sm-3" style={{ display: this.props.w9Uploaded ? 'none' : 'block' }}>
                                                <label>Exempt payee code (if any)</label>
                                                <select className="form-control" ref={(input) => this.tpiExemptPayeeCodeSelector = input} onChange={this.updateTPIExemptPayeeCode} value={this.state.tpi_ExemptPayeeCode}>
                                                    <option value="selectepc">-- select --</option>
                                                    <option value="1">1 - An organization exempt from tax under section 501(a), any IRA, or a custodial account under section 403(b)(7) if the account satisfies the requirements of section 401(f)(2)</option>
                                                    <option value="2">2 - The United States or any of its agencies or instrumentalities</option>
                                                    <option value="3">3 - A state, the District of Columbia, a U.S. commonwealth or possession, or any of their political subdivisions or instrumentalities</option>
                                                    <option value="4">4 - A foreign government or any of its political subdivisions, agencies, or instrumentalities</option>
                                                    <option value="5">5 - A corporation</option>
                                                    <option value="6">6 - A dealer in securities or commodities required to register in the United States, the District of Columbia, or a U.S. commonwealth or possession</option>
                                                    <option value="7">7 - A futures commission merchant registered with Commodity Futures Trading Commision</option>
                                                    <option value="8">8 - A real estate investment trust</option>
                                                    <option value="9">9 - An entity registered at all times during the tax year under the Investment Company Act of 1940</option>
                                                    <option value="10">10 - A common trust fund operated by a bank under section 584(a)</option>
                                                    <option value="11">11 - A financial institution</option>
                                                    <option value="12">12 - A middleman known in the investment community as a nominee or custodian</option>
                                                    <option value="13">13 - A trust exempt from tax under section 664 or described in section 4947</option>
                                                </select>
                                                <div class="alert-small-text">{this.state.tpi_ExemptPayeeCode_Error}</div>
                                            </div>

                                            <div class="form-group col-sm-4" style={{ display: this.props.w9Uploaded ? 'none' : 'block' }}>
                                                <label>Exemption from FATCA reporting code (if any)</label>
                                                <select className="form-control" ref={(input) => this.tpiFATCAReportingCodeSelector = input} onChange={this.updateTPIFATCAReportingCode} value={this.state.tpi_FATCAReportingCode}>
                                                    <option value="selectepc">-- select --</option>
                                                    <option value="A">A - An organization exempt from tax under section 501(a) or any individual retirement plan as defined in section 7701(a)(37)</option>
                                                    <option value="B">B - The United States or any of its agencies or instrumentalities</option>
                                                    <option value="C">C - A state, the District of Columbia, a U.S. commonwealth or possession, or any of their political subdivisions or instrumentalities</option>
                                                    <option value="D">D - A corporation the stock of which is regularly traded on one or more established securities markets, as described in Rebulations section 1.1472-1(c)(1)(i)</option>
                                                    <option value="E">E - A corporation that is a member of the same expanded affiliated group as a corporation described in Regulations section 1.1472-1(c)(1)(i)</option>
                                                    <option value="F">F - A dealer in securities, commoditites, or derivative financial instruments (including notional principal contracts, futures, forwards, and options) that is registered as such under the laws of the Unites States or any state</option>
                                                    <option value="G">G - A real estate investment trust</option>
                                                    <option value="H">H - A regulated investment company as defined in section 851 or an entity registered at all times during the tax year under the Investment Company Act of 1940</option>
                                                    <option value="I">I - A common trust fund as defined in section 584(a)</option>
                                                    <option value="J">J - A bank as defined in section 581</option>
                                                    <option value="K">K - A broker</option>
                                                    <option value="L">L - A trust exempt from tax under section 664 or described in section 4947(a)(1)</option>
                                                </select>
                                                <div class="alert-small-text">{this.state.tpi_FATCAReportingCode_Error}</div>
                                            </div>


                                            <div class="form-group col-sm-2">
                                                <label>Unit</label>
                                                <input className="form-control" type="text" maxlength="50" onChange={this.updateTPIUnit} value={this.state.tpi_Unit} placeholder="Unit" ></input>
                                                <div class="alert-small-text"></div>
                                            </div>
                                            <div class="form-group col-sm-3">
                                                <label>Address <i class="fas fa-asterisk asterisk" id="tpi_Address_asterisk_id"></i></label>
                                                <input className="form-control" type="text" maxlength="255" id="tpi_Address_id" ref={this.tpiAddressRef} onChange={this.updateTPIAddress} value={this.state.tpi_Address} placeholder="Street Address" ></input>
                                                <div class="alert-small-text">{this.state.tpi_Address_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>City <i class="fas fa-asterisk asterisk" id="tpi_City_asterisk_id"></i></label>
                                                <input className="form-control" type="text" maxlength="10" id="tpi_City_id" ref={this.tpiCityRef} onChange={this.updateTPICity} value={this.state.tpi_City} placeholder="City" ></input>
                                                <div class="alert-small-text">{this.state.tpi_City_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>State <i class="fas fa-asterisk asterisk" id="tpi_State_asterisk_id"></i></label>
                                                <select className="form-control" id="tpi_State_id" ref={(input) => this.tpi_StateSelector = input} onChange={this.updateTPIState} value={this.state.tpi_State}>
                                                    <option value="selectstate">-- select --</option>
                                                    <option value="AL">AL</option>
                                                    <option value="AK">AK</option>
                                                    <option value="AR">AR</option>
                                                    <option value="AZ">AZ</option>
                                                    <option value="CA">CA</option>
                                                    <option value="CO">CO</option>
                                                    <option value="CT">CT</option>
                                                    <option value="DC">DC</option>
                                                    <option value="DE">DE</option>
                                                    <option value="FL">FL</option>
                                                    <option value="GA">GA</option>
                                                    <option value="HI">HI</option>
                                                    <option value="IA">IA</option>
                                                    <option value="ID">ID</option>
                                                    <option value="IL">IL</option>
                                                    <option value="IN">IN</option>
                                                    <option value="KS">KS</option>
                                                    <option value="KY">KY</option>
                                                    <option value="LA">LA</option>
                                                    <option value="MA">MA</option>
                                                    <option value="MD">MD</option>
                                                    <option value="ME">ME</option>
                                                    <option value="MI">MI</option>
                                                    <option value="MN">MN</option>
                                                    <option value="MO">MO</option>
                                                    <option value="MS">MS</option>
                                                    <option value="MT">MT</option>
                                                    <option value="NC">NC</option>
                                                    <option value="NE">NE</option>
                                                    <option value="NH">NH</option>
                                                    <option value="NJ">NJ</option>
                                                    <option value="NM">NM</option>
                                                    <option value="NV">NV</option>
                                                    <option value="NY">NY</option>
                                                    <option value="ND">ND</option>
                                                    <option value="OH">OH</option>
                                                    <option value="OK">OK</option>
                                                    <option value="OR">OR</option>
                                                    <option value="PA">PA</option>
                                                    <option value="RI">RI</option>
                                                    <option value="SC">SC</option>
                                                    <option value="SD">SD</option>
                                                    <option value="TN">TN</option>
                                                    <option value="TX">TX</option>
                                                    <option value="UT">UT</option>
                                                    <option value="VT">VT</option>
                                                    <option value="VA">VA</option>
                                                    <option value="WA">WA</option>
                                                    <option value="WI">WI</option>
                                                    <option value="WV">WV</option>
                                                    <option value="WY">WY</option>
                                                </select>
                                                <div class="alert-small-text">{this.state.tpi_State_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>Zip <i class="fas fa-asterisk asterisk" id="tpi_Zip_asterisk_id"></i></label>
                                                <input className="form-control" type="text" maxlength="5" id="tpi_Zip_id" ref={this.tpiZipRef} onChange={this.updateTPIZip} value={this.state.tpi_Zip} placeholder="Zip" ></input>
                                                <div class="alert-small-text">{this.state.tpi_Zip_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-3">
                                                <label>List account number(s) here (optional)</label>
                                                <Tooltip title="Account Numbers separated by ';'">
                                                    <i className="fa fa-question-circle ml-3"></i>
                                                </Tooltip>
                                                <input className="form-control" type="text" maxlength="255" ref={this.tpiAccountNumbersRef} onChange={this.updateTPIAccountNumbers} value={this.state.tpi_AccountNumbers} placeholder="Account Numbers separated by ';'" ></input>
                                                <div class="alert-small-text">{this.state.tpi_AccountNumbers_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-3">
                                                <label>Social Security Number <i class="fas fa-asterisk asterisk" id="tpi_SSN_asterisk_id"></i></label>
                                                <Tooltip title="Either Social Security Number or Employer Identification Number is required">
                                                    <i className="fa fa-question-circle ml-3"></i>
                                                </Tooltip>
                                                <input className="form-control" type="text" maxlength="9" id="tpi_SSN_id" ref={this.tpiSSNRef} onChange={this.updateTPISSN} value={this.state.tpi_SSN} placeholder="Social Security Number" ></input>
                                                <div class="alert-small-text">{this.state.tpi_SSN_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-3">
                                                <label>Employer Identification Number <i class="fas fa-asterisk asterisk" id="tpi_EID_asterisk_id"></i></label>
                                                <Tooltip title="Either Employer Identification Number or Social Security Number is required">
                                                    <i className="fa fa-question-circle ml-3"></i>
                                                </Tooltip>
                                                <input className="form-control" type="text" maxlength="9" id="tpi_EID_id" ref={this.tpiEIDRef} onChange={this.updateTPIEID} value={this.state.tpi_EID} placeholder="Employer Identification Number" ></input>
                                                <div class="alert-small-text">{this.state.tpi_EID_Error}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-gray mb-4">
                                    <h2 class="profile-title"><i class="fas fa-user-tie mr-2"></i>Management Company</h2>
                                    <div className="box-details">

                                        {this.state.mci_Managers.length > 0 &&
                                            <div class="form-row">
                                                <div class="form-group col-sm-2">
                                                    <label>Company / Manager Name </label>
                                                    <input className="form-control" type="text" maxlength="100" ref={this.mciNameRef} onChange={this.updateMCIName} value={this.state.mci_Name} style={{ display: this.state.mci_Managers.length === 0 ? 'block' : 'none' }} placeholder="Company / Manager Name" ></input>

                                                    <select className="form-control" ref={(input) => this.mciNameSelector = input} onChange={this.updateMCIName} value={this.state.mci_Name} style={{ display: this.state.mci_Managers.length === 0 ? 'none' : 'block' }}>
                                                        <option value="">-- select --</option>
                                                        {
                                                            this.state.mci_Managers.map((item, index) => {
                                                                // console.log(item["mci_FirstName"] + item["mci_MiddleName"] + item["mci_LastName"]);
                                                                // console.log(this.state.mci_Name);
                                                                if (this.state.mci_Name === item["mci_FirstName"] + ' ' + item["mci_MiddleName"] + ' ' + item["mci_LastName"]) {
                                                                    return <option value={item["mci_FirstName"] + ' ' + item["mci_MiddleName"] + ' ' + item["mci_LastName"]} selected>{item["mci_FirstName"] + ' ' + item["mci_MiddleName"] + ' ' + item["mci_LastName"]}</option>
                                                                }
                                                                else {
                                                                    return <option value={item["mci_FirstName"] + ' ' + item["mci_MiddleName"] + ' ' + item["mci_LastName"]}>{item["mci_FirstName"] + ' ' + item["mci_MiddleName"] + ' ' + item["mci_LastName"]}</option>
                                                                }
                                                            })
                                                        }
                                                    </select>

                                                    <div class="alert-small-text">{this.state.mci_Name_Error}</div>
                                                </div>
                                                <div class="form-group col-sm-1">
                                                    <label>Unit </label>
                                                    <input className="form-control" type="text" maxlength="50" onChange={this.updateMCIUnit} value={this.state.mci_Unit} placeholder="Unit" ></input>
                                                    <div class="alert-small-text"></div>
                                                </div>
                                                <div class="form-group col-sm-1">
                                                    <label>Address </label>
                                                    <input className="form-control" type="text" maxlength="200" ref={this.mciAddressRef} onChange={this.updateMCIAddress} value={this.state.mci_Address} placeholder="Address" ></input>
                                                    <div class="alert-small-text">{this.state.mci_Address_Error}</div>
                                                </div>
                                                <div class="form-group col-sm-1">
                                                    <label>City </label>
                                                    <input className="form-control" type="text" maxlength="10" ref={this.mciCityRef} onChange={this.updateMCICity} value={this.state.mci_City} placeholder="City" ></input>
                                                    <div class="alert-small-text">{this.state.mci_City_Error}</div>
                                                </div>
                                                <div class="form-group col-sm-1">
                                                    <label>State </label>
                                                    <select className="form-control" ref={(input) => this.mci_StateSelector = input} onChange={this.updateMCIState} value={this.state.mci_State}>
                                                        <option value="selectstate">-- select --</option>
                                                        <option value="AL">AL</option>
                                                        <option value="AK">AK</option>
                                                        <option value="AR">AR</option>
                                                        <option value="AZ">AZ</option>
                                                        <option value="CA">CA</option>
                                                        <option value="CO">CO</option>
                                                        <option value="CT">CT</option>
                                                        <option value="DC">DC</option>
                                                        <option value="DE">DE</option>
                                                        <option value="FL">FL</option>
                                                        <option value="GA">GA</option>
                                                        <option value="HI">HI</option>
                                                        <option value="IA">IA</option>
                                                        <option value="ID">ID</option>
                                                        <option value="IL">IL</option>
                                                        <option value="IN">IN</option>
                                                        <option value="KS">KS</option>
                                                        <option value="KY">KY</option>
                                                        <option value="LA">LA</option>
                                                        <option value="MA">MA</option>
                                                        <option value="MD">MD</option>
                                                        <option value="ME">ME</option>
                                                        <option value="MI">MI</option>
                                                        <option value="MN">MN</option>
                                                        <option value="MO">MO</option>
                                                        <option value="MS">MS</option>
                                                        <option value="MT">MT</option>
                                                        <option value="NC">NC</option>
                                                        <option value="NE">NE</option>
                                                        <option value="NH">NH</option>
                                                        <option value="NJ">NJ</option>
                                                        <option value="NM">NM</option>
                                                        <option value="NV">NV</option>
                                                        <option value="NY">NY</option>
                                                        <option value="ND">ND</option>
                                                        <option value="OH">OH</option>
                                                        <option value="OK">OK</option>
                                                        <option value="OR">OR</option>
                                                        <option value="PA">PA</option>
                                                        <option value="RI">RI</option>
                                                        <option value="SC">SC</option>
                                                        <option value="SD">SD</option>
                                                        <option value="TN">TN</option>
                                                        <option value="TX">TX</option>
                                                        <option value="UT">UT</option>
                                                        <option value="VT">VT</option>
                                                        <option value="VA">VA</option>
                                                        <option value="WA">WA</option>
                                                        <option value="WI">WI</option>
                                                        <option value="WV">WV</option>
                                                        <option value="WY">WY</option>
                                                    </select>
                                                    <div class="alert-small-text">{this.state.mci_State_Error}</div>
                                                </div>
                                                <div class="form-group col-sm-1">
                                                    <label>Zip </label>
                                                    <input className="form-control" type="text" maxlength="5" ref={this.mciZipRef} onChange={this.updateMCIZip} value={this.state.mci_Zip} placeholder="Zip" ></input>
                                                    <div class="alert-small-text">{this.state.mci_Zip_Error}</div>
                                                </div>
                                                <div class="form-group col-sm-2">
                                                    <label>Email </label>
                                                    <input className="form-control" type="text" maxlength="255" ref={this.mciEmailRef} onChange={this.updateMCIEmail} value={this.state.mci_Email} placeholder="Email" ></input>
                                                    <div class="alert-small-text">{this.state.mci_Email_Error}</div>
                                                </div>
                                                <div class="form-group col-sm-1">
                                                    <label>Phone </label>
                                                    <input className="form-control" type="text" maxlength="14" ref={this.mciPhoneRef} onChange={this.updateMCIPhone} value={this.state.mci_Phone} laceholder="Phone" ></input>
                                                    <div class="alert-small-text">{this.state.mci_Phone_Error}</div>
                                                </div>
                                                <div class="form-group col-sm-1">
                                                    <label>Phone Extension</label>
                                                    <input className="form-control" type="text" maxlength="10" onChange={this.updateMCIPhoneExtension} value={this.state.mci_PhoneExtension} laceholder="Phone Extension" ></input>
                                                    <div class="alert-small-text">{this.state.mci_Phone_Error}</div>
                                                </div>
                                                <div class="form-group col-sm-1">
                                                    <label>Fax </label>
                                                    <input className="form-control" type="text" maxlength="10" ref={this.mciFaxRef} onChange={this.updateMCIFax} value={this.state.mci_Fax} placeholder="Fax" ></input>
                                                    <div class="alert-small-text">{this.state.mci_Fax_Error}</div>
                                                </div>
                                            </div>
                                        }

                                        <div class="form-row">
                                            <div class="form-group col-sm-2">
                                                <label>Company / Manager Name </label>
                                                <input className="form-control" type="text" maxlength="100" ref={this.mciNameRef} onChange={this.updateMCIName1} value={this.state.mci_Name1} placeholder="Company / Manager Name" ></input>
                                                <div class="alert-small-text">{this.state.mci_Name_Error1}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>Unit </label>
                                                <input className="form-control" type="text" maxlength="50" onChange={this.updateMCIUnit1} value={this.state.mci_Unit1} placeholder="Unit" ></input>
                                                <div class="alert-small-text"></div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>Address </label>
                                                <input className="form-control" type="text" maxlength="200" ref={this.mciAddressRef} onChange={this.updateMCIAddress1} value={this.state.mci_Address1} placeholder="Address" ></input>
                                                <div class="alert-small-text">{this.state.mci_Address_Error1}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>City </label>
                                                <input className="form-control" type="text" maxlength="10" ref={this.mciCityRef} onChange={this.updateMCICity1} value={this.state.mci_City1} placeholder="City" ></input>
                                                <div class="alert-small-text">{this.state.mci_City_Error1}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>State </label>
                                                <select className="form-control" ref={(input) => this.mci_StateSelector = input} onChange={this.updateMCIState1} value={this.state.mci_State1}>
                                                    <option value="selectstate">-- select --</option>
                                                    <option value="AL">AL</option>
                                                    <option value="AK">AK</option>
                                                    <option value="AR">AR</option>
                                                    <option value="AZ">AZ</option>
                                                    <option value="CA">CA</option>
                                                    <option value="CO">CO</option>
                                                    <option value="CT">CT</option>
                                                    <option value="DC">DC</option>
                                                    <option value="DE">DE</option>
                                                    <option value="FL">FL</option>
                                                    <option value="GA">GA</option>
                                                    <option value="HI">HI</option>
                                                    <option value="IA">IA</option>
                                                    <option value="ID">ID</option>
                                                    <option value="IL">IL</option>
                                                    <option value="IN">IN</option>
                                                    <option value="KS">KS</option>
                                                    <option value="KY">KY</option>
                                                    <option value="LA">LA</option>
                                                    <option value="MA">MA</option>
                                                    <option value="MD">MD</option>
                                                    <option value="ME">ME</option>
                                                    <option value="MI">MI</option>
                                                    <option value="MN">MN</option>
                                                    <option value="MO">MO</option>
                                                    <option value="MS">MS</option>
                                                    <option value="MT">MT</option>
                                                    <option value="NC">NC</option>
                                                    <option value="NE">NE</option>
                                                    <option value="NH">NH</option>
                                                    <option value="NJ">NJ</option>
                                                    <option value="NM">NM</option>
                                                    <option value="NV">NV</option>
                                                    <option value="NY">NY</option>
                                                    <option value="ND">ND</option>
                                                    <option value="OH">OH</option>
                                                    <option value="OK">OK</option>
                                                    <option value="OR">OR</option>
                                                    <option value="PA">PA</option>
                                                    <option value="RI">RI</option>
                                                    <option value="SC">SC</option>
                                                    <option value="SD">SD</option>
                                                    <option value="TN">TN</option>
                                                    <option value="TX">TX</option>
                                                    <option value="UT">UT</option>
                                                    <option value="VT">VT</option>
                                                    <option value="VA">VA</option>
                                                    <option value="WA">WA</option>
                                                    <option value="WI">WI</option>
                                                    <option value="WV">WV</option>
                                                    <option value="WY">WY</option>
                                                </select>
                                                <div class="alert-small-text">{this.state.mci_State_Error1}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>Zip </label>
                                                <input className="form-control" type="text" maxlength="5" ref={this.mciZipRef} onChange={this.updateMCIZip1} value={this.state.mci_Zip1} placeholder="Zip" ></input>
                                                <div class="alert-small-text">{this.state.mci_Zip_Error1}</div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Email </label>
                                                <input className="form-control" type="text" maxlength="255" ref={this.mciEmailRef} onChange={this.updateMCIEmail1} value={this.state.mci_Email1} placeholder="Email" ></input>
                                                <div class="alert-small-text">{this.state.mci_Email_Error1}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>Phone </label>
                                                <input className="form-control" type="text" maxlength="14" ref={this.mciPhoneRef} onChange={this.updateMCIPhone1} value={this.state.mci_Phone1} laceholder="Phone" ></input>
                                                <div class="alert-small-text">{this.state.mci_Phone_Error1}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>Phone Extension</label>
                                                <input className="form-control" type="text" maxlength="10" onChange={this.updateMCIPhoneExtension1} value={this.state.mci_PhoneExtension1} laceholder="Phone Extension" ></input>
                                                <div class="alert-small-text"></div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>Fax </label>
                                                <input className="form-control" type="text" maxlength="10" ref={this.mciFaxRef} onChange={this.updateMCIFax1} value={this.state.mci_Fax1} placeholder="Fax" ></input>
                                                <div class="alert-small-text">{this.state.mci_Fax_Error1}</div>
                                            </div>
                                        </div>


                                    </div>
                                </div>


                                <div className="box-gray mb-4">
                                    <h2 class="profile-title"><i class="fas fa-dollar-sign mr-2"></i>Financial Institution</h2>
                                    <div className="box-details">
                                        <div class="form-row">
                                            <div class="form-group col-sm-3">
                                                <label>Name of Financial Institution (Bank) <i class="fas fa-asterisk asterisk"></i></label>
                                                <input className="form-control" type="text" maxlength="100" ref={this.fiiNameRef} onChange={this.updateFIIName} value={this.state.fii_Name} placeholder="Name" ></input>
                                                <div class="alert-small-text">{this.state.fii_Name_Error}</div>
                                            </div>
                                            <div class="form-group col-auto">
                                                <label>Type of Account <i class="fas fa-asterisk asterisk"></i></label>
                                                <div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="savingAccount" name="typeOfAccount" ref={this.fiiAccountTypeRef} onChange={this.updateFIIAccountTypeSavings} class="custom-control-input" />
                                                        <label class="custom-control-label" for="savingAccount">Savings</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="checkingAccount" name="typeOfAccount" class="custom-control-input" onChange={this.updateFIIAccountTypeChecking} />
                                                        <label class="custom-control-label" for="checkingAccount">Checking</label>
                                                    </div>
                                                </div>
                                                <div class="alert-small-text">{this.state.fii_AccountType_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>Unit</label>
                                                <input className="form-control" type="text" maxlength="50" onChange={this.updateFIIUnit} value={this.state.fii_Unit} placeholder="Unit" ></input>
                                                <div class="alert-small-text"></div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Address <i class="fas fa-asterisk asterisk"></i></label>
                                                <input className="form-control" type="text" maxlength="200" ref={this.fiiAddressRef} onChange={this.updateFIIAddress} value={this.state.fii_Address} placeholder="Address" ></input>
                                                <div class="alert-small-text">{this.state.fii_Address_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>City <i class="fas fa-asterisk asterisk"></i></label>
                                                <input className="form-control" type="text" maxlength="10" ref={this.fiiCityRef} onChange={this.updateFIICity} value={this.state.fii_City} placeholder="City" ></input>
                                                <div class="alert-small-text">{this.state.fii_City_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>State <i class="fas fa-asterisk asterisk"></i></label>
                                                <select className="form-control" ref={(input) => this.fii_StateSelector = input} onChange={this.updateFIIState} value={this.state.fii_State}>
                                                    <option value="selectstate">-- select --</option>
                                                    <option value="AL">AL</option>
                                                    <option value="AK">AK</option>
                                                    <option value="AR">AR</option>
                                                    <option value="AZ">AZ</option>
                                                    <option value="CA">CA</option>
                                                    <option value="CO">CO</option>
                                                    <option value="CT">CT</option>
                                                    <option value="DC">DC</option>
                                                    <option value="DE">DE</option>
                                                    <option value="FL">FL</option>
                                                    <option value="GA">GA</option>
                                                    <option value="HI">HI</option>
                                                    <option value="IA">IA</option>
                                                    <option value="ID">ID</option>
                                                    <option value="IL">IL</option>
                                                    <option value="IN">IN</option>
                                                    <option value="KS">KS</option>
                                                    <option value="KY">KY</option>
                                                    <option value="LA">LA</option>
                                                    <option value="MA">MA</option>
                                                    <option value="MD">MD</option>
                                                    <option value="ME">ME</option>
                                                    <option value="MI">MI</option>
                                                    <option value="MN">MN</option>
                                                    <option value="MO">MO</option>
                                                    <option value="MS">MS</option>
                                                    <option value="MT">MT</option>
                                                    <option value="NC">NC</option>
                                                    <option value="NE">NE</option>
                                                    <option value="NH">NH</option>
                                                    <option value="NJ">NJ</option>
                                                    <option value="NM">NM</option>
                                                    <option value="NV">NV</option>
                                                    <option value="NY">NY</option>
                                                    <option value="ND">ND</option>
                                                    <option value="OH">OH</option>
                                                    <option value="OK">OK</option>
                                                    <option value="OR">OR</option>
                                                    <option value="PA">PA</option>
                                                    <option value="RI">RI</option>
                                                    <option value="SC">SC</option>
                                                    <option value="SD">SD</option>
                                                    <option value="TN">TN</option>
                                                    <option value="TX">TX</option>
                                                    <option value="UT">UT</option>
                                                    <option value="VT">VT</option>
                                                    <option value="VA">VA</option>
                                                    <option value="WA">WA</option>
                                                    <option value="WI">WI</option>
                                                    <option value="WV">WV</option>
                                                    <option value="WY">WY</option>
                                                </select>
                                                <div class="alert-small-text">{this.state.fii_State_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>Zip <i class="fas fa-asterisk asterisk"></i></label>
                                                <input className="form-control" type="text" maxlength="5" ref={this.fiiZipRef} onChange={this.updateFIIZip} value={this.state.fii_Zip} placeholder="Zip" ></input>
                                                <div class="alert-small-text">{this.state.fii_Zip_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Routing Transit Number <i class="fas fa-asterisk asterisk"></i></label>
                                                <input className="form-control" type="text" maxlength="9" ref={this.fiiRoutingTransitNumberRef} onChange={this.updateFIIRoutingTransitNumber} value={this.state.fii_RoutingTransitNumber} placeholder="Routing Transit Number" ></input>
                                                <div class="alert-small-text">{this.state.fii_RoutingTransitNumber_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-3">
                                                <label>Account Number <i class="fas fa-asterisk asterisk"></i></label>
                                                <input className="form-control" type="text" maxlength="20" ref={this.fiiAccountNumberRef} onChange={this.updateFIIAccountNumber} value={this.state.fii_AccountNumber} placeholder="Account Number" ></input>
                                                <div class="alert-small-text">{this.state.fii_AccountNumber_Error}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-gray mb-4">
                                    <h2 class="profile-title"><i class="fas fa-house-user mr-2"></i>Housing Authorities Correspondence</h2>
                                    <div className="box-details">
                                        <div class="form-row">
                                            <div class="form-group col-sm-2">
                                                <label>Name <i class="fas fa-asterisk asterisk"></i></label>
                                                <input className="form-control" type="text" maxlength="100" ref={this.haciNameRef} onChange={this.updateHACIName} value={this.state.haci_Name} placeholder="Name" ></input>
                                                <div class="alert-small-text">{this.state.haci_Name_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>In Care of <i class="fas fa-asterisk asterisk"></i></label>
                                                <input className="form-control" type="text" maxlength="100" ref={this.haciInCareOfRef} onChange={this.updateHACIInCareOf} value={this.state.haci_InCareOf} placeholder="In Care of" ></input>
                                                <div class="alert-small-text">{this.state.haci_InCareOf_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Unit </label>
                                                <input className="form-control" type="text" maxlength="50" onChange={this.updateHACIUnit} value={this.state.haci_Unit} placeholder="Unit" ></input>
                                                <div class="alert-small-text"></div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Address <i class="fas fa-asterisk asterisk"></i></label>
                                                <input className="form-control" type="text" maxlength="200" ref={this.haciAddressRef} onChange={this.updateHACIAddress} value={this.state.haci_Address} placeholder="Address" ></input>
                                                <div class="alert-small-text">{this.state.haci_Address_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Email <i class="fas fa-asterisk asterisk"></i></label>
                                                <input className="form-control" type="text" maxlength="100" ref={this.haciEmailRef} onChange={this.updateHACIEmail} value={this.state.haci_Email} placeholder="Email" ></input>
                                                <div class="alert-small-text">{this.state.haci_Email_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Phone <i class="fas fa-asterisk asterisk"></i></label>
                                                <input className="form-control" type="text" maxlength="14" ref={this.haciPhoneRef} onChange={this.updateHACIPhone} value={this.state.haci_Phone} placeholder="Phone" ></input>
                                                <div class="alert-small-text">{this.state.haci_Phone_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Phone Extension</label>
                                                <input className="form-control" type="text" maxlength="12" onChange={this.updateHACIPhoneExtension} value={this.state.haci_PhoneExtension} placeholder="Phone Extension" ></input>
                                                <div class="alert-small-text"></div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Fax </label>
                                                <input className="form-control" type="text" maxlength="10" ref={this.haciFaxRef} onChange={this.updateHACIFax} value={this.state.haci_Fax} placeholder="Fax" ></input>
                                                <div class="alert-small-text">{this.state.haci_Fax_Error}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-gray mb-4">
                                    <h2 class="profile-title"><i class="fas fa-user mr-2"></i>Requester</h2>
                                    <div className="box-details">
                                        <div class="form-row">
                                            <div class="form-group col-sm-2">
                                                <label>Name <i class="fas fa-asterisk asterisk"></i></label>
                                                <input className="form-control" type="text" maxlength="100" ref={this.riNameRef} onChange={this.updateRIName} value={this.state.ri_Name} placeholder="Requester Name" ></input>
                                                <div class="alert-small-text">{this.state.ri_Name_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label>Unit</label>
                                                <input className="form-control" type="text" maxlength="50" onChange={this.updateRIUnit} value={this.state.ri_Unit} placeholder="Unit" ></input>
                                                <div class="alert-small-text"></div>
                                            </div>
                                            <div class="form-group col-sm-3">
                                                <label>Address <i class="fas fa-asterisk asterisk"></i></label>
                                                <input className="form-control" type="text" maxlength="200" ref={this.riAddressRef} onChange={this.updateRIAddress} value={this.state.ri_Address} placeholder="Requester Address" ></input>
                                                <div class="alert-small-text">{this.state.ri_Address_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>City <i class="fas fa-asterisk asterisk"></i></label>
                                                <input className="form-control" type="text" maxlength="10" ref={this.riCityRef} onChange={this.updateRICity} value={this.state.ri_City} placeholder="City" ></input>
                                                <div class="alert-small-text">{this.state.ri_City_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>State <i class="fas fa-asterisk asterisk"></i></label>
                                                <select className="form-control" ref={(input) => this.ri_StateSelector = input} onChange={this.updateRIState} value={this.state.ri_State}>
                                                    <option value="selectstate">-- select --</option>
                                                    <option value="AL">AL</option>
                                                    <option value="AK">AK</option>
                                                    <option value="AR">AR</option>
                                                    <option value="AZ">AZ</option>
                                                    <option value="CA">CA</option>
                                                    <option value="CO">CO</option>
                                                    <option value="CT">CT</option>
                                                    <option value="DC">DC</option>
                                                    <option value="DE">DE</option>
                                                    <option value="FL">FL</option>
                                                    <option value="GA">GA</option>
                                                    <option value="HI">HI</option>
                                                    <option value="IA">IA</option>
                                                    <option value="ID">ID</option>
                                                    <option value="IL">IL</option>
                                                    <option value="IN">IN</option>
                                                    <option value="KS">KS</option>
                                                    <option value="KY">KY</option>
                                                    <option value="LA">LA</option>
                                                    <option value="MA">MA</option>
                                                    <option value="MD">MD</option>
                                                    <option value="ME">ME</option>
                                                    <option value="MI">MI</option>
                                                    <option value="MN">MN</option>
                                                    <option value="MO">MO</option>
                                                    <option value="MS">MS</option>
                                                    <option value="MT">MT</option>
                                                    <option value="NC">NC</option>
                                                    <option value="NE">NE</option>
                                                    <option value="NH">NH</option>
                                                    <option value="NJ">NJ</option>
                                                    <option value="NM">NM</option>
                                                    <option value="NV">NV</option>
                                                    <option value="NY">NY</option>
                                                    <option value="ND">ND</option>
                                                    <option value="OH">OH</option>
                                                    <option value="OK">OK</option>
                                                    <option value="OR">OR</option>
                                                    <option value="PA">PA</option>
                                                    <option value="RI">RI</option>
                                                    <option value="SC">SC</option>
                                                    <option value="SD">SD</option>
                                                    <option value="TN">TN</option>
                                                    <option value="TX">TX</option>
                                                    <option value="UT">UT</option>
                                                    <option value="VT">VT</option>
                                                    <option value="VA">VA</option>
                                                    <option value="WA">WA</option>
                                                    <option value="WI">WI</option>
                                                    <option value="WV">WV</option>
                                                    <option value="WY">WY</option>
                                                </select>
                                                <div class="alert-small-text">{this.state.ri_State_Error}</div>
                                            </div>
                                            <div class="form-group col-sm-1">
                                                <label>Zip <i class="fas fa-asterisk asterisk"></i></label>
                                                <input className="form-control" type="text" maxlength="5" ref={this.riZipRef} onChange={this.updateRIZip} value={this.state.ri_Zip} placeholder="Zip" ></input>
                                                <div class="alert-small-text">{this.state.ri_Zip_Error}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* className={this.state.classes.backdrop} */}
                                <Backdrop open={this.state.isLoading} onClick={this.handleClose}>
                                    <CircularProgress color="inherit" />
                                </Backdrop>

                                <Snackbar anchorOrigin={{ vertical: this.state.snackbarVertical, horizontal: this.state.snackbarHorizontal }}
                                    open={this.state.openSnackbar}
                                    autoHideDuration={3000}
                                    severity={this.state.snackbarSeverity}
                                    onClose={() => this.setState({ openSnackbar: false })}
                                    message={this.state.snackbarMessage}
                                    className={this.state.snackbarMessageClass}
                                    key={this.state.snackbarVertical + this.state.snackbarHorizontal}
                                />

                                {/* {this.state.isLoading && (
                                <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                                    <CircularProgress />
                                </div>
                            )} */}
                            </div>
                        </React.Fragment>
                    </div>
                </div>
            </div>
        );
    }
}

export default PHARegistrationStep2;
