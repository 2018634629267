import React from "react";
import WelcomehomeOCH from './imgs/welcomhome-oc-horizontal.png';

class ThankYou extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        document.body.classList.add('landing');
        document.body.classList.add('d-flex');
        return (
            <div >
                <div style={{ display: this.state.showLogin }}>

                    {/* header starts */}
                    {/* header ends */}

                    {/* main content starts */}
                    <main className="main">
                        <div className="container-fluid">
                            <div className="card-shadow mb-5 mb-md-0">
                                <div className="welcome-box text-center p-5">
                                    <img src={WelcomehomeOCH} className="img-fluid mb-3" alt="homeless logo image" loading="lazy" />
                                    {/* <h2 className="text-primary title-text font-weight-bold mb-0">WelcomeHomeOC Program</h2> */}
                                    <hr />
                                    <p><i class="far fa-check-circle success-icon"></i></p>
                                    <p className="text-center">
                                        {(this.props.isAuthorizedSignatoryDifferent === 1 || this.props.isAuthorizedSignatoryDifferent === true) ?
                                            (<><b>The Participation Agreement has been sent to your designated signatory.</b><br /><br />
                                                Your designated signatory has received the Participation Agreement via Docusign. Once the agreement is signed, your credentials to access the Property Engagement Portal and submit a rental will be sent to you via email.<br /><br />
                                                Your credentials and instructions to log into the Property Engagement Portal have been sent to your email.<br /><br />
                                                You can close this page.</>)
                                            :
                                            (<><b>Participation Agreement successfully submitted.</b><br /><br />
                                                Your WelcomeHomeOC Participation Agreement has been sent for signature by Orange County United Way. Once it is signed, you will receive an email notice from Docusign with an option to download the agreement for your records.<br /><br />
                                                <b>You are now ready to submit a rental unit to WelcomeHomeOC!</b><br /><br />
                                                Your credentials and instructions to log into the Property Engagement Portal have been sent to your email.<br /><br />
                                                You can close this page.</>)
                                        }
                                    </p>
                                    <hr />
                                    {/* <p className="roboto-font font-16 pt-3 mb-0">Powered by Orange County United Way</p> */}
                                </div>
                            </div>
                        </div>
                    </main>
                    {/* main content ends */}
                </div>
            </div>
        );
    }
}

export default ThankYou;
