import React from "react";
import WelcomehomeOCH from './imgs/welcomhome-oc-horizontal.png';

class InvitationExpired extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        document.body.classList.add('landing');
        document.body.classList.add('thankyou');
        //document.body.classList.add('d-flex');
        return (
            <div >
                <div style={{ display: this.state.showLogin }}>

                    {/* header starts */}
                    {/* header ends */}

                    {/* main content starts */}
                    <main className="main">
                        <div className="container-fluid">
                            <div className="card-shadow mb-5 mb-md-0">
                                <div className="welcome-box text-center p-5">
                                    <img src={WelcomehomeOCH} className="img-fluid mb-3" alt="homeless logo image" loading="lazy" />
                                    {/* <h2 className="text-primary title-text font-weight-bold mb-0">WelcomeHomeOC Program</h2> */}
                                    <hr />
                                    <p><i class="far fa-check-circle success-icon"></i></p>
                                    <p className="text-center"><b>This link has expired. It is most likely because:<br /><br /><ul className="d-inline-block text-left"><li>You already completed the Participation Agreement, or</li><li>You are already enrolled in the WelcomeHomeOC Property Provider Network</li></ul></b><br /></p>
                                    <p>Are you looking to access the <a className="woc-portal-link" href="https://master.d2rzgo2ahquwte.amplifyapp.com/">WelcomeHomeOC Property Engagement Portal</a>? Your username and temporary password were sent to the same email address used to complete the Participation Agreement. Check your spam folder if you did not receive them and be sure to add <b>WelcomeHomeOC@UnitedWayOC.org</b> to your trusted senders list.</p>
                                </div>
                            </div>
                        </div>
                    </main>
                    {/* main content ends */}
                </div>
            </div>
        );
    }
}

export default InvitationExpired;
