import React from "react";

class PHARegistrationComplete extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
        }
    }

    async componentDidMount() {
    }

    render() {
        return (
            <div className="card-shadow mb-5 mb-md-0">
                <div className="welcome-box text-center p-5">
                    {/* <img src={WelcomehomeOCH} className="img-fluid mb-3" alt="homeless logo image" loading="lazy" /> */}
                    <h2 className="text-primary title-text font-weight-bold mb-0">Registration Completed</h2>
                    <hr />
                    <p><i class="far fa-check-circle success-icon"></i></p>
                    <p className="text-center"><b>PHA registration completed successfully.<br /><br />Thank you for submitting PHA application. Our team of administrators are looking into your application and will approve it ASAP. You will be notified on email once the admin has signed the document.</b><br /><br /> You can close this page.</p>
                    <hr />
                </div>
            </div>
        );
    }
}

export default PHARegistrationComplete;
