import React from "react";
import { SignIn, SignOut, Greetings } from "aws-amplify-react";
import { CustomSignIn } from "./login";
import { Authenticator } from "aws-amplify-react";
import Amplify from 'aws-amplify';
import PropertyOwnerProfile from "./propertyOwnerProfile";
import '@aws-amplify/ui/dist/style.css';

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_UserIdentityPoolID,
    region: process.env.REACT_APP_Region,
    userPoolId: process.env.REACT_APP_UserUserPoolId,
    userPoolWebClientId: process.env.REACT_APP_UserUserPoolWebClientId,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3FrontendBucket, //REQUIRED -  Amazon S3 bucket
      region: process.env.REACT_APP_Region, //OPTIONAL -  Amazon service region
    }
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_APIGatewayName,
        endpoint: process.env.REACT_APP_APIEndpoint
      },
      {
        name: process.env.REACT_APP_APIGatewayName_Salesforce,
        endpoint: process.env.REACT_APP_APIEndpoint_Salesforce
      },
    ]
  }
});

const map = (message) => {
    
  if (/Neither Email nor Phone Number selected/i.test(message)) {
      return 'Please select Email option to share verification code.';
  }

  return message;
}

class AppWithAuth extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
    }
    this.updateUsername = this.updateUsername.bind(this)
  }

  updateUsername(newUsername) {
    this.setState({ username: newUsername })
  }

  render() {
    return (
      <div>
        <Authenticator errorMessage={map} hide={[SignIn, SignOut, Greetings]}>
          <CustomSignIn authState={this.props.authState} updateUsername={this.updateUsername} onStateChange={this.props.onStateChange} />
          <PropertyOwnerProfile authState={this.props.authState} username={this.username} updateUsername={this.updateUsername} onStateChange={this.props.onStateChange} />
        </Authenticator>
      </div>
    );
  }
}

export default AppWithAuth;
