import React, { useState } from "react";
import AppWithAuth from "./AppWithAuth";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import LandingPage from "./landingPage";
import PHARegistration from './pha/phaRegistration';

// import PropertyOwnerProfile from "./propertyOwnerProfile";
import AddProperty from './addProperty';
import EditProperty from "./editProperty";
import AddUnit from "./addUnit";
import ThankYou from "./thankYou";
import PageNotFound from './pageNotFound'

function Routes() {
    const { pathname } = useLocation();
    if(window.location && window.location.search === '?event=signing_complete' && window.location.href.indexOf("/pharegistration") > -1){
        console.log(window.location)
        localStorage.setItem('url',window.location.href)
        // var testingId = localStorage.getItem('url').split('/')[3]
        return <Redirect to='/pharegistration'/>
    }
    return (
        <Switch>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
            <Route exact path="/" component={AppWithAuth}></Route>

            {/* /welcomehomeoc/invitation/<guid> */}
            <Route path="/welcomehomeoc" component={LandingPage} ></Route>
            <Route path="/pharegistration" component={PHARegistration} ></Route>

            {/* <Route exact path="/profile" render={props => <PropertyOwnerProfile {...props} />} ></Route> */}
            <Route exact path="/property/add" render={props => <AddProperty {...props} />} ></Route>
            <Route exact path="/property/edit" render={props => <EditProperty {...props} />} ></Route>
            <Route exact path="/unit/add" render={props => <AddUnit {...props} readOnly={false} />} ></Route>
            <Route exact path="/unit/view" render={props => <AddUnit {...props} readOnly={true} />} ></Route>
            <Route exact path="/agreementsubmitted" render={props => <ThankYou {...props} isAuthorizedSignatoryDifferent={false} />} ></Route>
            <Route exact path="/agreementsent" render={props => <ThankYou {...props} isAuthorizedSignatoryDifferent={true} />} ></Route>
            {/* keep your routes above this route */}
            <Route path="**" component={PageNotFound} />
        </Switch>
    );
}

export default Routes;