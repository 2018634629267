import React from "react";
import { SignIn } from "aws-amplify-react";
import WHOClogo from './imgs/welcomhome-oc-horizontal.png';
import { CircularProgress } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';

export class CustomSignIn extends SignIn {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        }
        this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    }

    handleLoginClick = async () => {
        this.setState({ isLoading: true, });
        await super.signIn();
        this.setState({ isLoading: false, });
    }

    showComponent(theme) {
        document.body.classList.add('login');
        return (
            <div className="login-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-5 p-0 login-right">
                            <React.Fragment>
                                <div className="login-box">
                                    <h2 className="mb-4 text-center text-md-left font-weight-bold">Sign In</h2>
                                    <div className="form-group mb-4">
                                        <label htmlFor="username">Email</label>
                                        <input type="text" className="form-control" id="username" key="username" name="username" onChange={this.handleInputChange} placeholder="Enter Email" />
                                    </div>
                                    <div className="form-group mb-0">
                                        <label htmlFor="password">Password</label>
                                        <input type="password" className="form-control" id="password" key="password" name="password" onChange={this.handleInputChange} placeholder="Enter Password" />
                                    </div>
                                    <div className="text-right mb-5 mt-2">
                                        <a href="javascript:void(0);" onClick={() => {
                                            super.changeState("forgotPassword");
                                            document.body.classList.add('login-fp');
                                        }}
                                        >Forgot Password?</a>
                                    </div>
                                    <button type="button" onClick={this.handleLoginClick} className="btn btn-primary btn-black btn-block py-3">Login <i className="fas fa-lock ml-2"></i></button>
                                </div>

                                <Backdrop className="MuiBackdrop-root" open={this.state.isLoading} >
                                    <CircularProgress />
                                </Backdrop>
                            </React.Fragment>
                        </div>
                        <div className="col-lg-7 order-md-first p-0 login-left-bg">
                            <div className="p-3 p-md-5 login-left-content">
                                <img src={WHOClogo} height="36" alt="welcome home oc"></img>
                                <h3 className="login-title">A Home <br />is Essential <br />to End Homelessness</h3>
                                <p>WelcomeHomeOC is a landlord incentive program that provides financial incentives such as double security deposits, sign-on bonus, holding fees, and other assurances for landlords who rent units in Orange County to individuals, Veterans, and families with a housing voucher.  The program is a joint effort between the United to End Homelessness initiative, Orange County United Way, Public Housing Authorities, and rental property owners to reduce the time involved in the search for housing by increasing the availability of rental units.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}