import React from "react";
import { API, Storage } from 'aws-amplify';
import ReactDOM from 'react-dom';
import { CircularProgress } from '@material-ui/core';

import ThankYou from './thankYou';
import homelessLogo from './imgs/logo-united-homelessness.png';
import landingleftImg from './imgs/landing-leftimg.jpg';
import memorandamImg from './imgs/program-memorandam.png';
import participationImg from './imgs/participation.png';
import InvitationExpired from './invitationExpired';
import Backdrop from '@material-ui/core/Backdrop';
import AWS from 'aws-sdk';
import $ from 'jquery';

class LandingPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,

            firstName: "",
            lastName: "",
            userName: "",
            emailID: "",
            phone: "",
            status: "",
            guid: "",
            envelopeStatus: null,
            agreementSubmitted: 0,
            isAuthorizedSignatoryDifferent: 0,
            authorizedSignatoryFullName: "",
            authorizedSignatoryEmailID: "",
            authorizedSignatoryPhone: "",

            authorizedSignatoryFullName_Error: "",
            authorizedSignatoryEmailID_Error: "",
            authorizedSignatoryPhone_Error: "",
        }
    }

    async componentDidMount() {
        this.setState({ isLoading: true });

        const searchGuid = this.props.location.pathname.substring(26); // len(/welcomehomeoc/invitation/) = 26

        var res = await this.getPropertyOwnerInfo(searchGuid);
        if (res.statusCode !== 200) {
            this.setState({ isLoading: false });
            return;
        }
        this.setState({ guid: searchGuid });
        var info = JSON.parse(res.body);

        this.setState({
            guid: searchGuid,

            firstName: info[0][0],
            lastName: info[0][1],
            userName: `${info[0][0]} ${info[0][1]}`,
            emailID: info[0][2],
            phone: info[0][3],
            status: info[0][4],
            envelopeStatus: info[0][5],
            agreementSubmitted: info[0][6],
            isAuthorizedSignatoryDifferent: info[0][7],
            authorizedSignatoryFullName: info[0][8],
            authorizedSignatoryEmailID: info[0][9],
            authorizedSignatoryPhone: info[0][10],

            isLoading: false,
        });

        if (this.state.envelopeStatus === 'Completed') {
            ReactDOM.render(<InvitationExpired />, document.getElementById("root"));
        }
        else if (this.state.agreementSubmitted === 1) { // Already submitted the agreement form
            // Render the ThankYou page
            ReactDOM.render(<ThankYou isAuthorizedSignatoryDifferent={this.state.isAuthorizedSignatoryDifferent} />, document.getElementById("root"));
        }
    }

    getPropertyOwnerInfo = async (guid) => {
        //var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/propertyownerinfo';
        let myInit = {
            body: {
                "info": {
                    "GUID": guid
                }
            },
            headers: { Authorization: 'United Way WelcomeHomeOC' }
        }
        return await API.post(apiName, path, myInit);
    };

    updateReadMemorandum = (evt) => {
        if (evt.target.checked === true) {
            document.getElementById("participationAgreement").classList.remove('disabled');
            document.getElementById('participationAgreement').disabled = false;
            // document.getElementById('participationAgreement').onclick = (e) => { this.invokeDocuSignProcess(e); }
            document.getElementById('participationAgreement').setAttribute('data-target', '#authorizedSignatory');
        }
        else {
            document.getElementById("participationAgreement").classList.add('disabled');
            document.getElementById('participationAgreement').disabled = true;
            document.getElementById('participationAgreement').onclick = '';
        }
    }

    validateFields() {
        let fieldsAreValid = true;

        this.setState({ 
            authorizedSignatoryFullName_Error: "",
            authorizedSignatoryEmailID_Error: "",
            authorizedSignatoryPhone_Error: "",
         });

        if (this.state.isAuthorizedSignatoryDifferent === 1 || this.state.isAuthorizedSignatoryDifferent === true || this.state.isAuthorizedSignatoryDifferent === 'true') {
            if (this.state.authorizedSignatoryFullName === "" || this.state.authorizedSignatoryFullName === null || this.state.authorizedSignatoryFullName === undefined) {
                this.setState({ authorizedSignatoryFullName_Error: "Please enter the name" });
                fieldsAreValid = false;
            }

            if (this.state.authorizedSignatoryEmailID === "" || this.state.authorizedSignatoryEmailID === null || this.state.authorizedSignatoryEmailID === undefined) {
                this.setState({ authorizedSignatoryEmailID_Error: "Please enter the Email" });
                fieldsAreValid = false;
            }
            else if (this.state.authorizedSignatoryEmailID !== "" && this.state.authorizedSignatoryEmailID !== null && this.state.authorizedSignatoryEmailID !== undefined) {
                var emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (!emailPattern.test(this.state.authorizedSignatoryEmailID)) {
                    this.setState({ authorizedSignatoryEmailID_Error: "Invalid Email" });
                    fieldsAreValid = false;
                }
            }

            if (this.state.authorizedSignatoryPhone === "" || this.state.authorizedSignatoryPhone === null || this.state.authorizedSignatoryPhone === undefined) {
                this.setState({ authorizedSignatoryPhone_Error: "Please enter the Phone" });
                fieldsAreValid = false;
            }
            else if (this.state.authorizedSignatoryPhone !== "" && this.state.authorizedSignatoryPhone !== null && this.state.authorizedSignatoryPhone !== undefined) {
                if (!this.state.authorizedSignatoryPhone.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
                    this.setState({ authorizedSignatoryPhone_Error: "Invalid Phone" });
                    fieldsAreValid = false;
                }
            }
        }

        return fieldsAreValid;
    }

    invokeDocuSignProcess = async () => {
        // document.body.classList.remove('landing');

        // // Render the ParticipationAgreement page
        // ReactDOM.render(<ParticipationAgreement email={this.state.email} />, document.getElementById("root"));

        this.setState({ isLoading: true });

        if (!this.validateFields()) {
            this.setState({ isLoading: false });
            return;
        }
        $('#authorizedSignatory').modal('hide');

        let redirectURL = '';
        for (var i = 0; i < 5; i++) {
            try {
                var res = await this.requestDocuSignSignatureForAgreement();
                if (res.message === "Endpoint request timed out") {
                    console.log("Error: Endpoint request timed out. Retrying attempt: " + i);
                    continue;
                }
                else {
                    console.log(res);
                    redirectURL = res.returnUrl;
                    break;
                }
            }
            catch (err) {
                console.log('Error Response');
                console.log(err.response);
                if (err.response && err.response.status === 504) {
                    console.log("Error: Endpoint request timed out. Retrying attempt: " + (i + 1));
                    continue;
                }
                console.log(err);
            }
        }

        this.setState({ isLoading: false });

        window.location.href = redirectURL;
    }

    requestDocuSignSignatureForAgreement = async () => {
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/requestdocusignsignatureforagreement';
        let myInit = {
            body: {
                "guid": this.state.guid,
                "isAuthorizedSignatoryDifferent": this.state.isAuthorizedSignatoryDifferent === 1? true : false,
                "authorizedSignatoryFullName": this.state.authorizedSignatoryFullName,
                "authorizedSignatoryEmailID": this.state.authorizedSignatoryEmailID,
                "authorizedSignatoryPhone": (this.state.authorizedSignatoryPhone === null || this.state.authorizedSignatoryPhone === undefined) ? '' : this.state.authorizedSignatoryPhone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
            },
            headers: { Authorization: 'United Way WelcomeHomeOC' }

            //headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
            // headers: { "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
            //  "Access-Control-Allow-Origin": "*",
            // }
        }
        return await API.post(apiName, path, myInit);
    }

    OnFieldChanged(field, e) {
        if (field === "AuthorizedSignatoryFullName") {
            this.setState({ authorizedSignatoryFullName: e.target.value });
        }
        else if (field === "AuthorizedSignatoryEmailID") {
            this.setState({ authorizedSignatoryEmailID: e.target.value });
        }
        else if (field === "AuthorizedSignatoryPhone") {
            this.setState({ authorizedSignatoryPhone: e.target.value });
        }
    }

    OnRadioButtonsChanged(event) {
        if (event.target.value === 'true') {
            this.setState({ 
                isAuthorizedSignatoryDifferent: 1,

                authorizedSignatoryFullName: "",
                authorizedSignatoryEmailID: "",
                authorizedSignatoryPhone: "",

                authorizedSignatoryFullName_Error: "",
                authorizedSignatoryEmailID_Error: "",
                authorizedSignatoryPhone_Error: "",
            });
        }
        else {
            this.setState({ isAuthorizedSignatoryDifferent: 0 });
        }
    }

    resetFields = async () => {
        this.setState({
            isAuthorizedSignatoryDifferent: 0,
            authorizedSignatoryFullName: "",
            authorizedSignatoryEmailID: "",
            authorizedSignatoryPhone: "",

            authorizedSignatoryFullName_Error: "",
            authorizedSignatoryEmailID_Error: "",
            authorizedSignatoryPhone_Error: "",
        });
    }

    render() {
        if (this.state.status !== '') {
            document.body.classList.remove('login');
            document.body.classList.add('landing');
            return (
                <div >
                    {/* new design starts */}

                    <div style={{ display: this.state.showLogin }}>

                        {/* header starts */}
                        {/* header ends */}

                        {/* main content starts */}
                        <main className="main">
                            <div className="container-fluid">
                                <div className="card-shadow mb-5 mb-md-0">
                                    <div className="welcome-box text-center px-3 px-lg-0">
                                        {/* <h1 className="welcom-user-title">Welcome {this.state.firstName}</h1> */}
                                        <div className="row">
                                            <div className="col-lg-7 first text-center pr-5">
                                                <img src={homelessLogo} className="img-fluid" alt="homeless logo image" loading="lazy" />
                                                <p className="text-left mt-3 mt-xl-0"><b>Dear {this.state.userName}, Thank you for joining the WelcomeHomeOC program.</b></p>
                                                <p className="text-left">The WelcomeHomeOC landlord incentive program is a collaborative effort between Orange County United Way and its United to End Homelessness initiative, service providers, local housing authorities, and private market rental property owners to open the doors to critically-needed housing units for individuals and families experiencing homelessness with housing vouchers in hand. The goal of the program is to significantly reduce the time these families and individuals search for housing by increasing the number and accessibility of rental units.</p>
                                                <p className="text-left">WelcomeHomeOC provides benefits to property owners in the form of paying double security deposits, daily holding fees, as well as ensuring that each resident receives the supportive case-management they need to get back on their feet and remain stably housed. Both the Apartment Association of Orange County (AAOC) and California Apartment Association (CAA) have fully endorsed this program.</p>
                                                <p className="text-left"><b>Please review the Program Memorandum and sign the Participation Agreement to officially join our Property Provider Network.</b></p>
                                                <div className="welcomeoc-program row">
                                                    <div className="col-md-6">
                                                        <button className="btn mt-3" onClick={async () => {
                                                            this.setState({ isLoading: true });

                                                            Storage.configure({
                                                                bucket: process.env.REACT_APP_S3FrontendBucket,
                                                                //bucket: process.env.bu,
                                                                region: process.env.REACT_APP_Region,
                                                                level: 'public',
                                                            });

                                                            // User - S3UploadUser
                                                            AWS.config.update({
                                                                accessKeyId: process.env.REACT_APP_AccessKeyId,
                                                                secretAccessKey: process.env.REACT_APP_SecretAccessKey,
                                                                region: process.env.REACT_APP_Region //   <- If you want send something to your bucket, you need take off this settings, because the S3 are global. 
                                                            });

                                                            try {
                                                                var template = await Storage.get(process.env.REACT_APP_ProgramMemorandum_Path);
                                                                let link = document.createElement('a');
                                                                link.href = template;
                                                                link.target = '_blank';
                                                                link.download = template;
                                                                link.click();
                                                            }
                                                            catch (e) {
                                                                console.log(e);
                                                            }

                                                            this.setState({ isLoading: false });
                                                        }}>
                                                            <img src={memorandamImg} className="img-fluid" alt="homeless logo image" loading="lazy" />
                                                            <h2 className="subtitle-text">PROGRAM MEMORANDUM</h2>
                                                            <p>Click here to view more details about the WelcomeHomeOC program.</p>
                                                        </button>
                                                        <p style={{ fontStyle: 'normal' }}>Please click the following checkbox to enable the Participation Agreement Form</p>
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="programmemorandam" onChange={this.updateReadMemorandum} />
                                                            <label className="custom-control-label" htmlFor="programmemorandam">I have read and accept the terms of the memorandum.</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <button id="participationAgreement" disabled className="btn mt-3 disabled" data-toggle="modal" >
                                                            <img src={participationImg} className="img-fluid" alt="homeless logo image" loading="lazy" />
                                                            <h2 className="subtitle-text">PARTICIPATION AGREEMENT</h2>
                                                            <p>Click here to join the WelcomeHomeOC program and support us in ending homelessness in Orange County.</p>
                                                        </button>
                                                    </div>
                                                </div>
                                                {/* <p className="roboto-font font-16 pt-5">Powered by Orange County United Way</p> */}
                                            </div>
                                            <div className="col-lg-5 order-md-first">
                                                <div className="landing-left" style={{ backgroundImage: "url(" + landingleftImg + ")" }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <React.Fragment>
                                        <Backdrop className="MuiBackdrop-root" open={this.state.isLoading} onClick={this.handleClose}>
                                            <CircularProgress />
                                        </Backdrop>
                                    </React.Fragment>

                                </div>
                            </div>
                        </main>
                        {/* main content ends */}

                        {/* authorized signatory modal START*/}
                        <div class="modal fade" id="authorizedSignatory" tabindex="-1" aria-labelledby="authorizedSignatoryModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="authorizedSignatoryModalLabel"><i class="fas fa-address-book mr-2"></i>Authorized Signatory</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.resetFields}>
                                            <i class="fas fa-times"></i>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <form>
                                            <div class="form-row">
                                                <div className="col-auto">
                                                    <label><b>Send Participation Agreement to a different authorized signer</b></label>
                                                </div>
                                                <div className="col-auto">
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="AuthorizedSignatoryDifferentTrue" name="IsAuthorizedSignatoryDifferent" class="custom-control-input"
                                                            checked={this.state.isAuthorizedSignatoryDifferent === 1 || this.state.isAuthorizedSignatoryDifferent === true}
                                                            value="true" onChange={this.OnRadioButtonsChanged.bind(this)} />
                                                        <label class="custom-control-label" for="AuthorizedSignatoryDifferentTrue">Yes</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="AuthorizedSignatoryDifferentFalse" name="IsAuthorizedSignatoryDifferent" class="custom-control-input"
                                                            checked={this.state.isAuthorizedSignatoryDifferent === 0 || this.state.isAuthorizedSignatoryDifferent === false}
                                                            value="false" onChange={this.OnRadioButtonsChanged.bind(this)} />
                                                        <label class="custom-control-label" for="AuthorizedSignatoryDifferentFalse">No</label>
                                                    </div>
                                                </div>
                                            </div>

                                            {(this.state.isAuthorizedSignatoryDifferent === 1 || this.state.isAuthorizedSignatoryDifferent === true) ?
                                                (<div class="form-row search-area">
                                                    <div class="form-group col-md-4">
                                                        <label>Full Name</label>
                                                        <input className="form-control" type="text" value={this.state.authorizedSignatoryFullName} onChange={this.OnFieldChanged.bind(this, "AuthorizedSignatoryFullName")} maxlength="200"></input>
                                                        <span class="alert-small-text">{this.state.authorizedSignatoryFullName_Error}</span>
                                                    </div>
                                                    <div class="form-group col-md-4">
                                                        <label>Email</label>
                                                        <input className="form-control" type="text" value={this.state.authorizedSignatoryEmailID} onChange={this.OnFieldChanged.bind(this, "AuthorizedSignatoryEmailID")} maxlength="200"></input>
                                                        <span class="alert-small-text">{this.state.authorizedSignatoryEmailID_Error}</span>
                                                    </div>
                                                    <div class="form-group col-md-3">
                                                        <label>Phone</label>
                                                        <input className="form-control" type="text" value={this.state.authorizedSignatoryPhone} onChange={this.OnFieldChanged.bind(this, "AuthorizedSignatoryPhone")} maxlength="14"></input>
                                                        <span class="alert-small-text">{this.state.authorizedSignatoryPhone_Error}</span>
                                                    </div>
                                                </div>)
                                                :
                                                (<div class="form-row search-area">
                                                    <div class="form-group col-md-4">
                                                        <label>Full Name</label>
                                                        <input className="form-control" disabled type="text" value={(this.state.firstName && this.state.lastName) ? (this.state.firstName + " " + this.state.lastName) : ""} maxlength="200"></input>
                                                    </div>
                                                    <div class="form-group col-md-4">
                                                        <label>Email</label>
                                                        <input className="form-control" disabled type="text" value={this.state.emailID} maxlength="200"></input>
                                                    </div>
                                                    <div class="form-group col-md-3">
                                                        <label>Phone</label>
                                                        <input className="form-control" disabled type="text" value={(this.state.phone === null || this.state.phone === undefined) ? '' : this.state.phone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '')} maxlength="200"></input>
                                                    </div>
                                                </div>)
                                            }

                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-primary btn-black btn-sm" onClick={this.invokeDocuSignProcess} >Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* authorized signatory modal END*/}

                    </div>
                </div>
            );
        }
        else {
            return (
                <React.Fragment>
                    <Backdrop className="MuiBackdrop-root" open={this.state.isLoading} onClick={this.handleClose}>
                        <CircularProgress />
                    </Backdrop>
                </React.Fragment>
            );
        }
    }
}

export default LandingPage;
